import Axios from "./axios/provider";
import { serverConstants } from "../utils/constants";

/**
 * Get Service Authorization List
 *
 * @param {object} data
 * @returns
 */
export const getAccumulator = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetAccumulator + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @returns
 */
export const logout = async () => {
  try {
    const response = await Axios.get(serverConstants.userLogout, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {*} url API end point URL.
 * @param {*} param1 object that contains memberId and state code.
 * @returns
 */
export const SSORedirect = async (url, { RKSMemberId, StateCode, ...rest }) => {
  try {
    const response = await Axios.post(url, {
      RKSMemberId,
      StateCode,
      RequestSource: "Desktop",
      LoginSource: "Desktop",
      ...rest,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @returns
 */
export const getDictionaryItems = async (prefLang) => {
  try {
    //need to move url to constants
    const response = await Axios.get(
      `/sitecore/api/jss/dictionary/MemberPortal/${prefLang}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Live Chat Auditing
 *
 * @param {object} data
 * @returns
 */
export const LiveChatAuditing = async (data) => {
  try {
    const response = Axios.post(constants.apigeeURLs.LiveChatAuditing, data);
    return response;
  } catch (error) {}
};

/**
 *
 * @returns
 */
export const getLoginEnv = async () => {
  try {
    const response = await Axios.get(serverConstants.loginEnv);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @returns
 */
export const getWidgetEnv = async () => {
  try {
    const response = await Axios.get(serverConstants.widgetEnv);
    return response;
  } catch (error) {
    throw error;
  }
};
/**
 *
 * @returns
 */
export const getCDPConfig = async () => {
  try {
    const response = await Axios.get(serverConstants.CDPConfig);
    return response;
  } catch (error) {}
};
