import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useClaimsHistoryAPI } from "../../hooks/useClaimsHistoryAPI";
import { Formik, Form } from "formik";
import ClaimHistoryRecords from "./ClaimHistoryRecords";
import { paginationPrint } from "./Helper";
import {
  printReportDate,
  pagination,
  formSchema,
} from "../Common-Health-Records/Helper";
import MobileViewClaimHistoryRecords from "./MobileViewClaimHistoryRecords";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import ClaimLinks from "./ClaimLinks";
import DisplaySearch from "../MyHealthRecord-Layout/Common/DisplaySearch";
import {
  NUMBER_OF_RECORDS_PER_API_CALL,
  MARKETPLACE,
  MEDICAID,
  ID,
  KY,
  OH,
  KYMC_PROGRAMID,
  KYMAPD_PROGRAMID,
  DATE_MM_DD_YYYY,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { getFormattedDate } from "../../utils/helpers";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY } from "../../define.widgets";
import { FooterDescription } from "../MyHealthRecord-Layout/Common/FooterDescription";
import { AppContext } from "./../../contexts/app.context";

const MyHealthRecordClaimsHistory = (props) => {
  const recordsPerPage = parseInt(props.fields.lblPostPerPage.value);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  /**Pagination useState Variables */
  const [next, setNext] = useState(recordsPerPage);
  const [page, setPage] = useState(1);
  const [fetchData, setFetchData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  /** Search useState Variables */
  const [searchTerm, setSearchTerm] = useState(null);
  const [userAction, setUserAction] = useState(null);

  /**Filtering useState Variables */
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [slicedRecords, setSlicedRecords] = useState(null);

  /** Dropdown default value "All" */
  const [selectedValue, setSelectedValue] = useState(
    props.fields.lblDefaultDropdownOption.value
  );
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  /** Formik implementation. */
  const initialValues = {
    startDate: null,
    endDate: null,
    selectedOption: selectedValue,
  };

  const { providerList, claimsHistoryList, isClaimLoaded, totalRecords } =
    useClaimsHistoryAPI(
      page,
      NUMBER_OF_RECORDS_PER_API_CALL,
      fetchData,
      searchTerm
    );

  useEffect(() => {
    if (totalRecords > 0) {
      setFilteredRecords((r) =>
        page > 1 ? [...r, ...claimsHistoryList] : claimsHistoryList
      );
      setTotalRecordsCount(totalRecords);
    }
  }, [claimsHistoryList, totalRecords, page]);

  useEffect(() => {
    let slicedRecords = filteredRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    if (totalRecordsCount - offset < recordsPerPage) {
      slicedRecords = filteredRecords?.slice(
        0,
        offset + (totalRecordsCount - offset)
      );
    } else {
      slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    }

    setSlicedRecords(slicedRecords);
  }, [filteredRecords, currentPage, totalRecordsCount]);

  let totalPages = Math.ceil(totalRecordsCount / recordsPerPage);
  let { memberLOB, MemberStateName, programId } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  let isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID.toLowerCase();

  /* As of now MyMolina Redesign-JULY/2023, 
  showEOB flag is setting to false as per requirement.
  Later can be enabled to show EOB conditionally by
  setting this flag to TRUE*/
  const showEOB = false;

  /** Provider dropdown options */
  const dropdownOptions = [
    {
      value: props.fields.lblDefaultDropdownOption.value,
      id: props.fields.lblDefaultDropdownOption.value,
      label: props.fields.lblDefaultDropdownOption.value,
    },
  ];

  /* Mapping from API */
  providerList?.map((data) => {
    dropdownOptions.push({ value: data, id: data, label: data });
  });

  const handleDropdownChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };

  const resetPage = () => {
    setPage(1);
    setCurrentPage(1);
    setNext(recordsPerPage);
    setUserAction(null);
  };

  /** Submit Click Handler */
  const onSubmitHandler = (values) => {
    resetPage();
    setUserAction("Search");
    let filterParams = {
      startDate:
        values.startDate && getFormattedDate(values.startDate, DATE_MM_DD_YYYY),
      endDate:
        values.endDate && getFormattedDate(values.endDate, DATE_MM_DD_YYYY),
      provName: values.selectedOption,
    };
    setSearchTerm(filterParams);
    setFetchData(true);
  };

  const getKYBannerMsg = (fields) => {
    let bannerMsgKY = null;
    if (MemberStateName?.toLowerCase() === KY.toLowerCase() && programId) {
      if (programId === KYMC_PROGRAMID) {
        bannerMsgKY = fields?.lblDSNPMedicareMsgKY;
      } else if (programId === KYMAPD_PROGRAMID) {
        bannerMsgKY = fields?.lblMedicareMsgKY;
      } else {
        //Do Nothing
      }
    }
    return bannerMsgKY;
  };

  return (
    <>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema(
            props.fields.lblDateError.value,
            props.fields.lblMinMaxDateError.value
          )}
          onSubmit={onSubmitHandler}
        >
          {(formikProps) => (
            <Form>
              <div
                className="tab-pane fade show active referrals-tab"
                id="ClaimsHistory"
                role="tabpanel"
                aria-label="tab-1"
              >
                <div className="row claims-history no-tab-title">
                  {isClaimLoaded ? (
                    <div className="col-12">
                      {userAction === null &&
                      (slicedRecords === null ||
                        slicedRecords?.length === 0) ? (
                        <>
                          <div className="no-records">
                            <Text
                              field={props.fields.lblZeroClaimResultMessage}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-12 page-subheading mb-4">
                              <Text field={props.fields.Title} />
                            </div>
                          </div>
                          <DisplaySearch
                            providers={providerList}
                            handleDropdownChange={handleDropdownChange}
                            dropdownOptions={dropdownOptions}
                            props={props}
                            formikProps={formikProps}
                            showProvider={true}
                          />

                          <hr />
                          {slicedRecords && slicedRecords.length > 0 ? (
                            <>
                              {paginationPrint(
                                totalRecordsCount,
                                next,
                                props.fields,
                                isMarketplaceMember,
                                showEOB,
                                isMedicaidMember
                              )}
                              <ClaimHistoryRecords
                                fields={props.fields}
                                data={slicedRecords}
                                next={next}
                              />
                              <MobileViewClaimHistoryRecords
                                fields={props.fields}
                                data={slicedRecords}
                                next={next}
                              />
                              {totalPages !== currentPage && (
                                <a
                                  className="show-more hr-contact"
                                  onClick={(e) =>
                                    pagination(
                                      totalRecordsCount,
                                      next,
                                      page,
                                      recordsPerPage,
                                      setNext,
                                      setPage,
                                      currentPage,
                                      setCurrentPage,
                                      NUMBER_OF_RECORDS_PER_API_CALL,
                                      setFetchData
                                    )
                                  }
                                >
                                  {props.fields.lblShowMore.value}
                                </a>
                              )}
                            </>
                          ) : (
                            <p className="no-records">
                              <Text field={props.fields.lblNoResults} />
                            </p>
                          )}
                        </>
                      )}
                      {printReportDate(props.fields.lblPrintGenerated)}
                      <ServiceDescription
                        bannerMsgKY={getKYBannerMsg(props.fields)}
                        disclaimer={FooterDescription(props.fields)}
                        contactUs={props.fields.lnkContactUs}
                      />

                      {MemberStateName === OH && isMedicaidMember && (
                        <ClaimLinks fields={props.fields}></ClaimLinks>
                      )}
                    </div>
                  ) : (
                    slicedRecords?.slice(0).length === 0 && (
                      <li className="list-group-item">
                        {
                          innerSpinnerHTML?.[
                            MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY
                          ]
                        }
                        <div className="row field1">
                          <div className="col-sm-12"></div>
                        </div>
                      </li>
                    )
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default MyHealthRecordClaimsHistory;
