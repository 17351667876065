import React, { useContext, useState } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { usePCPInfoAPI } from "../../hooks/usePCPInfoAPI";
import { ModalContext } from "../../contexts/modal.context";
import BusinessHoursModal from "./BusinessHoursModal";
import {
  DELIMITER_COLON,
  SUPPORT_USER,
  TERMED_MEMBER,
  WA,
  AZ,
  CA,
  IL,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  MEDICAID,
  NY,
  NYMCC_PROGRAMID,
} from "../../define.constants";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { AppContext } from "../../contexts/app.context";
import { MY_DOCTOR } from "../../define.widgets";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const MyDoctorDoctorInfo = (props) => {
  const contextValue1 = useContext(AppContext);
  const { IsDualEligible } = contextValue1;
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { isTermedMember } = useContext(AppContext);
  const { MemberStateName, memberLOB, DSNPdetails, programId } =
    useContext(AppContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props.fields, "intent");

  const { fields } = props;
  const { pcpinfo, futurepcpinfo } = usePCPInfoAPI();
  const businessDay = fields.businessDays?.fields;
  const day = getFieldValue(businessDay, "monday", null);
  const [isTerminatedUser, setIsTerminatedUser] = useState(null);
  const link =
    pcpinfo !== "" &&
    getFieldValue(fields, "map", false)?.href + pcpinfo.address;
  const monday =
    pcpinfo?.monFrom &&
    `${day.substring(0, 3)}  ${pcpinfo.monFrom} - ${pcpinfo.monTo}`;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const isDSNPMedicaidMember =
    DSNPdetails?.length > 0 && memberLOB?.toLowerCase() === MEDICAID;
  const isNYMLTCProgram =
    memberLOB?.toLowerCase() === MEDICAID && programId === NYMCC_PROGRAMID;

  //Remove Staff Lang only for AZ-Medicare, Medicaid, DSNP JIRA2563
  const showStaffLanguage =
    MemberStateName == AZ && !isMarketplaceMember ? false : true;
  return (
    <div className="doctor-home">
      {!isTermedMember && <div className="home-link"></div>}
      <div className="row">
        <div className="col-12 d-none d-sm-none d-md-block"></div>
      </div>
      <div className="row d-lg-block d-xl-none">
        {!isTermedMember && !isDSNPMedicaidMember && (
          <div className="change-doctor-btn">
            <WhoIsAuthorized
              authorize={{
                [SUPPORT_USER]: true,
                [TERMED_MEMBER]: false,
              }}
              handleClick={(e) =>
                redirectToSSO({
                  e,
                  url: constants.apigeeURLs.SapphireChangePCPSSO,
                })
              }
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`.trim()}
                    aria-label={fields.changedoctor.value}
                    onClick={handleClick}
                    field={fields.changedoctor}
                  >
                    <Text
                      className="btn btn-primary ripple-surface"
                      field={fields.changedoctor}
                    />
                  </button>
                );
              }}
            </WhoIsAuthorized>
          </div>
        )}
      </div>

      <div className="row d-lg-block d-xl-none">
        {!isTermedMember && !isDSNPMedicaidMember && MemberStateName == WA && (
          <div className="rate-doctor-btn">
            <WhoIsAuthorized
              authorize={{
                [SUPPORT_USER]: true,
                [TERMED_MEMBER]: false,
              }}
              handleClick={(e) =>
                redirectToSSO({
                  e,
                  url: constants.apigeeURLs.SapphireChangePCPSSO,
                })
              }
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`.trim()}
                    aria-label={fields.ratedoctor.value}
                    onClick={handleClick}
                    field={fields.ratedoctor}
                  >
                    <Text
                      className="btn btn-primary ripple-surface"
                      field={fields.ratedoctor}
                    />
                  </button>
                );
              }}
            </WhoIsAuthorized>
          </div>
        )}
      </div>
      <div className="mydoctor-content">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div>
              {/*CA OPTOUT MEMBER*/}
              {!isMarketplaceMember &&
              ((IsDualEligible != null &&
                IsDualEligible.trim() === "O" &&
                (MemberStateName === CA || MemberStateName === IL)) ||
                isDSNPMedicaidMember ||
                isNYMLTCProgram) &&
              !isTermedMember ? (
                <section className="got-question-section d-flex justify-content-center">
                  <div className="card lg-card doctorinfo-card">
                    <div className="card-body doctor-card">
                      <div className="row">
                        {MemberStateName === WA ? (
                          <Text field={fields?.MyDoctorInfoMsgWA} />
                        ) : isNYMLTCProgram === true ? (
                          <Text field={fields?.MyDoctorInfoMsgNY} />
                        ) : (
                          <Text field={fields?.MyDoctorInfoMsg} />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              ) : isTermedMember ? (
                isMarketplaceMember ? (
                  <Text field={fields?.MyDoctorDisclaimer} />
                ) : (
                  <Text field={fields?.MyDoctorDisclaimerNMP} />
                )
              ) : !isMarketplaceMember && pcpinfo?.pcpName == null ? (
                <section className="got-question-section d-flex justify-content-center">
                  <div className="card lg-card doctorinfo-card">
                    {innerSpinnerHTML?.[MY_DOCTOR]}
                    <div className="card-body doctor-card">
                      <div className="row ">
                        <h5
                          className="doctor-title1 col-lg-8"
                          aria-label={fields.heading}
                        >
                          <Text field={fields?.heading} />
                        </h5>

                        <div className="col-lg-4 text-right">
                          <WhoIsAuthorized
                            authorize={{
                              [SUPPORT_USER]: true,
                              [TERMED_MEMBER]: false,
                            }}
                            handleClick={(e) =>
                              redirectToSSO({
                                e,
                                url: constants.apigeeURLs.SapphireChangePCPSSO,
                              })
                            }
                          >
                            {({ handleClick, disabledClass }) => {
                              return (
                                <button
                                  type="button"
                                  className={`btn btn-primary ${disabledClass}`.trim()}
                                  aria-label={fields.finddoctor}
                                  onClick={handleClick}
                                  field={fields.finddoctor}
                                >
                                  <Text
                                    className="btn btn-primary ripple-surface"
                                    field={fields.finddoctor}
                                  />
                                </button>
                              );
                            }}
                          </WhoIsAuthorized>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-lg-12">
                            <div>
                              <Text field={fields?.MyDoctorActiveNoPCP} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section className="got-question-section d-flex justify-content-center">
                  <div className="card lg-card doctorinfo-card">
                    {innerSpinnerHTML?.[MY_DOCTOR]}
                    <div className="card-body doctor-card">
                      <div className="row custom-container">
                        <h5
                          className="doctor-title"
                          aria-label={fields.heading}
                        >
                          <Text field={fields.heading} />
                        </h5>
                        <div className="d-none d-xl-block text-right">
                          <WhoIsAuthorized
                            authorize={{
                              [SUPPORT_USER]: true,
                              [TERMED_MEMBER]: false,
                            }}
                            handleClick={(e) =>
                              redirectToSSO({
                                e,
                                url: constants.apigeeURLs.SapphireChangePCPSSO,
                              })
                            }
                          >
                            {({ handleClick, disabledClass }) => {
                              return (
                                <button
                                  type="button"
                                  className={`btn btn-primary ${disabledClass}`.trim()}
                                  aria-label={fields.changedoctor.value}
                                  onClick={handleClick}
                                  field={fields.changedoctor}
                                >
                                  <Text
                                    className="btn btn-primary ripple-surface"
                                    field={fields.changedoctor}
                                  />
                                </button>
                              );
                            }}
                          </WhoIsAuthorized>
                        </div>
                      </div>

                      <div className="row custom-container">
                        <h5></h5>
                        {MemberStateName == WA && (
                          <div className="d-none d-xl-block text-right">
                            <WhoIsAuthorized
                              authorize={{
                                [SUPPORT_USER]: true,
                                [TERMED_MEMBER]: false,
                              }}
                              handleClick={(e) =>
                                redirectToSSO({
                                  e,
                                  url: constants.apigeeURLs
                                    .SapphireChangePCPSSO,
                                })
                              }
                            >
                              {({ handleClick, disabledClass }) => {
                                return (
                                  <button
                                    type="button"
                                    className={`btn btn-primary ${disabledClass}`.trim()}
                                    aria-label={fields.ratedoctor.value}
                                    onClick={handleClick}
                                    field={fields.ratedoctor}
                                  >
                                    <Text
                                      className="btn btn-primary ripple-surface"
                                      field={fields.ratedoctor}
                                    />
                                  </button>
                                );
                              }}
                            </WhoIsAuthorized>
                          </div>
                        )}
                      </div>
                      <div className="row field1 field-wapper">
                        <LabelValue
                          label={fields.name}
                          value={pcpinfo?.pcpName}
                        />
                        <LabelValue
                          label={fields.speciality}
                          value={pcpinfo?.speciality}
                        />
                        <LabelValue
                          label={fields.date}
                          value={pcpinfo?.effDate}
                        />
                        {/*Remove Staff Lang only for AZ-Medicare, Medicaid, DSNP JIRA2563*/}

                        {showStaffLanguage && (
                          <>
                            <LabelValue
                              label={fields.language}
                              value={pcpinfo?.language}
                            />
                          </>
                        )}
                        <LabelValue
                          label={fields.hours}
                          value={monday}
                          mainclass={"3"}
                          detailsClass={"doctor-row-details"}
                          linkText={fields.businesshours}
                          linkData={fields.businessDays}
                          pcpData={pcpinfo}
                        />
                      </div>
                      <div className="row field1 field-wapper-1">
                        <LabelValue
                          label={fields.phone}
                          value={pcpinfo?.phone}
                        />
                        <LabelValue label={fields.fax} value={pcpinfo?.fax} />
                        <LabelValue
                          label={fields.address}
                          value={pcpinfo.address}
                          detailsClass={"doctor-row-details"}
                          linkText={getFieldValue(fields, "map")?.text}
                          href={link}
                        />
                        <div className="doctor-row-details">
                          <RichText field={fields.howtochangedoctor} />
                        </div>
                      </div>
                      {/*Only user have future PCP data*/}
                      {!isMarketplaceMember && futurepcpinfo?.pcpName && (
                        <>
                          <h5
                            className="doctor-title"
                            aria-label={fields.newheading}
                          >
                            <Text field={fields.newheading} />
                          </h5>
                          <div className="row field1 field-wapper">
                            <LabelValue
                              label={fields.name}
                              value={futurepcpinfo?.pcpName}
                            />
                            <LabelValue
                              label={fields.speciality}
                              value={futurepcpinfo?.speciality}
                            />
                            <LabelValue
                              label={fields.date}
                              value={futurepcpinfo?.effDate}
                            />
                            <LabelValue
                              label={fields.language}
                              value={futurepcpinfo?.language}
                            />
                            <LabelValue
                              label={fields.hours}
                              value={monday}
                              mainclass={"3"}
                              detailsClass={"doctor-row-details"}
                              linkText={fields.businesshours}
                              linkData={fields.businessDays}
                              pcpData={futurepcpinfo}
                            />
                          </div>
                          <div className="row field1 field-wapper-1">
                            <LabelValue
                              label={fields.phone}
                              value={futurepcpinfo?.phone}
                            />
                            <LabelValue
                              label={fields.fax}
                              value={futurepcpinfo?.fax}
                            />
                            <LabelValue
                              label={fields.address}
                              value={futurepcpinfo.address}
                              detailsClass={"doctor-row-details"}
                              linkText={getFieldValue(fields, "map")?.text}
                              href={link}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LabelValue = (props) => {
  const { openModal } = useContext(ModalContext);
  const modalTest = (
    <BusinessHoursModal list={props.pcpData} {...props.linkData} />
  );
  const detailsClassName = props?.detailsClass
    ? props.detailsClass
    : "row-details";
  return (
    <div>
      <div className="row-title">
        <Text field={props.label} />
        {DELIMITER_COLON}
      </div>
      <div className={detailsClassName}>{props.value}</div>
      {props.linkText && (
        <div className="row-details docbusinesshours text-zoom-lg">
          {props.href ? (
            <a href={props.href} target="_blank" rel="noopener noreferrer">
              {props.linkText}
            </a>
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openModal({ content: modalTest });
              }}
            >
              <Text field={props.linkText} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default MyDoctorDoctorInfo;
//TestingDoctorPCPmessage//
