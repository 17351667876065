import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as AccountSettingsProfileAPI from "../services/AccountSettingsProfileAPI";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";
import { onSecuredPage } from "../utils/helpers";

const useAccountSettingsProfileAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [profileList, setProfileList] = useState([]);
  const [prefLang, setPrefLang] = useState("");
  const [sfContactId, setSFContactId] = useState("");
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [isProfileAPIResponded, setIsProfileAPIResponded] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const setData = (response) => {
    setProfileList(response);
    setPrefLang(response?.PreferredLanguage);
    setSFContactId(response?.SFContactId);
  };

  useEffect(() => {
    if (onSecuredPage()) {
      if (refreshAPI) {
        callAPI();
      } else {
        //if (profileList === []) {
        callAPI();
      }
    }
  }, [refreshAPI]);

  const callAPI = () => {
    setInnerSpinner(true, ACCOUNT_SETTINGS_MY_PROFILE);
    AccountSettingsProfileAPI.getAccountSettingsProfile({})
      .then((response) => {
        setData(response?.ResultData);
        if (refreshAPI) {
          setRefreshAPI(false);
          setIsProfileAPIResponded(true);
        }
      })
      .catch((err) => setError({ err }))
      .finally(() => setInnerSpinner(false, ACCOUNT_SETTINGS_MY_PROFILE));
  };

  const refreshGetAccountProfileAPI = (url = "") => {
    setRefreshAPI(true);
    setRedirectUrl(url);
  };
  useEffect(() => {
    if (isProfileAPIResponded) {
      setIsProfileAPIResponded(false);
      if (redirectUrl) {
        location.href = redirectUrl;
      }
    }
  }, [isProfileAPIResponded]);

  return {
    profileList,
    prefLang,
    refreshAPI,
    sfContactId,
    refreshGetAccountProfileAPI,
  };
};

export { useAccountSettingsProfileAPI };
