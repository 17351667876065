import React, { useContext, useEffect } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { clearData, handleLogin } from "../../utils/helpers";

import { ICON_PATH } from "../../define.constants";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import FooterInformation from "../Registration-MemberRegistration/FooterInformation";

const CommonLogin = (props) => {
  const { setError } = useContext(ErrorContext);
  const { openModal } = useContext(ModalContext);

  const displayLinks = (divTagClassName, linkText) => {
    return (
      <div className={`${divTagClassName}`}>
        <a
          href={getFieldValue(props.fields, `${linkText}`).href}
          className="services datcolor text-zoom"
        >
          {getFieldValue(props.fields, `${linkText}`).text}

          <img
            src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
            alt={getFieldValue(props.fields, `${linkText}`).text}
          />
        </a>
      </div>
    );
  };

  const displayPRPOAPopUp = (
    <>
      <div className="login-modal">
        <div className="update-header">
          <p>
            <Text field={props?.fields?.errorMsgPRPOA} />
          </p>
        </div>
      </div>
    </>
  );

  const displayPOAPRModal = () => {
    openModal({
      content: displayPRPOAPopUp,
      version: 1,
    });
  };

  useEffect(() => {
    clearData();
    let urlSearchParams = new URLSearchParams(window.location.search);
    let queryParams = {};
    urlSearchParams.forEach((value, key) => {
      queryParams[key.toLocaleLowerCase()] = value;
    });
    if (queryParams.redirectto)
      localStorage.setItem("landingPage", queryParams.redirectto);
    if (queryParams.mc) localStorage.setItem("messageQuery", queryParams.mc);
    if (queryParams.channel)
      localStorage.setItem("channel", queryParams.channel);

    let hash = window.location.hash;

    if (hash == "#defaulterror") {
      setError({ showDefault: true, err: { statusCode: 500 } });
    } else if (hash == "#loginfailed") {
      setError({
        customMessage: getFieldValue(props.fields, "loginErrorMsg"),
      });
    } else if (hash == "#PRActive") {
      displayPOAPRModal();
    }
  }, []);

  return (
    <section className="login">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <section className="login-section d-flex justify-content-center">
            <div className="card lg-card">
              <div className="bgcolor cookie-terms">
                <span className="alert-title-noCaps">
                  <img
                    alt={getFieldValue(props.fields, "renewAlertIconAlt")}
                    className="renew-icon p-r-5"
                    src={`${ICON_PATH}/announcement-24px.svg`}
                  />
                  <Text field={props.fields.cookieDisclaimer} />
                  <a
                    href={
                      getFieldValue(props.fields, "lnkCookieDisclaimer").href
                    }
                  >
                    {getFieldValue(props.fields, "lnkCookieDisclaimer").text}
                  </a>
                </span>
              </div>
              <div className="card-body">
                <div className="text-center">
                  <img src={`${ICON_PATH}/Molinalogo.png`} />
                </div>
                <div
                  style={{
                    font: "normal normal 30px campton-medium",
                    textAlign: "center",
                    color: "#000000",
                    marginTop: "10px",
                  }}
                >
                  <Text field={props.fields.WelcomeHeading} />
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <div style={{ marginTop: "40px" }}>
                      <button
                        type="button"
                        className="btn btn-primary ripple-surface"
                        onClick={handleLogin}
                      >
                        <Text
                          className="btn btn-primary ripple-surface"
                          field={props.fields.button}
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="create-account mt-4 mb-4"
                  style={{ textAlign: "center" }}
                >
                  <span className="d-inline-block createText">
                    <Text field={props.fields.dontHaveAnAccount} />
                  </span>
                  <span className="d-inline-block">
                    {displayLinks("", "lnkCreateAnAccount")}
                  </span>
                </div>
                <div className="create-account text-center">
                  <a
                    href="#"
                    onClick={handleLogin}
                    className="services datcolor text-zoom"
                  >
                    {getFieldValue(props.fields, "forgotUsername")}
                    <img
                      src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                    />
                  </a>
                </div>
                <div className="create-account text-center mt-2">
                  <a
                    href="#"
                    onClick={handleLogin}
                    className="services datcolor text-zoom"
                  >
                    {getFieldValue(props.fields, "forgotPwd")}
                    <img
                      src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <FooterInformation fields={props.fields} />
      </div>
    </section>
  );
};

export default withModalProvider(CommonLogin);
