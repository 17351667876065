import React, { useContext, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { SUCCESS_RESPONSE, SUCCESS_RESULT_CODE } from "../../define.constants";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { ModalContext } from "./../../contexts/modal.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { ErrorContext } from "../../contexts/error.context";

const FooterInformation = (props) => {
  const { fields } = props;
  const redStar = <span style={{ color: "red" }}>*</span>;
  const { openModal, closeModal } = useContext(ModalContext);
  const [enrollStateOptions, setEnrollStateOptions] = useState();
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const showGenericPopUp = () => {
    openModal({
      content: hippaModal,
      version: 1,
    });
  };

  useEffect(() => {
    getEnrollStateList();
  }, []);

  const getEnrollStateList = () => {
    setSpinner(true);
    RegistrationAPI.getStateListForReg({
      StateListFor: "Member",
    })
      .then((data) => {
        if (data) {
          const stateOptionsArray = [];
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE
          ) {
            data.ResultData?.map((item) => {
              stateOptionsArray.push({
                value: item.StateName === "Select" ? "" : item.StateName,
                label: item.StateName,
                id: item.StateCode === "0" ? "" : item.StateCode,
              });
            });
          }
          setEnrollStateOptions([...stateOptionsArray]);
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const OpenPWSInNewTab = (state) => {
    const lang = getFieldValue(fields, "lang");
    if (state) {
      const url = `https://www.molinahealthcare.com/members/${state}/${lang}-US/mem/hipaa/home.aspx`;
      window.open(url, "_blank");
      closeModal();
    }
  };

  const hippaModal = (
    <>
      <Formik initialValues={{ enrollState: "" }}>
        {({ touched, errors, values, setFieldValue }) => (
          <Form>
            <div className="mycoverage-modal">
              <div className="modal-body">
                <div
                  style={{
                    marginBottom: "1rem",
                    marginTop: "-2.5rem",
                    marginLeft: "-2rem",
                    marginRight: "-2rem",
                    padding: ".5rem",
                    color: "#009ea0",
                  }}
                >
                  <b>
                    <Text field={fields.hipaaNote} />
                  </b>
                </div>
                <div
                  className="text-center"
                  style={{
                    margin: "1rem",
                    font: "normal normal normal 0.88rem/0.88rem Campton-medium",
                  }}
                >
                  <b>
                    <Text field={fields.stateSelection} />
                  </b>
                </div>
                <div
                  className="row text-center"
                  style={{
                    font: "normal normal normal 0.88rem/0.88rem Campton-medium",
                  }}
                >
                  <div className="col-sm-5 StateAlign">
                    <Text field={fields.selectState} />
                    {redStar}
                  </div>
                  <div className="col-sm-7 text-left">
                    <Field
                      // id="enrollState"
                      name="enrollState"
                      as="select"
                      className={`form-gap ${highlightBorderOnError(
                        errors.enrollState,
                        touched.enrollState
                      )}`}
                      aria-label={getFieldValue(props, "enrollmentState")}
                      component={CustomSelect}
                      options={enrollStateOptions}
                      onChange={(option) => {
                        // console.log(typeof option.value);
                        // setFieldValue("enrollState", option.value);
                      }}
                      isFormik={true}
                    />
                  </div>
                </div>
                &nbsp;
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-primary m-3 w-md-35 w-sm-100"
                    onClick={() => {
                      OpenPWSInNewTab(values.enrollState);
                    }}
                  >
                    {fields?.submitButton?.value ?? ""}
                  </button>
                  <button
                    className="btn btn-primary m-3 w-md-35 w-sm-100"
                    onClick={closeModal}
                  >
                    {fields?.cancelButton?.value ?? ""}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <div
      className="text-center generic-popup"
      style={{
        marginTop: "2rem",
        font: "normal normal normal 0.72rem/0.96rem campton-medium",
        cursor: "pointer",
      }}
    >
      &nbsp;&nbsp; <span>{fields?.footerCopyRight?.value}</span>&nbsp;&nbsp;
      <a className="sign-link text-zoom" onClick={showGenericPopUp}>
        <Text field={fields.hipaaNote} />
      </a>
      <span style={{ color: "#009ea0" }}> | </span>
      <a
        className="sign-link text-zoom"
        href={getFieldValue(fields, "footerTermsOfUse")?.href}
        target="blank"
        style={{ marginLeft: "0rem" }}
      >
        {getFieldValue(fields, "footerTermsOfUse")?.text}
      </a>
    </div>
  );
};

export default FooterInformation;
