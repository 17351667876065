import Axios from "./axios/provider";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
export const getAllergiesList = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.AllergiesList, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Medications History
 *
 * @param {object} data
 * @returns
 */
export const getMedicationsHistory = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetMedicationsHistory,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * View Medications
 *
 * @param {object} data
 * @returns
 */
export const ViewMedications = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewMedications + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Claims History List
 *
 * @param {object} data
 * @returns
 */
export const getClaimsHistoryList = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemAPIServiceHistory + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Service Authorization List
 *
 * @param {object} data
 * @returns
 */
export const getServiceAuthorizationList = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ServiceAuthorizationList + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get getInpatientAndEDVisits List
 *
 * @param {object} data
 * @returns
 */
export const getInpatientAndEDVisits = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewInpatientAndEDVisits + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get View Lab Results List
 *
 * @param {object} data
 * @returns
 */
export const getViewLabResultsList = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewLabResults + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get View Member Referrals
 *
 * @param {object} data
 * @returns
 */
export const getViewMemberReferrals = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewMemberReferrals,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
/**
 *  Get CarePlanAccess
 *  @param {object} data
 *  @returns
 */
export const getCarePlanAccess = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.CarePlanAccess,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * GetCarePlan
 * @param {object} data
 * @returns
 */
export const getCarePlan = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GetCarePlan, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * GetClaimEOBDocument
 * @param {object} data
 * @returns
 */
export const getClaimEOBDocument = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetClaimEOBDocument,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCareTeamContactInfoAndAccess = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetCareTeamContactInfoAndAccess + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * GetCareTeamContactAccessManagement
 * @param {object} data
 * @returns
 */
export const getCareTeamContactAccessManagement = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetCareTeamContactAccessManagement,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * MemberConsentGetEntityNameList
 * @param {object} data
 * @returns
 */
export const MemberConsentGetEntityNameList = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemberConsentGetEntityNameList,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * MemberConsentSaveGridData
 * @param {object} data
 * @returns
 */
export const MemberConsentSaveGridData = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemberConsentSaveGridData,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
