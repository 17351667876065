import React from "react";
import { IMAGE_PATH, KY, CA, NM, OH, TX, UT, WA } from "../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { COST_ZERO } from "../../define.constants";

const ViewIdCardTpl2 = ({ fields, result, details, idCard }) => {
  return (
    <>
      {details?.PCPName_Current === null && details?.PCPName_New === null ? (
        <div className="justify-content-center row no-id-card">
          <Text field={fields.lblNoIDCard} />
        </div>
      ) : (
        <div className="row ">
          <div
            id="id-card-container"
            className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
          >
            <p className="text-align-center">
              <Text field={fields.lblFront} />
            </p>
            <div
              className="bg-front"
              style={{
                backgroundImage: `url(${IMAGE_PATH}/id/2022/2022-MP-ID-Card${
                  idCard?.OriginalMemberProfile?.State === KY ? "-KY" : ""
                }.png)`,
              }}
            >
              <div className="row id-front-header">
                <img
                  className={`id-front-logo-header${
                    idCard?.StateName === KY ? "-Passport" : ""
                  }`}
                  src={`${IMAGE_PATH}/id/2022/2022-MP-ID-Card-${
                    idCard?.StateName === KY
                      ? "Passport-By-Molina-Healthcare"
                      : "Molina-Healthcare-Logo"
                  }.svg`}
                />
                {idCard?.StateName === WA &&
                idCard?.PlanName?.toLowerCase().includes("cascade") ? (
                  <img
                    className="wa-cascade-logo"
                    src={`${IMAGE_PATH}/id/2022/2022-MP-ID-Card-WA-Cascade-Logo.png`}
                  />
                ) : null}
              </div>
              <section className="id-body">
                <div className="row card-adj">
                  <div className="card">
                    {/* Condition for CA logos */}
                    <div className="row">
                      {idCard?.StateName === CA ? (
                        <div className="col-8 ca-header">
                          <img
                            className="col-4 california-dental"
                            src={`${IMAGE_PATH}/id/2022/2022-MP-ID-Card-California-Dental.svg`}
                          />
                          <img
                            className="col-4 covered-california"
                            src={`${IMAGE_PATH}/id/2022/2022-MP-ID-Card-Covered-California.svg`}
                          />
                        </div>
                      ) : (
                        <div className="id-card-logo-hide"></div>
                      )}
                    </div>

                    {/* Condition of heading OH,UT,TX */}
                    <div
                      className={`row ${
                        [OH, UT].includes(idCard?.StateName) ? (
                          "misc-headings"
                        ) : idCard.StateName === TX ? (
                          "misc-headings-left"
                        ) : (
                          <div className="id-card-logo-hide"></div>
                        )
                      }`}
                    >
                      <Text field={fields.lblMisc} />
                    </div>
                  </div>
                  <div className="col-xxl-12 col-xl-12 c-border-bottom card-f-top">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 line-height-zero">
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblSubscriberName} />
                            </b>
                            &nbsp;
                          </label>
                          <label>{details?.Member_Name}</label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblSubscriberId} />
                            </b>
                            &nbsp;
                          </label>
                          <label>{details?.Member_Number}</label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblPlan} />
                            </b>
                          </label>
                          <label>{idCard?.PlanName}</label>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 line-height-zero">
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblMember} />
                            </b>
                          </label>
                          <label>
                            {`${idCard?.OriginalMemberProfile?.LastName}, ${idCard?.OriginalMemberProfile?.FirstName}`}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblID} />
                            </b>
                          </label>
                          <label>{idCard?.OriginalMemberProfile?.Number}</label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblEffectiveDate} />
                            </b>
                          </label>
                          <label>{details?.MemberEffectiveDate}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-12 col-xl-12">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 border-right line-height-zero">
                        <div className="form-group card-f-style">
                          <label className="table-second-heading">
                            <b>
                              <Text field={fields.lblMedicalHeading} />
                            </b>
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblPrimaryCare} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]?.PCP ??
                              COST_ZERO}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblSpecialistVisits} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.Specialist ?? COST_ZERO}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblUrgentCare} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.Urgent_Care ?? COST_ZERO}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblER} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.ER_Visit ?? COST_ZERO}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblTierOne} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.Tier_1_Rx ?? COST_ZERO}
                          </label>
                        </div>
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblTierTwo} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.Tier_2_Rx ?? COST_ZERO}
                          </label>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="form-group card-f-style line-height-zero">
                          <label className="table-second-heading">
                            <b>
                              <Text field={fields.lblDeductibles} />
                            </b>
                          </label>
                        </div>
                        <div className="form-group card-f-style line-height-zero">
                          <label>
                            <b>
                              <Text field={fields.lblMedicalDeductible} />
                            </b>
                          </label>
                          <div>
                            <label>
                              {result?.PortalDeductiblesMappingDetails[0]
                                ?.Medical_Indv_Deductible ?? COST_ZERO}
                            </label>
                          </div>
                        </div>
                        <div className="form-group card-f-style line-height-zero">
                          <label>
                            <b>
                              <Text field={fields.lblRXDeductible} />
                            </b>
                          </label>
                          <div>
                            <label>
                              {result?.PortalDeductiblesMappingDetails[0]
                                ?.RX_Indv_Deductible ?? COST_ZERO}
                            </label>
                          </div>
                        </div>
                        <div className="form-group card-f-style">
                          <label className="table-second-heading">
                            <b>
                              <Text field={fields.lblOOPMHeading} />
                            </b>
                          </label>
                        </div>
                        <div className="form-group card-f-style line-height-zero">
                          <label>
                            <b>
                              <Text field={fields.lblOOPM} />
                            </b>
                          </label>
                          <label>
                            {result?.PortalDeductiblesMappingDetails[0]
                              ?.Indv_OOPM ?? COST_ZERO}
                          </label>
                        </div>
                      </div>

                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group card-f-style">
                          {result?.RxBin && (
                            <>
                              <label>
                                <b>
                                  <Text field={fields.lblBin} />
                                </b>
                              </label>
                              <label>{result.RxBin}</label>&nbsp;
                            </>
                          )}
                          {result?.RxPCN && (
                            <>
                              <label>
                                <b>
                                  <Text field={fields.lblPCN} />
                                </b>
                              </label>
                              <label className="m-l-xs">{result.RxPCN}</label>
                              &nbsp;
                            </>
                          )}
                          {idCard?.RxGroup && (
                            <>
                              <label>
                                <b>
                                  <Text field={fields.lblGroup} />
                                </b>
                              </label>
                              <label className="m-l-xs">{idCard.RxGroup}</label>
                              &nbsp;
                            </>
                          )}
                        </div>
                        <div className="form-group card-f-style line-height-zero">
                          <label>
                            <b>
                              <Text field={fields.lblHMO} />
                            </b>
                          </label>
                          <label>
                            <Text field={fields.lblHealthCare} />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <img
                      className="cvs-caremark"
                      src={`${IMAGE_PATH}/id/2022/2022-MP-ID-Card-CVS-Caremark.svg`}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div
            id="id-card-container"
            className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
          >
            <p className="text-align-center">
              <Text field={fields.lblBack} />
            </p>
            <div
              className="bg-back"
              style={{
                backgroundImage: `url(${IMAGE_PATH}/id/2022/${
                  idCard?.OriginalMemberProfile?.State === KY
                    ? "card_back"
                    : "2022-MP-ID-Card-Back"
                }.png)`,
              }}
            >
              <div className="row">
                <div className="col-xxl-12 col-xl-12">
                  <div className="row card-content-id-back">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 border-right p-b-xs">
                      <div className="form-group card-f-style">
                        <label className="table-second-heading">
                          <b>
                            <Text field={fields.lblMemberNumbers} />
                          </b>
                        </label>
                      </div>
                      <div className="form-group card-f-style p-t-xxs line-height-zero">
                        <label>
                          <b>
                            <Text field={fields.lblIDBackServices} />
                          </b>
                        </label>
                        <label>
                          <Text field={fields.lblIDBackServicesData} />
                        </label>
                      </div>
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields.lblTTY} />
                          </b>
                        </label>
                        <label>
                          <Text field={fields.lblTTYData} />
                        </label>
                      </div>
                      <div className="form-group card-f-style p-t-xxs line-height-zero">
                        <label>
                          <b>
                            <Text field={fields.lblIDBackNAL} />
                          </b>
                        </label>
                        <label>
                          <Text field={fields.lblIDBackNALData} />
                        </label>
                      </div>
                      <div className="form-group card-f-style p-t-xxs line-height-zero">
                        <label>
                          <b>
                            <Text field={fields.lblIDBackEnfermeras} />
                          </b>
                        </label>
                      </div>
                      <div className="form-group card-f-style line-height-zero">
                        <label>
                          <Text field={fields.lblIDBackEnfermerasData} />
                        </label>
                      </div>
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields.lblBilling} />
                          </b>
                        </label>
                      </div>
                      <div className="form-group card-f-style line-height-zero">
                        <label>
                          <Text field={fields.lblBillingData} />
                        </label>
                      </div>
                      {idCard?.StateName === CA ? (
                        <>
                          <div className="form-group card-f-style line-height-zero p-t-xxs">
                            <label>
                              <b>
                                <Text field={fields.lblIDBackDental} />
                              </b>
                            </label>
                          </div>
                          <div className="form-group card-f-style line-height-zero">
                            <label>
                              <Text field={fields.lblIDBackDentalData} />
                            </label>
                          </div>
                        </>
                      ) : null}
                      <div className="form-group card-f-style m-b-5">
                        <label>
                          <RichText field={fields.lblCostShares} />
                        </label>
                      </div>
                      {idCard?.StateName !== NM ? (
                        idCard?.StateName !== CA && idCard?.StateName !== KY ? (
                          <div className="form-group card-f-style">
                            <label>
                              <b>
                                <Text field={fields.lblNotice} />
                                <Text field={fields.lblNoticeData} />
                              </b>
                            </label>
                          </div>
                        ) : null
                      ) : (
                        <div className="form-group card-f-style">
                          <label>
                            <Text field={fields.lblIDBackComplaintContact} />
                            <span>
                              <Text
                                field={fields.lblIDBackComplaintContactData}
                              />
                            </span>
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="form-group card-f-style">
                        <label className="table-second-heading">
                          <b>
                            <Text field={fields.lblProviderNumbers} />
                          </b>
                        </label>
                      </div>
                      <div className="form-group card-f-style p-b-xs p-t-xxs line-height-zero">
                        <label>
                          <b>
                            <Text field={fields.lblIDBackCVS} />
                          </b>
                        </label>
                        <div className="line-height-zero">
                          <label>
                            <Text field={fields.lblIDBackCVSData} />
                          </label>
                        </div>
                      </div>

                      <div className="form-group card-f-style p-b-xs">
                        <label>
                          <b>
                            <Text field={fields.lblIDBackNotification} />
                          </b>
                          <span>
                            <Text field={fields.lblIDBackNotificationData} />
                          </span>
                        </label>
                      </div>
                      <div className="form-group card-f-style line-height-zero p-b-xs">
                        <label>
                          <b>
                            <Text field={fields.lblMedicalClaims} />
                          </b>
                        </label>
                        <br />
                        <label>
                          <RichText field={fields.lblMedicalClaimsData} />
                        </label>
                      </div>
                      <div className="form-group card-f-style p-b-xs">
                        <label>
                          <b>
                            <Text field={fields.lblInpatientAdmissions} />
                          </b>
                          &nbsp;
                          <span>
                            <Text field={fields.lblIDBackProviderData} />
                          </span>
                        </label>
                      </div>
                      {idCard?.StateName === NM ? (
                        <div className="form-group card-f-style">
                          <label>
                            <b>
                              <Text field={fields.lblNotice} />
                            </b>
                            <span>
                              <b>
                                <Text field={fields.lblNoticeData} />
                              </b>
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {idCard?.StateName === CA || idCard?.StateName === KY ? (
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-left">
                        <div className="form-group card-f-style m-b-5 p-b-xs">
                          <label>
                            <b>
                              <div className="ca-notice p-r-10">
                                <Text field={fields.lblNotice} />
                              </div>
                              <span>
                                <Text field={fields.lblNoticeData} />
                              </span>
                            </b>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="disclaimer col-lg-12">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row card-content-id-back m-b-t-0">
                    {idCard?.StateName === KY ? (
                      <>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 card-f-style">
                          <label className="mymolina-disclaimer-ky">
                            <Text field={fields.lblIDBackMyMolina} />
                          </label>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 card-f-style">
                          <label className="p-r-20">
                            <Text field={fields.lblIDBackHeading} />
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 card-f-style">
                          <label className="mymolina-disclaimer">
                            <Text field={fields.lblIDBackMyMolina} />
                          </label>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-9 card-f-style">
                          <label className="p-r-20 v-a-top">
                            <Text field={fields.lblIDBackHeading} />
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewIdCardTpl2;
