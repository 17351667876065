import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidSC = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  console.log("BineRX", result?.RxBin);
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardSC"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/SC/Medicaid/member-id-sc.png)`,
          }}
          className="background-idcard"
          id="containerSC"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-margin-top-tablet"
          >
            <tbody>
              <tr>
                <td>
                  <div className="frontheight">&nbsp;</div>
                  <div className="clearfix">
                    <div className="float-start leftSCcard">
                      <ul className="list-unstyled mrgnListSC">
                        <li>
                          <b>
                            <Text field={fields?.lblMember} />
                          </b>
                          &nbsp; {idCard?.Member_Name}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCMemberID} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li className="SCmrgnb">
                          <b>
                            <Text field={fields?.lblSCDOB} />
                          </b>
                          &nbsp;{idCard?.Member_DOB}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPName} />
                          </b>
                          &nbsp; {idCard?.PCPName_Current}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPPhone} />
                          </b>
                          &nbsp; {idCard?.PCPPhone_Current}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPLocation} />
                          </b>
                          &nbsp;{idCard?.PCPAddress_Current}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPPrcaticeName} />
                          </b>
                          &nbsp; {idCard?.ProviderGroupName}
                        </li>
                        <li>
                          <div className="SCmrgnTop">&nbsp;</div>
                          <div className="text-center">
                            <label>
                              <Text field={fields?.lblSC24HrsNurseAdvice} />
                            </label>
                            <label>
                              <Text field={fields?.lblSCPhoneNumberValues} />
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="float-end rightSCcard">
                      <ul className="list-unstyled mrgnListSC">
                        <li>
                          <div className="height1SC">&nbsp;</div>
                          <Text field={fields?.lblSCProgram} />
                          &nbsp;
                          <Text field={fields?.lblScMedicaid} />
                          <div className="height2SC">&nbsp;</div>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblBin} />
                          </b>
                          &nbsp; {result?.IdCardValues?.RxBIN}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblPCN} />
                          </b>
                          &nbsp; {result?.IdCardValues?.RxPCN}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblGroup} />
                          </b>
                          &nbsp; {result?.IdCardValues?.RxGRP}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/SC/Medicaid/SC_Back_View.png)`,
          }}
          className="member-id-card-back id-card-back-footer"
          id="SCbackID"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSCBackMembers} />
                    </b>
                    &nbsp; <Text field={fields?.lblSCBackMemberValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSCBack24HrsNurseAdvice} />
                    </b>
                    &nbsp;{" "}
                    <Text field={fields?.lblSCBack24HrsNurseAdviceValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSCBackEmergency} />
                    </b>
                    &nbsp; <Text field={fields?.lblSCBackEmergencyValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSCBackPractionerHeading} />
                    </b>
                    &nbsp; <Text field={fields?.lblSCBackPractioneRValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSCBackPharmacists} />
                    </b>
                    &nbsp; <Text field={fields?.lblSCBackPharmacistsValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <Text field={fields?.lblSCBackCLaim} />
                    &nbsp;{" "}
                    <b>
                      <Text field={fields?.lblSCBackCLaimValue} />
                    </b>
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <Text field={fields?.lblSCBackEDIClaims} />
                    &nbsp;{" "}
                    <b>
                      <Text field={fields?.lblSCBackEDIClaimsValue} />
                    </b>
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td className="text-center">
                  <Text field={fields?.lblSCBackAddress1} />
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <Text field={fields?.lblSCBackAddress2} />
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <Text field={fields?.lblSCBackAddress3} />
                </td>
              </tr>
            </table>
          </div>
          <div className="position-absolute bottom-0 pb-3 table-font-size-back">
            &nbsp; &nbsp;
            <Text field={fields?.lblSCBackStateRequiredMaterialId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidSC;
