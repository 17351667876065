import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { MARKETPLACE, MEDICAID } from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
import { renderStatusCss, renderStatus, getshowEOB } from "./Helper";
import ReadMore from "../Common-Health-Records/ReadMore";
import DisplayItemCH from "./DisplayItemCH";
import DisplayTitleItemCH from "./DisplayTitleItemCH";
import GetEOBDocument from "./GetEOBDocument";

const MobileViewClaimHistoryRecords = (props) => {
  const { fields, data, next } = props;
  let { memberLOB, MemberStateName } = useContext(AppContext);
  let isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  let isMedicaidMember = memberLOB.toLowerCase() === MEDICAID.toLowerCase();
  return (
    <>
      <ul className="list-group d-sm-block d-md-none" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item" key={index}>
            <div className="row">
              <DisplayTitleItemCH
                item={item.Provider}
                colClass={"col-md-4 col-list-title"}
              />

              <div className="col-lg-2 col-md-4 col-6 history-details">
                <div
                  className={`history-status ${renderStatusCss(
                    item.Status.trim()
                  )}`}
                >
                  {renderStatus(item.Status.trim(), fields)}
                </div>
              </div>
              <DisplayItemCH
                nameField={fields.lblClaimId.value}
                valueField={item.ClaimID}
                outClass={"col-lg-2 col-md-4 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />

              <div id={"readMoreDetails" + index} className="">
                <ReadMore props={fields}>
                  <DisplayItemCH
                    nameField={fields.lblClaim.value}
                    valueField={item.ClaimDatestr}
                    outClass={"col-lg-2 col-md-4 details"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItemCH
                    nameField={fields.lblService.value}
                    valueField={item.ServiceDate}
                    outClass={"col-lg-2 col-md-4 details"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItemCH
                    nameField={fields.lblPaidOn.value}
                    valueField={item.PaidONstr}
                    outClass={"col-lg-2 col-md-4 details"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItemCH
                    nameField={fields.lblDescription.value}
                    valueField={item.ServiceDescription}
                    outClass={"col-lg-2 col-md-4 details"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  {isMarketplaceMember ||
                  isMedicaidMember ||
                  getshowEOB(MemberStateName) ? (
                    item?.HasEOB ? (
                      <>
                        <div className="col-lg-1 col-md-3 custom-tooltip-spending claim-items">
                          <Text field={props.fields.lblEOB} />
                          {/* <span className="custom-tooltip-spending-text">
                          <Text field={fields.lblEOBTooltip} />
                        </span>
                        <img
                          src={`${ICON_PATH}/healthinfo-24px.svg`}
                          alt={fields.lblEOBTooltip.value}
                        /> */}
                        </div>
                        <GetEOBDocument
                          claimId={item.ClaimID}
                          lblEOB={props.fields.lblEOB}
                          index={null}
                          lblEOBDoc={props.fields.lblEOBDoc}
                          divFlag={false}
                        />
                      </>
                    ) : null
                  ) : (
                    <>
                      {/* <DisplayItemCH
                        nameField={fields.lblMemberCost.value}
                        valueField={currencyValue(item.MemberCost)}
                        outClass={"col-lg-2 col-md-4 details"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItemCH
                        nameField={fields.lblAmountPaid.value}
                        valueField={currencyValue(item.AmountPaid)}
                        outClass={"col-lg-2 col-md-4 details"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItemCH
                        nameField={fields.lblAmountApproved.value}
                        valueField={currencyValue(item.AmountApproved)}
                        outClass={"col-lg-2 col-md-4 details"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      /> */}
                    </>
                  )}
                  <div
                    className="close-btn"
                    id={"closeBtnDetails" + index}
                  ></div>
                </ReadMore>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MobileViewClaimHistoryRecords;
