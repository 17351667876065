import React, { useState, useEffect, useContext } from "react";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import * as MessageAPI from "../../services/MessagesAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ErrorContext } from "../../contexts/error.context";
import { AppContext } from "./../../contexts/app.context";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { InlineFormError } from "../../common/InlineFormError";
import {
  SC,
  TX,
  WA,
  MEDICAID,
  TX_MD_STAR_PROGRAMID,
  TX_MD_PLUS_PROGRAMID,
  TX_MD_CHIPPerinate_PROGRAMID,
  TX_MD_CHIP_PROGRAMID,
  ANNUAL_REWARD_LIST_COUNT,
  SUCCESS_RESULT_CODE,
  EMAIL_REGEX,
  FIRST_NAME_REGEX,
  LAST_NAME_REGEX,
  MEMBER_REWARDS_MemberID_REGEX,
  WI,
  MI,
  KY,
  NV,
  MS,
} from "../../define.constants";
import { ModalContext } from "./../../contexts/modal.context";

const MemberRewardsForm = (props) => {
  let fields = props.fields;
  let {
    memberFirstName,
    memberLastName,
    userId,
    memberRKSID,
    memberLOB,
    memberStateCode,
    mailingAddress,
    prefPhone,
    prefEmailId,
    city,
    stateFullName,
    zip,
    MemberStateName,
    memberId,
    programId,
    isSupportUser,
  } = useContext(AppContext);
  const requiredMark = <span style={{ color: "red" }}>*</span>;
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  let [annualRewardList, setAnnualRewardList] = useState([]);
  const { openModal, closeModal } = useContext(ModalContext);
  const isSCMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === SC;
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSCCheckBocVal, setIsSCCheckBocVal] = useState(false);
  const [isCheckbox, setISCheckbox] = useState(false);
  const [isPrimaryCheckbox, setISPrimaryCheckbox] = useState(false);
  const [isSecondaryCheckbox, setISSecondaryCheckbox] = useState(false);
  const [isAllRequiredFields, setIsAllRequiredFields] = useState(false);

  let address1 = [];
  address1 = mailingAddress?.split(",");

  const initialValues = {
    memberFirstName:
      memberFirstName == null || memberFirstName == undefined
        ? ""
        : memberFirstName.trim(),
    memberLastName:
      memberLastName == null || memberLastName == undefined
        ? ""
        : memberLastName.trim(),
    memberRewardsMemberID:
      memberId == null || memberId == undefined ? "" : memberId.trim(),
    mailingAddress1: address1?.length > 0 ? address1[0] : "",
    mailingAddress2: "",
    mailingCity: city == null || city == undefined ? "" : city.trim(),
    mailingState:
      stateFullName == null || stateFullName == undefined
        ? ""
        : stateFullName.trim(),
    mailingZipCode: zip == null || zip == undefined ? "" : zip.trim(),
    phoneNumber:
      prefPhone == null || prefPhone == undefined ? "" : prefPhone.trim(),
    email:
      prefEmailId == null || prefEmailId == undefined ? "" : prefEmailId.trim(),
    optInfortextmessages: "",
  };

  const isTexasMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX;
  const isWIMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === WI;
  const isMIMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === MI;
  const isWAMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === WA;
  const isKYMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === KY;
  const isTXStar =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_STAR_PROGRAMID;
  const isTXStarPlus =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_PLUS_PROGRAMID;
  const isTXChip =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_CHIP_PROGRAMID;
  const isTXCHIPPerinate =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_CHIPPerinate_PROGRAMID;
  const isMSMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === MS;
  const isNVMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NV;

  const setScreening = () => {
    let screeningValue = null;
    if (isTexasMedicaidMember) {
      if (isTXStar === true) {
        screeningValue = fields.screeningDropdownSTAR;
      } else if (isTXStarPlus === true) {
        screeningValue = fields.screeningDropdownSTARPLUS;
      } else if (isTXCHIPPerinate === true) {
        screeningValue = fields.screeningDropdownCHIPPerinatal;
      } else if (isTXChip === true) {
        screeningValue = fields.screeningDropdownCHIP;
      } else {
        screeningValue = fields.screeningDropdown;
      }
    } else {
      screeningValue = fields.screeningDropdown;
    }
    return screeningValue;
  };

  var screeningdropdownlist = setScreening();
  let screeningdropdownValues = [];
  screeningdropdownValues = screeningdropdownlist?.value?.split(" | ");
  const screeningdropdownValuesU = screeningdropdownValues.map((screenings) => {
    return (
      <option
        label={screenings}
        key={screenings}
        value={screenings}
        title={screenings}
      />
    );
  });

  var rewardsdropdownlist = fields.rewardsChoicesDropdown;
  let rewardsdropdownValues = [];
  rewardsdropdownValues = rewardsdropdownlist?.value?.split(" | ");
  const rewardsdropdownValuesU = rewardsdropdownValues.map((rewardsChoice) => {
    return (
      <option
        label={rewardsChoice}
        key={rewardsChoice}
        value={rewardsChoice}
        title={rewardsChoice}
      />
    );
  });

  var bestTimetoReachYouDropdownlist = fields.bestTimetoReachYouDropdown;
  let bestTimetoReachYouDropdownValues = [];
  bestTimetoReachYouDropdownValues =
    bestTimetoReachYouDropdownlist?.value?.split(" | ");
  const bestTimetoReachYouDropdownValuesU =
    bestTimetoReachYouDropdownValues.map((bestTimetoReachYou) => {
      return (
        <option
          label={bestTimetoReachYou}
          key={bestTimetoReachYou}
          value={bestTimetoReachYou}
        />
      );
    });

  const bestTimetoReachYouDropdownValuesUV = [
    <option value="" label="" key="default" />,
    ...bestTimetoReachYouDropdownValuesU,
  ];

  const saveModal = () => {
    openModal({
      header: (
        <div className="request-new-id-header">
          <Text field={fields.OptinTextDisclaimer} />
        </div>
      ),
      content: popupModal(),
      version: 1,
    });
  };

  const popupModal = () => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p className="datasharing-para">
            <RichText field={fields.OptinTextDisclaimerText} />
          </p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={successdialog}
          >
            <Text field={fields.Agree} />
          </button>

          <>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={exitdialog}
            >
              <Text field={fields.Decline} />
            </button>
          </>
        </div>
      </div>
    </>
  );

  const successdialog = () => {
    closeModal();
    setISCheckbox(true);
  };

  const exitdialog = () => {
    closeModal();
    setISCheckbox(false);
  };

  const savePrimaryModal = () => {
    openModal({
      header: (
        <div className="request-new-id-header">
          <Text field={fields.OptinTextDisclaimer} />
        </div>
      ),
      content: popupPrimaryModal(),
      version: 1,
    });
  };

  const popupPrimaryModal = () => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p className="datasharing-para">
            <RichText field={fields.OptinTextDisclaimerText} />
          </p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={successPrimarydialog}
          >
            <Text field={fields.Agree} />
          </button>

          <>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={exitPrimarydialog}
            >
              <Text field={fields.Decline} />
            </button>
          </>
        </div>
      </div>
    </>
  );

  const successPrimarydialog = () => {
    closeModal();
    setISPrimaryCheckbox(true);
  };

  const exitPrimarydialog = () => {
    closeModal();
    setISPrimaryCheckbox(false);
  };

  const saveSecondaryModal = () => {
    openModal({
      header: (
        <div className="request-new-id-header">
          <Text field={fields.OptinTextDisclaimer} />
        </div>
      ),
      content: popupSecondaryModal(),
      version: 1,
    });
  };

  const popupSecondaryModal = () => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p className="datasharing-para">
            <RichText field={fields.OptinTextDisclaimerText} />
          </p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={successSecondarydialog}
          >
            <Text field={fields.Agree} />
          </button>

          <>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={exitSecondarydialog}
            >
              <Text field={fields.Decline} />
            </button>
          </>
        </div>
      </div>
    </>
  );

  const today = new Date().toISOString().split("T")[0];

  const successSecondarydialog = () => {
    closeModal();
    setISSecondaryCheckbox(true);
  };

  const exitSecondarydialog = () => {
    closeModal();
    setISSecondaryCheckbox(false);
  };

  useEffect(() => {
    getAnnaulRewardsDefaultValue();
  }, []);

  const emailRegex = EMAIL_REGEX;
  const firstNameRegex = FIRST_NAME_REGEX;
  const lastNameRegex = LAST_NAME_REGEX;
  const memberRewardsMemberIDRegex = MEMBER_REWARDS_MemberID_REGEX;

  const RequestSchema = Yup.object().shape({
    mailingAddress1: Yup.string().required(
      getFieldValue(fields, "addressValidationMSG")
    ),
    mailingCity: Yup.string().required(
      getFieldValue(fields, "mailingCityValidationMSG")
    ),
    mailingState: Yup.string().required(
      getFieldValue(fields, "mailingStateValidationMSG")
    ),
    mailingZipCode: Yup.string()
      .required(getFieldValue(fields, "zipcodeValidationMSG"))
      .test(
        "zipLenValidation",
        getFieldValue(fields, "zipLenValidation"),
        (val) => val?.length == 5 || val?.length == 10 // one extra character for '-'
      ),
    phoneNumber: Yup.string()
      .required(getFieldValue(fields, "phonenumberValidationMSG"))
      .test(
        "phoneLenValidation",
        getFieldValue(fields, "phoneLenValidation"),
        (val) =>
          val?.includes("-")
            ? val?.length >= 12 && val?.length <= 13
            : val?.length >= 10 && val?.length <= 11 // three extra character for '(', ')', and '-'
      ),

    email: Yup.string()
      .required(getFieldValue(fields, "zipcodeValidationMSG"))
      .matches(
        emailRegex,
        getFieldValue(fields, "zipcodeValidationMSG", false)
      ),
  });

  let [rewardsChoiceflag, setrewardsChoiceflag] = useState(false);

  const getAnnualRewardsValue = (e, fieldName, index) => {
    if (annualRewardList.length > 0 && e != undefined) {
      const newState = annualRewardList.map((component, indexs) => {
        if (index === indexs) {
          let rewardsChoiceValue = "";
          if (fieldName == "Screening") {
            let rewardsDefaultValue = saveRewardsChoice(
              index,
              e?.target?.value
            );
            rewardsChoiceValue =
              rewardsDefaultValue.length > 0
                ? rewardsDefaultValue[0].props?.value
                : rewardsDefaultValue?.props
                ? rewardsDefaultValue?.props?.value
                : "";
          }
          if (rewardsChoiceValue) {
            return {
              ...component,
              [fieldName]: e?.target?.value,
              RewardChoice: rewardsChoiceValue,
            };
          }
          return { ...component, [fieldName]: e?.target?.value };
        }
        return component;
      });

      setAnnualRewardList(newState);
    }
  };

  let [rewardsChoice, setRewardsChoice] = useState();

  const saveRewardsChoice = (index, screeningName) => {
    let returnValues = [];
    if (isTexasMedicaidMember) {
      if (rewardsChoiceflag === true) {
        returnValues = rewardsdropdownValuesU[0];
      } else {
        returnValues = rewardsdropdownValuesU[1];
      }
    } else if (isWAMedicaidMember) {
      annualRewardList.forEach((component, indexs) => {
        let parentScreening = "";
        if (screeningName != null) {
          parentScreening = screeningName;
        } else {
          parentScreening = component?.Screening;
        }
        if (index == indexs) {
          if (
            parentScreening === "OTC-CVS" ||
            parentScreening === "OTC-CVS CHIP RSA ONLY" ||
            parentScreening === "OTC-CVS SOLO CHIP RSA" ||
            parentScreening === "Well-Child Visits for 30-Month-Olds" ||
            parentScreening === "Prenatal Visit" ||
            parentScreening === "Postpartum Visit" ||
            parentScreening === "Childhood Immunizations" ||
            parentScreening ===
              "Consultas de control pediátrico desde el nacimiento hasta los 30 meses de edad" ||
            parentScreening === "Inmunizaciones infantiles" ||
            parentScreening === "Consulta prenatal" ||
            parentScreening === "Consulta de posparto"
          ) {
            returnValues = rewardsdropdownValuesU;
          } else {
            returnValues = rewardsdropdownValuesU[1];
          }
        }
      });
    } else if (isKYMedicaidMember) {
      annualRewardList.forEach((component, indexs) => {
        let parentScreening = "";
        if (screeningName != null) {
          parentScreening = screeningName;
        } else {
          parentScreening = component?.Screening;
        }
        if (index == indexs) {
          if (
            parentScreening === "Prenatal Visit" ||
            parentScreening === "Postpartum Visit" ||
            parentScreening === "Consulta prenatal" ||
            parentScreening === "Visita posparto"
          ) {
            returnValues = rewardsdropdownValuesU[0];
          } else {
            returnValues = rewardsdropdownValuesU;
          }
        }
      });
    } else if (isNVMedicaidMember) {
      annualRewardList.forEach((component, indexs) => {
        let parentScreening = "";
        if (screeningName != null) {
          parentScreening = screeningName;
        } else {
          parentScreening = component?.Screening;
        }
        if (index == indexs) {
          if (
            parentScreening === "Adult Preventive Dental Visit" ||
            parentScreening === "Consulta dental preventiva para adultos" ||
            parentScreening === "Adult Preventive Screening Visit" ||
            parentScreening ===
              "Consulta de detección preventiva para adultos" ||
            parentScreening === "Annual Mammogram Screening" ||
            parentScreening === "Prueba de detención de mamografía annual" ||
            parentScreening === "Cervical Cancer Screening" ||
            parentScreening === "Pruebas de detección del cáncer cervical" ||
            parentScreening === "Chlamydia Screening" ||
            parentScreening === "Prueba de detección de clamidia" ||
            parentScreening === "Diabetic Retinal Eye Exam" ||
            parentScreening === "Examen de la retina para diabéticos" ||
            parentScreening ===
              "Follow-up PCMH or participating primary care physician visit within 7 days of an inpatient hospitalization or behavioral health stay, unlimited" ||
            parentScreening ===
              "Visita de seguimiento al hogar médico centrado en el paciente (PCMH) o al doctor de atención primaria participante en los 7 días de una hospitalización o estadía de salud conductual, ilimitada" ||
            parentScreening ===
              "Follow-up visit with a behavioral health provider within 7 days of an inpatient hospitalization for mental illness" ||
            parentScreening ===
              "Consulta de seguimiento con un proveedor de salud conductual dentro de los 7 días posteriores a la hospitalización por enfermedad mental" ||
            parentScreening === "GED" ||
            parentScreening === "HbA1c Lab Test" ||
            parentScreening === "Prueba de laboratorio de HbA1c"
          ) {
            returnValues = rewardsdropdownValuesU[1];
          } else if (
            parentScreening === "Annual Baby Shower" ||
            parentScreening === "Baby shower anual" ||
            parentScreening === "Postpartum Visit" ||
            parentScreening === "Visita posparto"
          ) {
            returnValues = rewardsdropdownValuesU[0];
          } else if (
            parentScreening ===
              "Syphilis Screening And Treated Prior To Giving Birth" ||
            parentScreening ===
              "Prueba de detección de sífilis y tratamiento previo al parto" ||
            parentScreening === "Well-Child Visits 0-15 months old" ||
            parentScreening ===
              "Consultas preventivas para niños de 0 a 15 meses" ||
            parentScreening === "Well-Child Visits 15-30 months old" ||
            parentScreening ===
              "Consultas preventivas para niños de 15 a 30 meses" ||
            parentScreening === "Well-Child Visits 3-20 years old" ||
            parentScreening ===
              "Consultas preventivas para niños de 3 a 17 años"
          ) {
            returnValues = rewardsdropdownValuesU.slice(0, 2);
          } else if (
            parentScreening === "Alternatives to Opioids" ||
            parentScreening === "Alternativas a los opioides" ||
            parentScreening === "Asthma" ||
            parentScreening === "Asma" ||
            parentScreening === "Electronic Breast Pump" ||
            parentScreening === "Sacaleches electrónico" ||
            parentScreening === "Healthy Food Program" ||
            parentScreening === "Programa de alimentos saludables" ||
            parentScreening === "Obesity/Weight Watchers" ||
            parentScreening === "Obesidad/Weight Watchers" ||
            parentScreening === "Prenatal Visit" ||
            parentScreening === "Consulta prenatal" ||
            parentScreening === "Replace Lost ID Card or Birth Certificate" ||
            parentScreening ===
              "Reemplazar tarjetas de identificación perdidas o certificados de nacimiento" ||
            parentScreening === "Sam’s Club Membership" ||
            parentScreening === "Membresía de Sam’s Club"
          ) {
            returnValues = rewardsdropdownValuesU[4];
          } else {
            returnValues = rewardsdropdownValuesU.slice(0, 4);
          }
        }
      });
    } else if (isMSMedicaidMember) {
      annualRewardList.forEach((component, indexs) => {
        let parentScreening = "";
        if (screeningName != null) {
          parentScreening = screeningName;
        } else {
          parentScreening = component?.Screening;
        }
        if (index == indexs) {
          if (
            parentScreening === "minimum of 6 prenatal visits" ||
            parentScreening === "mínimo de 6 visitas prenatales"
          ) {
            returnValues = rewardsdropdownValuesU[3];
          } else {
            returnValues = rewardsdropdownValuesU.slice(0, 3);
          }
        }
      });
    } else {
      returnValues = rewardsdropdownValuesU;
    }
    return returnValues;
  };

  let [bestTimeToReachFlag, setBestTimeToReachFlag] = useState(false);
  const getBestTimetoReachYouSelect = (e, fieldName, index) => {
    if (e?.target?.value === "Other") {
      setBestTimeToReachFlag(true);
    } else {
      setBestTimeToReachFlag(false);
    }
  };

  const addRemoveAnnualRewards = (value, index) => {
    if (value == "Yes") {
      let addRewards = {};
      addRewards.Screening =
        screeningdropdownValuesU.length > 0
          ? screeningdropdownValuesU[0].props?.value
          : "";
      if (isWAMedicaidMember) {
        if (screeningdropdownValuesU.length > 0) {
          if (
            screeningdropdownValuesU[0].props?.value === "OTC-CVS" ||
            screeningdropdownValuesU[0].props?.value ===
              "OTC-CVS CHIP RSA ONLY" ||
            screeningdropdownValuesU[0].props?.value ===
              "OTC-CVS SOLO CHIP RSA" ||
            screeningdropdownValuesU[0].props?.value ===
              "Well-Child Visits for 30-Month-Olds" ||
            screeningdropdownValuesU[0].props?.value === "Prenatal Visit" ||
            screeningdropdownValuesU[0].props?.value === "Postpartum Visit" ||
            screeningdropdownValuesU[0].props?.value ===
              "Childhood Immunizations" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consultas de control pediátrico desde el nacimiento hasta los 30 meses de edad" ||
            screeningdropdownValuesU[0].props?.value ===
              "Inmunizaciones infantiles" ||
            screeningdropdownValuesU[0].props?.value === "Consulta prenatal" ||
            screeningdropdownValuesU[0].props?.value === "Consulta de posparto"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[0].props?.value
                : "";
          } else {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length >= 1
                ? rewardsdropdownValuesU[1].props?.value
                : "";
          }
        }
      } else if (isKYMedicaidMember) {
        if (screeningdropdownValuesU.length > 0) {
          if (
            screeningdropdownValuesU[0].props?.value === "Prenatal Visit" ||
            screeningdropdownValuesU[0].props?.value === "Postpartum Visit" ||
            screeningdropdownValuesU[0].props?.value === "Consulta prenatal" ||
            screeningdropdownValuesU[0].props?.value === "Visita posparto"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[0].props?.value
                : "";
          }
        }
      } else if (isNVMedicaidMember) {
        if (screeningdropdownValuesU.length > 0) {
          if (
            screeningdropdownValuesU[0].props?.value ===
              "Adult Preventive Dental Visit" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consulta dental preventiva para adultos" ||
            screeningdropdownValuesU[0].props?.value ===
              "Adult Preventive Screening Visit" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consulta de detección preventiva para adultos" ||
            screeningdropdownValuesU[0].props?.value ===
              "Annual Mammogram Screening" ||
            screeningdropdownValuesU[0].props?.value ===
              "Prueba de detención de mamografía annual" ||
            screeningdropdownValuesU[0].props?.value ===
              "Cervical Cancer Screening" ||
            screeningdropdownValuesU[0].props?.value ===
              "Pruebas de detección del cáncer cervical" ||
            screeningdropdownValuesU[0].props?.value ===
              "Chlamydia Screening" ||
            screeningdropdownValuesU[0].props?.value ===
              "Prueba de detección de clamidia" ||
            screeningdropdownValuesU[0].props?.value ===
              "Diabetic Retinal Eye Exam" ||
            screeningdropdownValuesU[0].props?.value ===
              "Examen de la retina para diabéticos" ||
            screeningdropdownValuesU[0].props?.value ===
              "Follow-up PCMH or participating primary care physician visit within 7 days of an inpatient hospitalization or behavioral health stay, unlimited" ||
            screeningdropdownValuesU[0].props?.value ===
              "Visita de seguimiento al hogar médico centrado en el paciente (PCMH) o al doctor de atención primaria participante en los 7 días de una hospitalización o estadía de salud conductual, ilimitada" ||
            screeningdropdownValuesU[0].props?.value ===
              "Follow-up visit with a behavioral health provider within 7 days of an inpatient hospitalization for mental illness" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consulta de seguimiento con un proveedor de salud conductual dentro de los 7 días posteriores a la hospitalización por enfermedad mental" ||
            screeningdropdownValuesU[0].props?.value === "GED" ||
            screeningdropdownValuesU[0].props?.value === "HbA1c Lab Test" ||
            screeningdropdownValuesU[0].props?.value ===
              "Prueba de laboratorio de HbA1c"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[1].props?.value
                : "";
          } else if (
            screeningdropdownValuesU[0].props?.value === "Annual Baby Shower" ||
            screeningdropdownValuesU[0].props?.value === "Baby shower anual" ||
            screeningdropdownValuesU[0].props?.value === "Postpartum Visit" ||
            screeningdropdownValuesU[0].props?.value === "Visita posparto"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[0].props?.value
                : "";
          } else if (
            screeningdropdownValuesU[0].props?.value ===
              "Syphilis Screening And Treated Prior To Giving Birth" ||
            screeningdropdownValuesU[0].props?.value ===
              "Prueba de detección de sífilis y tratamiento previo al parto" ||
            screeningdropdownValuesU[0].props?.value ===
              "Well-Child Visits 0-15 months old" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consultas preventivas para niños de 0 a 15 meses" ||
            screeningdropdownValuesU[0].props?.value ===
              "Well-Child Visits 15-30 months old" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consultas preventivas para niños de 15 a 30 meses" ||
            screeningdropdownValuesU[0].props?.value ===
              "Well-Child Visits 3-20 years old" ||
            screeningdropdownValuesU[0].props?.value ===
              "Consultas preventivas para niños de 3 a 17 años"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU.slice(0, 2).props?.value
                : "";
          } else if (
            screeningdropdownValuesU[0].props?.value ===
              "Alternatives to Opioids" ||
            screeningdropdownValuesU[0].props?.value ===
              "Alternativas a los opioides" ||
            screeningdropdownValuesU[0].props?.value === "Asthma" ||
            screeningdropdownValuesU[0].props?.value === "Asma" ||
            screeningdropdownValuesU[0].props?.value ===
              "Electronic Breast Pump" ||
            screeningdropdownValuesU[0].props?.value ===
              "Sacaleches electrónico" ||
            screeningdropdownValuesU[0].props?.value ===
              "Healthy Food Program" ||
            screeningdropdownValuesU[0].props?.value ===
              "Programa de alimentos saludables" ||
            screeningdropdownValuesU[0].props?.value ===
              "Obesity/Weight Watchers" ||
            screeningdropdownValuesU[0].props?.value ===
              "Obesidad/Weight Watchers" ||
            screeningdropdownValuesU[0].props?.value === "Prenatal Visit" ||
            screeningdropdownValuesU[0].props?.value === "Consulta prenatal" ||
            screeningdropdownValuesU[0].props?.value ===
              "Replace Lost ID Card or Birth Certificate" ||
            screeningdropdownValuesU[0].props?.value ===
              "Reemplazar tarjetas de identificación perdidas o certificados de nacimiento" ||
            screeningdropdownValuesU[0].props?.value ===
              "Sam’s Club Membership" ||
            screeningdropdownValuesU[0].props?.value ===
              "Membresía de Sam’s Club"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[4].props?.value
                : "";
          } else {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[0].props?.value
                : "";
          }
        }
      } else if (isMSMedicaidMember) {
        if (screeningdropdownValuesU.length > 0) {
          if (
            screeningdropdownValuesU[0].props?.value ===
            "minimum of 6 prenatal visits"
          ) {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[3].props?.value
                : "";
          } else {
            addRewards.RewardChoice =
              rewardsdropdownValuesU.length > 0
                ? rewardsdropdownValuesU[0].props?.value
                : "";
          }
        }
      } else {
        addRewards.RewardChoice =
          rewardsdropdownValuesU.length > 0
            ? rewardsdropdownValuesU[0].props?.value
            : "";
      }

      addRewards.CompletionDate = "";
      addRewards.ProviderName = "";

      if (annualRewardList.length < ANNUAL_REWARD_LIST_COUNT) {
        setAnnualRewardList((current) => [...current, addRewards]);
      }
    } else {
      if (annualRewardList.length >= 2) {
        setAnnualRewardList([
          ...annualRewardList.slice(0, index),
          ...annualRewardList.slice(index + 1, annualRewardList.length),
        ]);
      }
    }
  };

  const AnnualRewards = () => {
    return (
      <div>
        {annualRewardList?.map((component, index) => (
          <>
            <div className="row m-2" style={{ display: "flex" }}>
              <div className="col-sm-12 col-md-3 row-title">
                <label htmlFor="screening">
                  <Text field={fields.screening} />
                </label>
              </div>
              <div className="col-sm-12 col-md-5 row-title">
                <Field
                  name={"screening" + index}
                  id={"screening" + index}
                  as="select"
                  className="form-select rewards-drpdwn"
                  style={{ display: "block" }}
                  value={component.Screening}
                  onChange={(event) =>
                    getAnnualRewardsValue(event, "Screening", index)
                  }
                >
                  {screeningdropdownValuesU}
                </Field>
              </div>
            </div>
            <div className="row m-2" style={{ display: "flex" }}>
              <div className="col-sm-12 col-md-3 row-title">
                <label htmlFor="rewardsChoice">
                  <Text field={fields.rewardsChoice} />
                </label>
              </div>
              <div className="col-sm-12 col-md-5 row-title">
                <Field
                  name={"rewardsChoice" + index}
                  id={"rewardsChoice" + index}
                  as="select"
                  className="form-select rewards-drpdwn"
                  disabled={isTexasMedicaidMember ? true : false}
                  style={{ display: "block" }}
                  // value={rewardsChoiceflag ? "CVS-OTC" : component.RewardChoice}
                  value={component.RewardChoice}
                  onChange={(event) =>
                    getAnnualRewardsValue(event, "RewardChoice", index)
                  }
                >
                  {saveRewardsChoice(index, null)}
                </Field>
              </div>
            </div>
            {isWIMedicaidMember ? (
              <>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    fontWeight: "bold",
                    color: "#575757",
                    fontSize: 18,
                  }}
                >
                  <br />
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.rewardsChoicesDescriptions} />
                  </label>
                  <br />
                </div>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    bottom: "10px",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    display: "grid",
                    color: "#575757",
                  }}
                >
                  <br />
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.wholeCare} />
                  </label>
                  <label htmlFor="rewardsChoice">
                    <RichText field={fields.wholeCareDescription} />
                  </label>
                  <br />
                </div>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    bottom: "7px",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    display: "grid",
                    color: "#575757",
                  }}
                >
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.pregnancyRewards} />
                  </label>
                  <label htmlFor="rewardsChoice">
                    <RichText field={fields.pregnancyRewardsDescription} />
                  </label>
                  <br />
                </div>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    bottom: "10px",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    display: "grid",
                    color: "#575757",
                  }}
                >
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.gas} />
                  </label>
                  <label htmlFor="rewardsChoice">
                    <RichText field={fields.gasDescription} />
                  </label>
                  <br />
                </div>
              </>
            ) : isMIMedicaidMember ? (
              <>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    fontWeight: "bold",
                    color: "#575757",
                    fontSize: 18,
                  }}
                >
                  <br />
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.rewardsChoicesDescriptions} />
                  </label>
                  <br />
                </div>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    bottom: "10px",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    display: "grid",
                    color: "#575757",
                  }}
                >
                  <br />
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.wholeCare} />
                  </label>
                  <label htmlFor="rewardsChoice">
                    <RichText field={fields.wholeCareDescription} />
                  </label>
                  <br />
                </div>
                <div
                  className="pb-2"
                  style={{
                    position: "relative",
                    bottom: "7px",
                    left: "12px",
                    font: "normal normal .72rem Campton-medium",
                    display: "grid",
                    color: "#575757",
                  }}
                >
                  <label htmlFor="rewardsChoice">
                    <Text field={fields.pregnancyRewards} />
                  </label>
                  <label htmlFor="rewardsChoice">
                    <RichText field={fields.pregnancyRewardsDescription} />
                  </label>
                  <br />
                </div>
              </>
            ) : (
              " "
            )}
            <div className="row m-2" style={{ display: "flex" }}>
              <div className="col-sm-12 col-md-3 row-title">
                <label htmlFor="completionDate">
                  <Text field={fields.completionDate} />
                </label>
              </div>
              <div className="col-sm-12 col-md-4 row-title">
                <Field
                  name={"completionDate" + index}
                  id={"completionDate" + index}
                  type="date"
                  className="form-control"
                  value={component.CompletionDate}
                  max={isWAMedicaidMember ? today : undefined}
                  onChange={(event) =>
                    getAnnualRewardsValue(event, "CompletionDate", index)
                  }
                />
              </div>
            </div>
            <div className="row m-2" style={{ display: "flex" }}>
              <div className="col-sm-12 col-md-3 row-title">
                {isWAMedicaidMember ? (
                  <label htmlFor="providerOffice">
                    <Text field={fields.ProviderOffice} />
                  </label>
                ) : (
                  <label htmlFor="providerOfficeorDoctorsName">
                    <Text field={fields.providerOfficeorDoctorsName} />
                  </label>
                )}
              </div>
              <div className="col-sm-12 col-md-4 row-title">
                <Field
                  name={"providerOfficeorDoctorsName" + index}
                  id={"providerOfficeorDoctorsName" + index}
                  type="text"
                  className="form-control"
                  value={component.ProviderName}
                  onChange={(event) =>
                    getAnnualRewardsValue(event, "ProviderName", index)
                  }
                />
              </div>
            </div>
            {isWAMedicaidMember ? (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-3 row-title">
                  <label htmlFor="doctorsName">
                    <Text field={fields.DoctorsName} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 row-title">
                  <Field
                    name={"doctorsName" + index}
                    id={"doctorsName" + index}
                    type="text"
                    className="form-control"
                    value={component.DoctorsName}
                    onChange={(event) =>
                      getAnnualRewardsValue(event, "DoctorsName", index)
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {index == annualRewardList.length - 1 ? (
              <div className="row m-2" style={{ display: "flex" }}>
                <div>
                  {annualRewardList.length != ANNUAL_REWARD_LIST_COUNT ? (
                    <button
                      type="button"
                      className={"btn btn-primary m-3 w-md-35 w-sm-100"}
                      style={{ cursor: "pointer" }}
                      onClick={() => addRemoveAnnualRewards("Yes", index)}
                    >
                      <Text field={fields?.addBtn ?? ""} />
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    className={"btn btn-danger m-3 w-md-35 w-sm-100"}
                    style={{ cursor: "pointer" }}
                    onClick={() => addRemoveAnnualRewards("No", index)}
                  >
                    <Text field={fields?.removeBtn ?? ""} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="row m-2" style={{ display: "flex" }}>
                <div>
                  <button
                    type="button"
                    className={"btn btn-danger m-3 w-md-35 w-sm-100"}
                    style={{ cursor: "pointer" }}
                    onClick={() => addRemoveAnnualRewards("No", index)}
                  >
                    <Text field={fields?.removeBtn ?? ""} />
                  </button>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  const resetRewardSubmission = () => {
    var formContent = document.getElementById("collapse0");
    formContent.className = " accordion-collapse collapse";
    var formTitle = document.getElementById("formBtn");
    formTitle.className = "accordion-button collapsed";
    var formContent = document.getElementById("collapse1");
    formContent.className = " accordion-collapse collapse show";
    var formTitle = document.getElementById("staticBtn");
    formTitle.className = "accordion-button";
  };

  const intialRewardsChoice = () => {
    if (isWAMedicaidMember) {
      if (rewardsChoiceflag == true) {
        return rewardsdropdownValues[0];
      } else {
        return rewardsdropdownValues[1];
      }
    } else {
      return rewardsdropdownValues[0];
    }
  };

  const getAnnaulRewardsDefaultValue = () => {
    if (annualRewardList.length > 0) {
      annualRewardList.splice(0, annualRewardList.length);
    }
    let addRewards = {};
    addRewards.Screening =
      screeningdropdownValues?.length > 0 ? screeningdropdownValues[0] : "";
    addRewards.RewardChoice =
      rewardsdropdownValues?.length > 0 ? intialRewardsChoice() : "";
    addRewards.CompletionDate = "";
    addRewards.ProviderName = "";
    addRewards.DoctorsName = "";
    setAnnualRewardList([addRewards]);
  };

  const successModal = (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            <Text field={props?.fields?.lblSuccessMessage ?? ""} />
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );

  const successUpdate = () => {
    openModal({
      header: <div className="request-new-id-header"></div>,
      content: successModal,
      version: 1,
    });
  };

  const getFormSubmit = () => {
    setIsSubmited(true);
  };

  const validateFields = (values) => {
    setIsAllRequiredFields(false);
    let errors = {};
    if (
      values.memberFirstName.trim() == "" ||
      values.memberFirstName == undefined
    ) {
      errors.memberFirstName = getFieldValue(fields, "firstNameValidationMSG");
    } else if (values.memberFirstName.match(firstNameRegex) == null) {
      errors.memberFirstName = getFieldValue(fields, "firstNameLenValidation");
    }
    if (
      values.memberLastName.trim() == "" ||
      values.memberLastName == undefined
    ) {
      errors.memberLastName = getFieldValue(fields, "lastNameValidationMSG");
    } else if (values.memberLastName.match(lastNameRegex) == null) {
      errors.memberLastName = getFieldValue(fields, "lastNameLenValidation");
    }
    if (
      values.memberRewardsMemberID.trim() == "" ||
      values.memberRewardsMemberID == undefined
    ) {
      errors.memberRewardsMemberID = getFieldValue(
        fields,
        "healthplanIDValidationMSG"
      );
    } else if (
      values.memberRewardsMemberID.match(memberRewardsMemberIDRegex) == null
    ) {
      errors.memberRewardsMemberID = getFieldValue(
        fields,
        "healthplanIDLenValidation"
      );
    }
    if (
      values.mailingAddress1.trim() == "" ||
      values.mailingAddress1 == undefined
    ) {
      errors.mailingAddress1 = getFieldValue(fields, "addressValidationMSG");
    }
    if (values.mailingCity.trim() == "" || values.mailingCity == undefined) {
      errors.mailingCity = getFieldValue(fields, "mailingCityValidationMSG");
    }
    if (values.mailingState.trim() == "" || values.mailingState == undefined) {
      errors.mailingState = getFieldValue(fields, "mailingStateValidationMSG");
    }
    if (
      values.mailingZipCode.length == 5 || values.mailingZipCode.length == 10
        ? false
        : true
    ) {
      errors.mailingZipCode = getFieldValue(fields, "zipLenValidation");
    }
    if (values.mailingCity.trim() == "" || values.mailingCity == undefined) {
      errors.mailingZipCode = getFieldValue(fields, "zipcodeValidationMSG");
    }
    if (
      (values.phoneNumber.includes("-")
        ? values.phoneNumber?.length >= 12 && values.phoneNumber?.length <= 13
        : values.phoneNumber?.length >= 10 &&
          values.phoneNumber?.length <= 11) == false
    ) {
      errors.phoneNumber = getFieldValue(fields, "phoneLenValidation");
    }
    if (values.phoneNumber.trim() == "" || values.phoneNumber == undefined) {
      errors.phoneNumber = getFieldValue(fields, "phonenumberValidationMSG");
    }
    if (
      values.secondaryPhoneNumber?.trim() != "" &&
      values.secondaryPhoneNumber != undefined
    ) {
      if (
        (values.secondaryPhoneNumber?.includes("-")
          ? values.secondaryPhoneNumber?.length >= 12 &&
            values.secondaryPhoneNumber?.length <= 13
          : values.secondaryPhoneNumber?.length >= 10 &&
            values.secondaryPhoneNumber?.length <= 11) == false
      ) {
        errors.secondaryPhoneNumber = getFieldValue(
          fields,
          "phoneLenValidation"
        );
      }
    }

    if (values.email.trim() == "" || values.email == undefined) {
      errors.email = getFieldValue(fields, "emailValidationMSG");
    } else if (values.email.match(emailRegex) == null) {
      errors.email = getFieldValue(fields, "emailLenValidation");
    }
    if (Object.keys(errors).length > 0) {
      setIsAllRequiredFields(true);
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        setIsSubmited(true);

        let annualProgramRewards = [];
        let momMealsRewards = [];
        let optInTxtMessage = "No";
        optInTxtMessage = values?.optInfortextmessages == true ? "Yes" : "No";
        let optIntoTextPrimaryPhone = "No";
        optIntoTextPrimaryPhone =
          values?.optIntoTextPrimaryPhone == true ? "Yes" : "No";
        let optIntoTextSecondaryPhone = "No";
        optIntoTextSecondaryPhone =
          values?.optIntoTextSecondaryPhone == true ? "Yes" : "No";
        annualProgramRewards = annualRewardList;
        setSpinner(true);
        MessageAPI.SubmitMemberRewards({
          RKSMemberID: memberRKSID,
          MemberStateName: stateFullName,
          StateCode: memberStateCode,
          MemberLob: memberLOB,
          UserID: userId,
          RewardFirstName: values?.memberFirstName.trim(),
          RewardLastName: values?.memberLastName.trim(),
          RewardHealthPlanID: values?.memberRewardsMemberID.trim(),
          MailingAddress1: values?.mailingAddress1.trim(),
          MailingAddress2: values?.mailingAddress2.trim(),
          MailingCity: values?.mailingCity.trim(),
          MailingState: values?.mailingState.trim(),
          MailingZipCode: values?.mailingZipCode.trim(),
          PhoneNumber: values?.phoneNumber.trim(),
          OptIntoTextPrimaryPhone: optIntoTextPrimaryPhone,
          SecondaryPhone: values?.secondaryPhoneNumber,
          OptIntoTextSecondaryPhone: optIntoTextSecondaryPhone,
          BestTimeToReach:
            values?.bestTimetoReachYou === "Other"
              ? values?.other
              : values?.bestTimetoReachYou,
          OptInTxtMessage: optInTxtMessage,
          Email: values?.email.trim(),
          AnnualProgramRewards: annualProgramRewards,
        })
          .then((response) => {
            if (response?.ResultCode === SUCCESS_RESULT_CODE) {
              values.memberFirstName =
                memberFirstName == null || memberFirstName == undefined
                  ? ""
                  : memberFirstName.trim();
              values.memberLastName =
                memberLastName == null || memberLastName == undefined
                  ? ""
                  : memberLastName.trim();
              values.memberRewardsMemberID =
                memberId == null || memberId == undefined
                  ? ""
                  : memberId.trim();
              values.mailingAddress1 = address1?.length > 0 ? address1[0] : "";
              values.mailingAddress2 = "";
              values.mailingCity =
                city == null || city == undefined ? "" : city.trim();
              values.mailingState =
                stateFullName == null || stateFullName == undefined
                  ? ""
                  : stateFullName.trim();
              values.mailingZipCode =
                zip == null || zip == undefined ? "" : zip.trim();
              values.phoneNumber =
                prefPhone == null || prefPhone == undefined
                  ? ""
                  : prefPhone.trim();
              values.optIntoTextPrimaryPhone = false;
              values.secondaryPhoneNumber = "";
              values.optIntoTextSecondaryPhone = false;
              values.bestTimetoReachYou = "";
              values.other = "";
              values.optInfortextmessages = false;
              values.email =
                prefEmailId == null || prefEmailId == undefined
                  ? ""
                  : prefEmailId.trim();
              values.optInforfuturecommunication = false;
              setSpinner(false);
              getAnnaulRewardsDefaultValue();
              successUpdate();
              resetRewardSubmission();
              setBestTimeToReachFlag(false);
              setrewardsChoiceflag(false);
              setISCheckbox(false);
              setISPrimaryCheckbox(false);
              setISSecondaryCheckbox(false);
            }
          })
          .catch((err) => {
            setSpinner(false);
            setError({ err });
          });
      }}
      validate={validateFields}
    >
      {(props) => {
        {
          return (
            <div className="row">
              <div className="col-md-12">
                <ul className="list-group">
                  <li className="list-group-item">
                    <Form>
                      {isSCMedicaidMember ? (
                        <>
                          <div
                            className="row-title"
                            style={{ fontSize: "20px", padding: "0px" }}
                            id="headingOne"
                          >
                            <Text field={fields.lblTitle} />
                          </div>

                          <div className="row-title">
                            <div
                              className="col-xl-11 col-lg-9 col-md-8 col-sm-8 col-9"
                              style={{
                                font: "normal normal normal 0.8rem/0.96rem campton-medium",
                              }}
                            >
                              <Text
                                className="btn btn-primary ripple-surface"
                                field={fields.redeemDesc}
                              />
                            </div>{" "}
                          </div>

                          <div
                            className="row m-2"
                            style={{ display: "flex", padding: "0px" }}
                          >
                            <p
                              style={{
                                textDecoration: "underline",
                                padding: "0px",
                              }}
                            >
                              <Text
                                field={fields.indicatesRequiredfiledstart}
                              />
                              {requiredMark}
                              <Text field={fields.indicatesRequiredfiledend} />
                            </p>
                          </div>
                        </>
                      ) : (
                        " "
                      )}

                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-10">
                          {isWAMedicaidMember ? (
                            <p className="row-details">
                              <Text field={fields.message} />
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-3 row-title ">
                          <label htmlFor="memberFirstName">
                            {requiredMark}
                            <Text field={fields.memberFirstName} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                          <Field
                            name="memberFirstName"
                            id="memberFirstName"
                            type="text"
                            className={`form-control  ${
                              props.errors.memberFirstName &&
                              props.touched.memberFirstName &&
                              "is-invalid mb-0"
                            }`}
                            disabled={false}
                          />
                          <ErrorMessage
                            name="memberFirstName"
                            component={InlineFormError}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="memberLastName">
                            {requiredMark}
                            <Text field={fields.memberLastName} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                          <Field
                            name="memberLastName"
                            id="memberLastName"
                            type="text"
                            className={`form-control  ${
                              props.errors.memberLastName &&
                              props.touched.memberLastName &&
                              "is-invalid mb-0"
                            }`}
                            disabled={false}
                          />
                          <ErrorMessage
                            name="memberLastName"
                            component={InlineFormError}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="memberRewardsMemberID">
                            {requiredMark}
                            <Text field={fields.memberRewardsMemberID} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                          <Field
                            name="memberRewardsMemberID"
                            id="memberRewardsMemberID"
                            type="text"
                            className={`form-control  ${
                              props.errors.memberRewardsMemberID &&
                              props.touched.memberRewardsMemberID &&
                              "is-invalid mb-0"
                            }`}
                            disabled={false}
                          />
                          <ErrorMessage
                            name="memberRewardsMemberID"
                            component={InlineFormError}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="mailingAddress1">
                            {requiredMark}
                            <Text field={fields.mailingAddress1} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="mailingAddress1"
                            id="mailingAddress1"
                            type="text"
                            className={`form-control  ${
                              props.errors.mailingAddress1 &&
                              props.touched.mailingAddress1 &&
                              "is-invalid mb-0"
                            }`}
                            disabled={false}
                          />
                          <ErrorMessage
                            name="mailingAddress1"
                            component={InlineFormError}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="mailingAddress2">
                            <Text field={fields.mailingAddress2} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="mailingAddress2"
                            id="mailingAddress2"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="mailingCity">
                            {requiredMark}
                            <Text field={fields.mailingCity} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="mailingCity"
                            id="mailingCity"
                            type="text"
                            className={`form-control  ${
                              props.errors.mailingCity &&
                              props.touched.mailingCity &&
                              "is-invalid mb-0"
                            }`}
                          />
                          <ErrorMessage
                            name="mailingCity"
                            component={InlineFormError}
                          />
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="mailingState">
                            {requiredMark}
                            <Text field={fields.mailingState} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="mailingState"
                            id="mailingState"
                            type="text"
                            className={`form-control  ${
                              props.errors.mailingState &&
                              props.touched.mailingState &&
                              "is-invalid mb-0"
                            }`}
                          />
                          <ErrorMessage
                            name="mailingState"
                            component={InlineFormError}
                          />
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="mailingZipCode">
                            {requiredMark}
                            <Text field={fields.mailingZipCode} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="mailingZipCode"
                            id="mailingZipCode"
                            type="text"
                            className={`form-control  ${
                              props.errors.mailingZipCode &&
                              props.touched.mailingZipCode &&
                              "is-invalid mb-0"
                            }`}
                          />
                          <ErrorMessage
                            name="mailingZipCode"
                            component={InlineFormError}
                          />
                        </div>
                      </div>
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          {isSCMedicaidMember ? (
                            <label htmlFor="primaryPhoneNumber">
                              {requiredMark}
                              <Text field={fields.primaryPhoneNumber} />
                            </label>
                          ) : (
                            <label htmlFor="phoneNumber">
                              {requiredMark}
                              <Text field={fields.phoneNumber} />
                            </label>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            className={`form-control  ${
                              props.errors.phoneNumber &&
                              props.touched.phoneNumber &&
                              "is-invalid mb-1"
                            }`}
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component={InlineFormError}
                          />
                        </div>

                        {isSCMedicaidMember ? (
                          <div className="row m-2" style={{ display: "flex" }}>
                            <div className="col-sm-12 col-md-3"></div>
                            <div className="col-sm-12 col-md-4">
                              <div className="row" style={{ display: "flex" }}>
                                <div className="col-1">
                                  <Field
                                    type="checkbox"
                                    id="optIntoTextPrimaryPhone"
                                    name="optIntoTextPrimaryPhone"
                                    onClick={(e) => {
                                      savePrimaryModal();
                                    }}
                                    checked={isPrimaryCheckbox}
                                    className={`form-check-input mr-2  ${
                                      props.errors.optIntoTextPrimaryPhone &&
                                      props.touched.optIntoTextPrimaryPhone &&
                                      "is-invalid"
                                    }`}
                                  />
                                </div>
                                <div className="col-11">
                                  <p className="row-details">
                                    <Text
                                      field={fields.optInforfuturecommunication}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      {isSCMedicaidMember ? (
                        <>
                          <div className="row m-2" style={{ display: "flex" }}>
                            <div className="col-sm-12 col-md-3 row-title">
                              <label htmlFor="secondaryPhoneNumber">
                                <Text field={fields.secondaryPhoneNumber} />
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-4 row-title">
                              <Field
                                name="secondaryPhoneNumber"
                                id="secondaryPhoneNumber"
                                type="text"
                                className={`form-control  ${
                                  props.errors.secondaryPhoneNumber &&
                                  props.touched.secondaryPhoneNumber &&
                                  "is-invalid mb-1"
                                }`}
                              />
                              <ErrorMessage
                                name="secondaryPhoneNumber"
                                component={InlineFormError}
                              />
                            </div>{" "}
                            <div
                              className="row m-2"
                              style={{ display: "flex" }}
                            >
                              <div className="col-sm-12 col-md-3"></div>
                              <div className="col-sm-12 col-md-4">
                                <div
                                  className="row"
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-1">
                                    <Field
                                      type="checkbox"
                                      id="optIntoTextSecondaryPhone"
                                      name="optIntoTextSecondaryPhone"
                                      onClick={(e) => {
                                        saveSecondaryModal();
                                      }}
                                      checked={isSecondaryCheckbox}
                                      className={`form-check-input mr-2  ${
                                        props.errors
                                          .optIntoTextSecondaryPhone &&
                                        props.touched
                                          .optIntoTextSecondaryPhone &&
                                        "is-invalid"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-11">
                                    <p className="row-details">
                                      <Text
                                        field={
                                          fields.optInforfuturecommunication
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row m-2" style={{ display: "flex" }}>
                            <div className="col-sm-12 col-md-3 row-title">
                              <label htmlFor="bestTimetoReachYou">
                                <Text field={fields.bestTimetoReachYou} />
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-5 row-title">
                              <Field
                                name={"bestTimetoReachYou"}
                                id={"bestTimetoReachYou"}
                                as="select"
                                className="form-select"
                                style={{ display: "block" }}
                                onChange={(event) =>
                                  event.target.value?.length === 0
                                    ? props.setFieldValue(
                                        "bestTimetoReachYou",
                                        event.target.value
                                      ) && setBestTimeToReachFlag(false)
                                    : event.target.value === "Other"
                                    ? props.setFieldValue(
                                        "bestTimetoReachYou",
                                        event.target.value
                                      ) && setBestTimeToReachFlag(true)
                                    : props.setFieldValue(
                                        "bestTimetoReachYou",
                                        event.target.value
                                      ) && setBestTimeToReachFlag(false)
                                }
                              >
                                {bestTimetoReachYouDropdownValuesUV}
                              </Field>
                              {bestTimeToReachFlag ? (
                                <div className="col-sm-12 col-md-9 row-title">
                                  <Field
                                    name="other"
                                    id="other"
                                    type="text"
                                    maxLength={100}
                                    className={"form-control"}
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "other",
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        " "
                      )}
                      <div className="row m-2" style={{ display: "flex" }}>
                        <div className="col-sm-12 col-md-3 row-title">
                          <label htmlFor="email">
                            {requiredMark}
                            <Text field={fields.email} />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-title">
                          <Field
                            name="email"
                            id="email"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component={InlineFormError}
                          />
                        </div>
                      </div>
                      <br></br>

                      <>
                        <div className="row m-2" style={{ display: "flex" }}>
                          {!isSCMedicaidMember ? (
                            <>
                              <div className="col-1 rewards-checkbox">
                                <Field
                                  type="checkbox"
                                  id="optInfortextmessages"
                                  name="optInfortextmessages"
                                  onClick={(e) => {
                                    saveModal();
                                  }}
                                  checked={isCheckbox}
                                  className={`form-check-input mr-2  ${
                                    props.errors.optInfortextmessages &&
                                    props.touched.optInfortextmessages &&
                                    "is-invalid"
                                  }`}
                                />
                              </div>
                              <div className="col-10">
                                <p className="row-details">
                                  <Text field={fields.optInfortextmessages} />
                                </p>
                              </div>
                            </>
                          ) : (
                            " "
                          )}

                          <br></br>
                          <div
                            className="row-title"
                            style={{
                              fontWeight: "bold",
                              fontSize: "inherit",
                            }}
                          >
                            <label htmlFor="annualProgramRewards">
                              <Text field={fields.annualProgramRewards} />
                            </label>
                          </div>
                        </div>
                        <FieldArray>{AnnualRewards}</FieldArray>
                      </>

                      {isSCMedicaidMember ? (
                        <>
                          <div
                            className="row-details"
                            style={{ display: "flex" }}
                          >
                            <Text field={fields.annualScreeningsDesc} />
                          </div>
                        </>
                      ) : (
                        " "
                      )}

                      <div className="row m-2">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary m-3 w-md-35 w-sm-100"
                            disabled={isSupportUser ? true : false}
                            onClick={getFormSubmit}
                          >
                            <Text field={fields.lblSubmit} />
                          </button>
                        </div>
                        {isAllRequiredFields && isSubmited ? (
                          <div className="text-center">
                            <p className="text-danger">
                              <Text
                                field={fields.emptyRequiredFieldsValidationMSG}
                              ></Text>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {isSCMedicaidMember ? (
                        <>
                          <div
                            className="row-details"
                            style={{ display: "flex" }}
                          >
                            <Text field={fields.lastDesc} />
                          </div>
                        </>
                      ) : (
                        " "
                      )}
                    </Form>
                  </li>
                </ul>
              </div>
            </div>
          );
        }
      }}
    </Formik>
  );
};

export default MemberRewardsForm;
