import React, { useContext, useState, useEffect, useRef } from "react";
import { useGetAlertsAPI } from "../../hooks/useGetAlertsAPI";
import { useMessageAPI } from "../../hooks/useMessageAPI";
import {
  ICON_PATH,
  OTHER_TEXT,
  OER,
  PDHI,
  PRERENEWAL,
  NM,
  MEDICARE,
  MEDICAID,
  MEDICAID_REDET_BANNER_STATES,
  REDETERMINATION,
  INBOX,
  TEXTMESSAGECONSENT,
  ASSESSMENT,
  ASSESSMENT_STATUS_COMPLETED,
  DEFAULT_STATE,
  DEFAULT_LOB,
  TERMSCONDITION,
  MFA_NOTIFICATION,
  MYSUBSCRIPTIONDATE,
  MYSUBSCRIPTION,
  WI_MEDICAID_PROGRAMID,
  WI_MEDICAID_BADGERCARE,
  WI,
  DATE_MM_DD_YYYY,
} from "../../define.constants";
import { Text, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import { getStateConfiguration } from "./../../utils/helpers";
import { useWindowSize } from "./AlertSize";
import { withSSOProvider } from "../../hoc/withSSOProvider";
import { useMemberAPI } from "../../hooks/useMemberAPI";
import Description from "./Description";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./constants";
import * as qs from "qs";
import { useGetAssessmentAndValidationListAPI } from "../../hooks/useGetAssessmentAndValidationListAPI";
import { fi } from "date-fns/locale";
import { getFormattedDate } from "../../utils/helpers";
import { DateField } from "@sitecore-jss/sitecore-jss-react";
import {
  NY_MD_HARP_PROGRAMID,
  NYMCC_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  NY_MD_SWHFIDE_PROGRAMID,
} from "../../define.constants";
import * as cdpAPI from "../../services/CDPAPI";
const HeaderAlerts = ({ fields }) => {
  // Message Api call
  const { inboxMessageList } = useMessageAPI(3, INBOX, 1, "Date");
  const {
    memberLOB,
    MemberStateName,
    isTermedMember,
    RedeterminationDate,
    RedetSFMessageID,
    memberRKSID,
    programId,
    sfContactId,
    prefLang,
    isSupportUser,
    planId,
  } = useContext(AppContext);
  // let isNotTerminated = true;
  // const { EnrollmentStatus } = useMemberAPI();
  // if (EnrollmentStatus == "Inactive") {
  //   isNotTerminated = false;
  // }
  const {
    alertsData,
    showOerBanner,
    showPDHIBanner,
    showPRERENEWALBanner,
    oerBannerIds,
    pdhiBannerIds,
    prerenewalBannerIds,
    alertMemberOnNoConsents,
    bnrProgramId,
  } = useGetAlertsAPI();

  //To get the assessment status, call the below API as confirmed by API team
  const { assessments } = useGetAssessmentAndValidationListAPI();
  let { assessmentBannerToShow } = "";
  if (assessments != null && assessments.length > 0) {
    assessments?.map((assessment, index) => {
      //For completed asessement alone don't show the banner
      assessmentBannerToShow =
        assessment != null
          ? assessment.status.toLowerCase() === ASSESSMENT_STATUS_COMPLETED
            ? false
            : true
          : false;
    });
  }
  const [sitecoreData, setSitecoreData] = useState([]);
  const [readMoreIds, setReadMoreIds] = useState([]);
  const [allAlerts, setAllAlerts] = useState([]);
  const [apiAlerts, setApiAlerts] = useState([]);
  const [sitecoreAlerts, setSitecoreAlerts] = useState([]);
  const [closedAlerts, setClosedAlerts] = useState([]);
  const [carousalSettings, setCarousalSettings] = useState({});
  const [redeterminationBannerIds, SetRedeterminationBannerIds] = useState({});
  const [alertMemConsent, SetAlertMemConsentData] = useState(false);
  const [assessmentData, SetAssessmentData] = useState({});

  let maxChar = useWindowSize();

  // Creating flag for showing Redetermination Banner
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();
  const showREDETERMINATIONBanner =
    isMedicaidMember &&
    MEDICAID_REDET_BANNER_STATES?.includes(
      MemberStateName?.trim()?.toLowerCase()
    ) &&
    RedeterminationDate &&
    RedetSFMessageID &&
    !isTermedMember;

  //NY Medicaid My Subscription banner
  const dateValidation = () => {
    let current = new Date();
    let startdate = new Date(MYSUBSCRIPTIONDATE);
    let enddate = new Date();
    enddate.setDate(startdate.getDate() + 100);
    if (current <= enddate && current >= startdate) {
      return true;
    }
    return false;
  };

  //WI Medicaid My Molina Welcome banner
  const badgerCaredateValidation = () => {
    if (
      MemberStateName?.toLocaleLowerCase() === WI.toLocaleLowerCase() &&
      memberLOB?.toLocaleLowerCase() == MEDICAID?.toLowerCase()
    ) {
      let currentDate = new Date();
      let formattedDate = getFormattedDate(currentDate, "mm/dd/yyyy");
      //Formatting Badger Start Date
      let badgercareBannerStartdate = new Date(
        getFieldValue(fields, "badgercareStartdate")
      );

      let formatedBannerStartDate = getFormattedDate(
        badgercareBannerStartdate,
        "mm/dd/yyyy"
      );
      //Formatting Badger End Date
      let badgercareBannerEnddate = new Date(
        getFieldValue(fields, "badgercareEnddate")
      );
      let formatedBannerEndDate = getFormattedDate(
        badgercareBannerEnddate,
        "mm/dd/yyyy"
      );
      if (
        formattedDate <= formatedBannerEndDate &&
        formattedDate >= formatedBannerStartDate &&
        bnrProgramId === WI_MEDICAID_PROGRAMID
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //WI Medicaid My Molina Welcome banner
  const showBadgercareBanner = isMedicaidMember && badgerCaredateValidation();
  const program_inscop = [
    NY_MD_HARP_PROGRAMID,
    NYMCC_PROGRAMID,
    NY_MD_NMC_PROGRAMID,
    NY_MD_SWHFIDE_PROGRAMID,
  ];
  const isSubscription = program_inscop.includes(programId);

  const showMySubscriptionBanner =
    isMedicaidMember && dateValidation() && isSubscription;

  //add cdp alert
  useEffect(() => {
    if (sfContactId !== "") {
      cdpAPI.sendIDENTITYEvent(sfContactId).then((response) => {
        fetchCDPAlerts();
        if (window.location.pathname.toLowerCase().includes("mydoctor")) {
          cdpAPI.sendCDPUTMEvent();
        }
      });
    }
  }, [sfContactId]);

  // Getting IDs for redirection of Redetermination banner to redet. message
  useEffect(() => {
    SetAlertMemConsentData(alertMemberOnNoConsents);
  }, [alertMemberOnNoConsents]);

  //on assessment api call, set the data which re-loads the page
  useEffect(() => {
    SetAssessmentData(assessmentBannerToShow);
  }, [assessmentBannerToShow, assessments]);

  useEffect(() => {
    if (RedetSFMessageID && inboxMessageList && inboxMessageList?.length > 0) {
      for (let item of inboxMessageList) {
        if (item?.SFMessageID === RedetSFMessageID) {
          const redeterminationIDs = {
            MessageID: item.MessageID ? item.MessageID : null,
            InteractionId: item.InteractionId ? item.InteractionId : null,
            sfMessageID: item.SFMessageID ? item.SFMessageID : null,
          };
          SetRedeterminationBannerIds(redeterminationIDs);
          break;
        }
      }
    }
  }, [RedetSFMessageID, inboxMessageList]);

  let carousalSpeedInMilliSeconds =
    getFieldValue(fields, "bannerCarousalSpeed", false) * 1000;

  useEffect(() => {
    if (fields?.isAutomaticCarousal?.value === true) {
      setCarousalSettings({
        ...settings,
        autoplay: true,
        autoplaySpeed: carousalSpeedInMilliSeconds,
      });
    } else {
      setCarousalSettings(settings);
    }
  }, []);
  useEffect(() => {
    setClosedAlerts(JSON.parse(localStorage.getItem("CLOSED_ALERTS")) || []);
  }, []);
  const isLoggedin = memberRKSID && MemberStateName;
  useEffect(() => {
    const sitecoreNotificationsCommon = getStateConfiguration(
      fields,
      DEFAULT_STATE,
      DEFAULT_LOB
    );
    const sitecoreNotifications = getStateConfiguration(
      fields,
      MemberStateName,
      memberLOB
    );
    //Merge two notification list
    const sitecoreNotificationsFinal = sitecoreNotificationsCommon.concat(
      sitecoreNotifications
    );

    const sitecoreNotificationsFinalUnique = sitecoreNotificationsFinal.filter(
      (obj, index) => {
        return (
          index ===
          sitecoreNotificationsFinal.findIndex((item) => obj.id === item.id)
        );
      }
    );

    if (isLoggedin) {
      setSitecoreData(sitecoreNotificationsFinalUnique);
    } else {
      setSitecoreData(sitecoreNotificationsCommon);
    }
  }, [MemberStateName, fields, memberLOB, planId]);
  useEffect(() => {
    if (alertsData?.length) {
      const [firstAlert, secondAlert] = alertsData;
      setApiAlerts([
        ...transformAlerts(
          [firstAlert, secondAlert].filter((val) => val !== undefined)
        ),
      ]);
    }
  }, [alertsData]);

  useEffect(() => {
    if (sitecoreData?.length) {
      setSitecoreAlerts([...transformAlerts(sitecoreData, true)]);
    }
  }, [
    sitecoreData,
    showOerBanner,
    showPDHIBanner,
    showPRERENEWALBanner,
    oerBannerIds,
    pdhiBannerIds,
    prerenewalBannerIds,
    showREDETERMINATIONBanner,
    //showCovidBanner,
    redeterminationBannerIds,
    //covidBannerIds,
    alertMemConsent,
    assessmentData,
  ]);

  useEffect(() => {
    setAllAlerts([...apiAlerts, ...sitecoreAlerts]);
  }, [apiAlerts, sitecoreAlerts]);

  useEffect(() => {
    localStorage.setItem("CLOSED_ALERTS", JSON.stringify(closedAlerts));
  }, [closedAlerts]);

  useEffect(() => {
    setTimeout(function () {
      if (
        document.querySelector(".header-alerts-carousel .slick-dots") ===
        undefined
      )
        document.querySelector(
          ".header-alerts-carousel .carousel-btns-alert"
        ).style.display = "none";
    }, 700);
  }, []);

  const handleReadMore = ({ id, showMore }) => {
    if (showMore) {
      setReadMoreIds([...readMoreIds, id]);
    } else {
      setReadMoreIds([...readMoreIds.filter((val) => val !== id)]);
    }
  };
  const handleDismiss = async ({ id }) => {
    setInBrowserStorage(id);
    setAllAlerts(allAlerts.filter((alert) => alert.id !== id));
    if (id.includes("cdp_UpdatePCP") && !isSupportUser) {
      const alertTypeParam = id.slice(id.indexOf("_") + 1);
      const rep = await cdpAPI.sendCDPAlert("AlertDismiss", alertTypeParam);
      fetchCDPAlerts();
    }
  };

  const handleView = async ({ id, link }) => {
    setInBrowserStorage(id);
    setAllAlerts(allAlerts.filter((alert) => alert.id !== id));
    if (id.includes("cdp_") && !isSupportUser) {
      const alertTypeParam = id?.slice(id.indexOf("_") + 1);
      const resp = await cdpAPI.sendCDPAlert(alertTypeParam);
    }
    switch (id) {
      case "cdp_UpdatePCP":
        window.location.href =
          "/" + prefLang?.toLocaleLowerCase() + "/MyDoctor";
        break;
      case "cdp_DigitalIDCard":
        window.location.href =
          "/" + prefLang?.toLocaleLowerCase() + "/MemberIDCard";
        break;
      case "cdp_DownloadApp":
        window.open(link?.value, "_blank");
        break;
      default:
        window.location.href = "";
    }
  };

  const fetchCDPAlerts = async () => {
    const cdpAlert = await cdpAPI.getCDPAlerts(prefLang.toLowerCase());
    setApiAlerts([...transformAlerts(cdpAlert.alerts, false, true)]);
  };

  // useEffect(() => {
  //   SetCovidBannerIds("test") || [];
  // }, []);

  const transformAlerts = (alerts, isSitecore = false, isCDP = false) => {
    if (!alerts) return [];
    return alerts
      ?.map((alert, index) => {
        if (isSitecore) {
          const {
            title,
            descriptionBanner,
            continuedTitle,
            titleCaps,
            ssoLinkText,
            ssoEndPoint,
            link,
            linkAndDesc,
            bannerTypes,
            excludePlanIds,
          } = alert.fields;
          if (
            ((!bannerTypes ||
              getFieldValue(bannerTypes?.fields, "text") === OER) &&
              !showOerBanner) ||
            (getFieldValue(bannerTypes?.fields, "text") === PDHI &&
              !showPDHIBanner) ||
            (getFieldValue(bannerTypes?.fields, "text") === PRERENEWAL &&
              !showPRERENEWALBanner) ||
            (getFieldValue(bannerTypes?.fields, "text") === REDETERMINATION &&
              !showREDETERMINATIONBanner) ||
            (getFieldValue(bannerTypes?.fields, "text") ===
              TEXTMESSAGECONSENT &&
              !alertMemConsent) ||
            (getFieldValue(bannerTypes?.fields, "text") === ASSESSMENT &&
              (!assessmentData || assessmentData === undefined)) ||
            (getFieldValue(bannerTypes?.fields, "text") === MYSUBSCRIPTION &&
              !showMySubscriptionBanner) ||
            ((!bannerTypes ||
              getFieldValue(bannerTypes?.fields, "text") === TERMSCONDITION) &&
              isLoggedin) ||
            ((!bannerTypes ||
              getFieldValue(bannerTypes?.fields, "text") ===
                MFA_NOTIFICATION) &&
              !isLoggedin) ||
            ((!bannerTypes ||
              getFieldValue(bannerTypes?.fields, "text") ===
                WI_MEDICAID_BADGERCARE) &&
              !showBadgercareBanner) ||
            (getFieldValue(alert?.fields, "beforeLogin") === false &&
              !isLoggedin)
          ) {
            return {};
          }

          // Preparing Banner Description
          let descriptionValue = getFieldValue(alert, "ssoLinkText", false)
            ? ssoLinkText
            : linkAndDesc;
          // Redetermination banner description
          descriptionValue =
            getFieldValue(bannerTypes?.fields, "text") === REDETERMINATION
              ? descriptionBanner
              : descriptionValue;

          // TextMessage- banner description
          if (alertMemConsent) {
            descriptionValue =
              getFieldValue(bannerTypes?.fields, "text") === TEXTMESSAGECONSENT
                ? descriptionBanner
                : descriptionValue;
          }

          // Assesments - banner description
          if (assessmentData || assessmentData != undefined) {
            descriptionValue =
              getFieldValue(bannerTypes?.fields, "text") === ASSESSMENT
                ? descriptionBanner
                : descriptionValue;
          }

          const description =
            descriptionValue?.value?.href === "" ? " " : descriptionValue;
          if (getFieldValue(alert, "titleCaps") === false) {
            title.value = title?.value?.toUpperCase();
            continuedTitle.value = continuedTitle?.value?.toUpperCase();
          }

          // Preparing BannerIDs
          let bannerIds = {};
          switch (getFieldValue(bannerTypes?.fields, "text")) {
            case PDHI:
              bannerIds = pdhiBannerIds;
              break;
            case OER:
              bannerIds = oerBannerIds;
              break;
            case PRERENEWAL:
              bannerIds = prerenewalBannerIds;
              break;
            case REDETERMINATION:
              bannerIds = redeterminationBannerIds;
              break;
            //case COVID:
            //bannerIds = covidBannerIds;
            //break;
            default:
              bannerIds = {};
              break;
          }
          if (
            excludePlanIds?.value === "" ||
            (excludePlanIds?.value !== "" &&
              excludePlanIds?.value.toString().split(",").indexOf(planId) < 0)
          ) {
            return {
              id: `sitecore_${index}`,
              title,
              continuedTitle,
              description,
              ssoEndPoint,
              isSSO: !!getFieldValue(alert, "ssoEndPoint", false),
              link,
              isSitecoreAlert: isSitecore,
              bannerIds,
            };
          } else {
            return {};
          }
        } else if (isCDP) {
          return {
            id: `cdp_${alert?.AlertType}`,
            title: { value: alert?.Description },
            description: "",
            link: { value: alert?.Link },
            isSitecoreAlert: false,
          };
        } else {
          const alertType =
            alert.AlertType !== OTHER_TEXT
              ? alert.AlertType
              : alert.OtherAlertTypeText;
          return {
            id: `api_${index}`,
            title: { value: alertType },
            description: { value: alert.Description },
            isSitecoreAlert: false,
          };
        }
      })
      .filter((alert) => {
        return (
          !closedAlerts?.includes(alert.id) && Object.keys(alert).length !== 0
        );
      });
  };

  const closeAlert = (id) => {
    setInBrowserStorage(id);
    setAllAlerts(allAlerts.filter((alert) => alert.id !== id));
    if (id.includes("cdp_") && !isSupportUser) {
      const alertTypeParam = id.slice(id.indexOf("_") + 1);
      if (id == "cdp_DigitalIDCard") {
        const rep = cdpAPI.sendCDPAlert("AlertDismiss", alertTypeParam);
      } else {
        const rep = cdpAPI.sendCDPAlert("AlertClosed", alertTypeParam);
      }
    }
  };

  const setInBrowserStorage = (id) => {
    setClosedAlerts([...closedAlerts, id]);
  };
  const IsNotLoggedin = () => {
    let isnotloggedin = false;
    isnotloggedin =
      //getFieldValue(alert.fields.bannerTypes?.fields, "text") ===
      // TERMSCONDITION &&
      !memberRKSID && !MemberStateName;
    return isnotloggedin;
  };
  // Don't show alert for NM - Medicare
  // Showing alert for NM - Medicare as a part of CY2024
  // const showAlert =
  //   MemberStateName?.toLowerCase() === NM.toLowerCase() &&
  //   memberLOB?.toLowerCase() === MEDICARE.toLowerCase()
  //     ? false
  //     : true;

  return (
    <>
      <div className="alert-wrapper">
        {allAlerts.length > 0 && (
          <div className="container-fluid">
            <div className="row header-alerts-carousel">
              <div className="col-md-12">
                <div className="links">
                  <Slider {...carousalSettings}>
                    {allAlerts?.map((alert, index) => {
                      const isReadMore = readMoreIds.includes(alert.id);
                      const learnMore = getFieldValue(alert, "link", false);
                      return (
                        <div className="col-lg-12 pad-mb" key={index}>
                          <div
                            className={`alert alert-light alert-container-renew ${
                              IsNotLoggedin() ? "bgcolor" : ""
                            }`.trim()}
                            id="alertContentRenew"
                          >
                            <div className="row">
                              <div className="col-lg-1 col-md-1 col-sm-1 t-mid-par">
                                {allAlerts?.length > 1 && (
                                  <div className="carousel-btns-alert t-mid-child text-align-center">
                                    <a
                                      className="carousel-btn-alert-prev"
                                      onClick={() =>
                                        document
                                          .querySelector(
                                            ".header-alerts-carousel .slick-slider .slick-prev"
                                          )
                                          .click()
                                      }
                                    >
                                      <img
                                        src={`${ICON_PATH}/arrow-left-24px.svg`}
                                        className="arrow-size-xl"
                                        alt={getFieldValue(
                                          fields,
                                          "previousAlt"
                                        )}
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-10 col-md-10 col-sm-9 p-r-xs">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 p-lg-0 p-l-r-0">
                                    <p className="renew-title">
                                      <span className="alert-title-noCaps">
                                        <img
                                          alt={getFieldValue(
                                            fields,
                                            "renewAlertIconAlt"
                                          )}
                                          className="renew-icon p-r-5"
                                          src={`${ICON_PATH}/announcement-24px.svg`}
                                        />
                                        <Text field={alert.title} />
                                      </span>
                                      {!IsNotLoggedin() && (
                                        <Description
                                          isReadMore={isReadMore}
                                          alert={alert}
                                          maxChar={maxChar}
                                          fields={fields}
                                          handleReadMore={handleReadMore}
                                          handleDismiss={handleDismiss}
                                          handleView={handleView}
                                        />
                                      )}
                                      {alert?.isSitecoreAlert &&
                                        learnMore.text && (
                                          <span className="card-link-learn-more text-zoom-lg">
                                            <a
                                              className="state-color-txt"
                                              aria-label={learnMore.text}
                                              target={learnMore?.target}
                                              href={`${
                                                learnMore.href
                                              }${qs.stringify(
                                                {
                                                  mid: alert?.bannerIds
                                                    ?.MessageID,
                                                  iid: alert?.bannerIds
                                                    ?.InteractionId,
                                                  ...(showREDETERMINATIONBanner
                                                    ? {
                                                        sfid: alert?.bannerIds
                                                          ?.sfMessageID,
                                                      }
                                                    : {}),
                                                },
                                                {
                                                  addQueryPrefix: true,
                                                }
                                              )}`}
                                            >
                                              {learnMore.text}
                                            </a>
                                            <img
                                              className="link-icon img-link-icon"
                                              src={`${ICON_PATH}/arrow-right-md.svg`}
                                            />
                                          </span>
                                        )}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-1 col-md-1 col-sm-1 t-mid-par">
                                {allAlerts?.length > 1 && (
                                  <div className="carousel-btns-alert t-mid-child p-t-9 p-xs-0">
                                    <a
                                      className="carousel-btn-alert-next"
                                      onClick={() =>
                                        document
                                          .querySelector(
                                            ".header-alerts-carousel .slick-slider .slick-next"
                                          )
                                          .click()
                                      }
                                    >
                                      <img
                                        src={`${ICON_PATH}/arrow-right.svg`}
                                        className="arrow-size-xl"
                                        alt={getFieldValue(fields, "nextAlt")}
                                      />
                                    </a>
                                  </div>
                                )}
                                <div className="">
                                  <button
                                    type="button"
                                    id={alert.id}
                                    className="btn-alert-modal"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => {
                                      closeAlert(alert.id);
                                    }}
                                  >
                                    <span aria-hidden="true"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withSSOProvider(HeaderAlerts);
