import Axios from "./axios/provider";

/**
 * Registration - EnrollmentState List!
 *
 * @param {object} data
 * @returns
 */
export const getStateListForReg = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetStateListForReg,
      data
    );
    return response;
  } catch (error) {
    console.log(error, "Reg. API");
    throw error;
  }
};

/**
 * Registration - Validate Membership!
 *
 * @param {object} data
 * @returns
 */
export const getValidateMembership = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ValidateMembership,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Checks if email exists in system or not!
 *
 * @param {object} data
 * @returns
 */
export const getEmailExistsInSystem = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.EmailExistsInSystem,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Checks if userid exists in system or not!
 *
 * @param {object} data
 * @returns
 */
export const getUserIdExistsInSystem = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UserIdExistsInSystem,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Create Account!
 *
 * @param {object} data
 * @returns
 */
export const getCreateAccount = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.CreateAccount, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Validates security code for Step-3 Authentication!
 *
 * @param {object} data
 * @returns
 */
export const getValidateSecurityCode = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ValidateSecurityCode,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Sends security code for Step-3 Authentication!
 *
 * @param {object} data
 * @returns
 */
export const getSendOrResendSecurityCode = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SendOrResendSecurityCode,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - ForgotUserIDPassword
 *
 * @param {object} data
 * @returns
 */
export const getForgotUserIDPassword = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.ForgotUIDPd, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/* Registration - ForgotUserIDPasswordPost
 *
 * @param {object} data
 * @returns
 */
export const getForgotUserIDPasswordPost = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ForgotUIDPdPost,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - ResetPassword
 *
 * @param {object} data
 * @returns
 */
export const getResetPassword = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.ResetPd, data);
    return response;
  } catch (error) {
    throw error;
  }
};
/**
 * Registration - Send CDP Event!
 *
 * @param {object} data
 * @returns
 */
export const sendRegistrationCDPEvent = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.SendCDPEvent, data);
    return response;
  } catch (error) {
    console.log(error, "Reg. API");
    throw error;
  }
};
