import React, { useState, useEffect, useContext } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Field, ErrorMessage } from "formik";
import {
  dynamicControlConstants,
  assessmentConstants,
} from "../../utils/constants";
import { getFormattedDate } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { Calendar } from "../../common/Calendar";
import {
  SPANISH_LANG_TEXT,
  SPANISH_LANG_CODE,
  VISIBLE_OPTION,
} from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
const FormikControls = (props) => {
  try {
    const controls = () => {
      let className = "col-lg-6";
      const { question } = props;
      switch (question.AspControlType) {
        case dynamicControlConstants.textbox:
          return (
            <>
              <TextBoxControl data={props} className={className} />
            </>
          );
        case dynamicControlConstants.textarea:
          className = "col-sm-12";
          return (
            <>
              <TextAreaControl data={props} className={className} />
            </>
          );
        case dynamicControlConstants.date:
          return (
            <>
              <DateControl data={props} className={className} />
            </>
          );
        case dynamicControlConstants.select:
          return (
            <>
              <SelectControl data={props} className={className} />
              {question.IsChild && (
                <ChildQuetionsBinding data={props} className={className} />
              )}
            </>
          );
        case dynamicControlConstants.radio:
          return (
            <>
              <RadioControl data={props} className={className} />
              {question.IsChild && (
                <ChildQuetionsBinding data={props} className={className} />
              )}
            </>
          );
        case dynamicControlConstants.checkbox:
          return (
            <>
              <CheckBoxGroupControl data={props} className={className} />
              {question.IsChild && (
                <ChildQuetionsBinding data={props} className={className} />
              )}
            </>
          );
        case dynamicControlConstants.hidden:
          className = "col-sm-12";
          return (
            <>
              <HiddenControl data={props} className={className} />
            </>
          );
        case dynamicControlConstants.scoreid:
          return (
            <>
              <ScoreIdControl data={props} className={className} />
            </>
          );
        default:
          return <></>;
      }
    };
    return <>{controls()} </>;
  } catch (e) {
    console.log(e);
  }
};
FormikControls.defaultProps = {
  parentQuestionId: 0,
  isChild: false,
};
export default FormikControls;
const ChildQuetionsBinding = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      assessmentOptions,
      assessmentContent,
      values,
      setFieldValue,
      disabledPage,
    } = props.data;
    const { className } = props;
    return (
      <>
        {question.SubListQuestion?.map((subQuestion, i) => {
          return (
            <div className="col-sm-12" key={i}>
              <div className="row">
                <FormikControls
                  question={subQuestion}
                  index={i}
                  errors={errors}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  assessmentOptions={assessmentOptions}
                  assessmentContent={assessmentContent}
                  isChild={true}
                  parentQuestion={question}
                  parentQuestionId={subQuestion.ParentQuestionId}
                  disabledPage={disabledPage}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const TextAreaControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      setFieldValue,
      isChild,
      values,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    return (
      <>
        <ControlBox data={props.data} colClass="">
          <BindQuestionSerialNo data={props.data} />
          {question.IsVisibleOption !== VISIBLE_OPTION && (
            <>
              <div className={className}>
                <div className="spacing-left">
                  <Field
                    name={`question_${question.QuestionId}`}
                    as={`textarea`}
                    rows={question.Rows}
                    cols={question.Cols}
                    maxLength={question.MaxLength}
                    disabled={disabledPage ? true : isReadOnly}
                    className={
                      "form-control describe" +
                      (errors[`question_${question.QuestionId}`] &&
                      touched[`question_${question.QuestionId}`]
                        ? " is-invalid"
                        : "")
                    }
                  />
                </div>
              </div>
              <ErrorMessage
                name={`question_${question.QuestionId}`}
                component="div"
                className={InlineFormError}
              />
            </>
          )}
        </ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const TextBoxControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      values,
      setFieldValue,
      isChild,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    return (
      <>
        <ControlBox data={props.data} colClass="">
          <div className="col-lg-12">
            <BindQuestionSerialNo data={props.data} />
            {question.IsVisibleOption !== VISIBLE_OPTION && (
              <>
                <div className={className}>
                  <div className="spacing-left">
                    <Field
                      name={`question_${question.QuestionId}`}
                      type={`text`}
                      disabled={disabledPage ? true : isReadOnly}
                      className={
                        "form-control" +
                        (errors[`question_${question.QuestionId}`] &&
                        touched[`question_${question.QuestionId}`]
                          ? " is-invalid"
                          : "")
                      }
                    />
                  </div>
                  <ErrorMessage
                    name={`question_${question.QuestionId}`}
                    component="div"
                    className={InlineFormError}
                  />
                </div>
              </>
            )}
          </div>
        </ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const DateControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      setFieldValue,
      isChild,
      values,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    return (
      <>
        <ControlBox data={props.data} colClass="">
          <div className="col-lg-12 date-picker">
            <BindQuestionSerialNo data={props.data} />
            {question.IsVisibleOption !== VISIBLE_OPTION && (
              <>
                <div className={className}>
                  <div className="spacing-left">
                    <Field
                      component={Calendar}
                      name={`question_${question.QuestionId}`}
                      value={
                        values[`question_${question.QuestionId}`] == ""
                          ? null
                          : values[`question_${question.QuestionId}`]
                      }
                      onChange={(value) => {
                        setFieldValue(
                          `question_${question.QuestionId}`,
                          getFormattedDate(value).replace(/, /g, " ")
                        );
                      }}
                      aria-label={`question_${question.QuestionId}`}
                      disabled={disabledPage ? true : isReadOnly}
                      className={
                        "form-control" +
                          errors[`question_${question.QuestionId}`] &&
                        touched[`question_${question.QuestionId}`]
                          ? " is-invalid"
                          : ""
                      }
                    />
                  </div>
                </div>
                <ErrorMessage
                  name={`question_${question.QuestionId}`}
                  component={InlineFormError}
                />
              </>
            )}
          </div>
        </ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const SelectControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      setFieldValue,
      assessmentOptions,
      isChild,
      values,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    let dropoptions = [];
    dropoptions.push({
      value: "",
      id: "",
      label: "Select",
    });
    assessmentOptions
      ?.filter((obj) => obj.QuestionId === question.QuestionId)
      .map((data) => {
        let optionText = PreferredLanguage(data, "option");

        dropoptions.push({
          value: data.OptionId + "",
          id: data.OptionId + "",
          label: optionText,
        });
      });
    if (
      assessmentOptions?.filter((obj) => obj.QuestionId === question.QuestionId)
        .length > 0
    )
      return (
        <>
          <ControlBox data={props.data} colClass="">
            <BindQuestionSerialNo data={props.data} />
            {question.IsVisibleOption !== VISIBLE_OPTION && (
              <>
                <div className={className}>
                  <div className="spacing-left">
                    <Field
                      as="select"
                      name={`question_${question.QuestionId}`}
                      id={`question_${question.QuestionId}`}
                      placeholder={getFieldValue(
                        props?.fields,
                        "selectPlaceholder",
                        false
                      )}
                      options={dropoptions}
                      component={CustomSelect}
                      isDisabled={disabledPage ? true : isReadOnly}
                      customclass={
                        errors[`question_${question.QuestionId}`] &&
                        touched[`question_${question.QuestionId}`]
                          ? " molina-select__control_err"
                          : ""
                      }
                      onChange={(event) => {
                        onChangeControl(
                          event,
                          question,
                          isChild,
                          setFieldValue
                        );
                      }}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name={`question_${question.QuestionId}`}
                  component="div"
                  className={InlineFormError}
                />
              </>
            )}
          </ControlBox>
        </>
      );
    else return null;
  } catch (e) {
    console.log(e);
  }
};
const RadioControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      assessmentOptions,
      setFieldValue,
      isChild,
      values,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    const colSize =
      12 / (question.OptionDisplaySize === 0 ? 1 : question.OptionDisplaySize);
    if (
      assessmentOptions?.filter((obj) => obj.QuestionId === question.QuestionId)
        .length > 0
    )
      return (
        <>
          <div className="col-lg-12">
            <ControlBox data={props.data} colClass="">
              <div className="question-gap">
                <BindQuestionSerialNo data={props.data} />
                {question.IsVisibleOption !== VISIBLE_OPTION && (
                  <div className="spacing-left">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="row" role="questions">
                          {assessmentOptions
                            ?.filter(
                              (obj) => obj.QuestionId === question.QuestionId
                            )
                            .map((option, index) => {
                              let optionText = PreferredLanguage(
                                option,
                                "option"
                              );
                              return (
                                <div
                                  className={`col-lg-${colSize}`}
                                  key={index}
                                >
                                  <div className="form-check">
                                    <Field
                                      id={`question_${index}_${question.QuestionId}`}
                                      name={`question_${question.QuestionId}`}
                                      type={`radio`}
                                      disabled={
                                        disabledPage ? true : isReadOnly
                                      }
                                      className={
                                        "form-check-input" +
                                        (errors[
                                          `question_${question.QuestionId}`
                                        ] &&
                                        touched[
                                          `question_${question.QuestionId}`
                                        ]
                                          ? " is-invalid"
                                          : "")
                                      }
                                      value={option.OptionId}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `question_${question.QuestionId}`,
                                          event.target.value
                                        );
                                        onChangeControl(
                                          event,
                                          question,
                                          isChild,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`question_${index}_${question.QuestionId}`}
                                      className="label-radio"
                                    >
                                      {optionText}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <ErrorMessage
                          name={`question_${question.QuestionId}`}
                          component="div"
                          className={InlineFormError}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ControlBox>
          </div>
        </>
      );
    else return null;
  } catch (e) {
    console.log(e);
  }
};
const CheckBoxGroupControl = (props) => {
  try {
    const {
      question,
      errors,
      touched,
      assessmentOptions,
      setFieldValue,
      values,
      isChild,
      parentQuestion,
      disabledPage,
    } = props.data;
    const { className } = props;
    let isReadOnly = question.IsReadOnly;
    if (isChild && !isReadOnly) {
      isReadOnly = CheckConditionForBinding(
        parentQuestion,
        question,
        values,
        setFieldValue
      );
    }
    const questionOptionsList = assessmentOptions?.filter(
      (obj) => obj.QuestionId === question.QuestionId
    );
    const noneOption =
      questionOptionsList?.filter(
        (o) =>
          o.OptionText.toLowerCase().indexOf(assessmentConstants.none) !== -1
      ).length > 0
        ? questionOptionsList?.filter(
            (o) =>
              o.OptionText.toLowerCase().indexOf(assessmentConstants.none) !==
              -1
          )[0]
        : [];
    const colSize =
      12 / (question.OptionDisplaySize === 0 ? 1 : question.OptionDisplaySize);
    return (
      <>
        <ControlBox
          data={props.data}
          colClass={questionOptionsList?.length > 0 ? "" : "col-sm-12 pb-2"}
        >
          <BindQuestionSerialNo data={props.data} />
          {question.IsVisibleOption !== VISIBLE_OPTION && (
            <>
              <div className="row" id={`question_chk_${question.QuestionId}`}>
                <div className="col-xl-6">
                  {questionOptionsList?.length > 0 && (
                    <div className="spacing-left">
                      <div
                        className="row"
                        role="group"
                        aria-labelledby={`question_chk_${question.QuestionId}`}
                      >
                        {questionOptionsList?.map((option, index) => {
                          let optionText = PreferredLanguage(option, "option");
                          return (
                            <div className={`col-lg-${colSize}`} key={index}>
                              <div className="form-check">
                                <Field
                                  id={`question_${index}_${question.QuestionId}`}
                                  name={`question_${question.QuestionId}`}
                                  type="checkbox"
                                  disabled={
                                    disabledPage
                                      ? true
                                      : isReadOnly
                                      ? isReadOnly
                                      : option.OptionId === noneOption.OptionId
                                      ? false
                                      : values[
                                          `question_${question.QuestionId}`
                                        ]?.includes(noneOption.OptionId)
                                  }
                                  className={
                                    "form-check-input" +
                                    (errors[
                                      `question_${question.QuestionId}`
                                    ] &&
                                    touched[`question_${question.QuestionId}`]
                                      ? " is-invalid"
                                      : "")
                                  }
                                  value={option.OptionId}
                                  onChange={(e) => {
                                    const { checked, value } = e.target;
                                    if (
                                      optionText
                                        ?.toLowerCase()
                                        .indexOf(assessmentConstants.none) !==
                                      -1
                                    ) {
                                      checkboxOnChangeNone(
                                        question,
                                        setFieldValue,
                                        values,
                                        checked,
                                        value
                                      );
                                    } else {
                                      if (checked) {
                                        setFieldValue(
                                          `question_${question.QuestionId}`,
                                          [
                                            ...values[
                                              `question_${question.QuestionId}`
                                            ],
                                            value,
                                          ]
                                        );
                                      } else {
                                        setFieldValue(
                                          `question_${question.QuestionId}`,
                                          values[
                                            `question_${question.QuestionId}`
                                          ].filter((v) => v !== value)
                                        );
                                      }
                                    }
                                    onChangeControl(
                                      e,
                                      question,
                                      isChild,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={`question_${index}_${question.QuestionId}`}
                                  className="form-check-label"
                                >
                                  {optionText}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage
                name={`question_chk_${question.QuestionId}`}
                component="div"
                className={InlineFormError}
              />
            </>
          )}
        </ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const HiddenControl = (props) => {
  try {
    const { question } = props.data;
    const { className } = props;
    return (
      <>
        <ControlBox data={props.data} colClass="">
          {question.IsVisibleOption !== VISIBLE_OPTION && (
            <>
              <Field
                name={`question_${question.QuestionId}`}
                type={`hidden`}
                className={"form-control"}
                value={question.QuestionText}
              />
            </>
          )}
        </ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const ScoreIdControl = (props) => {
  try {
    const { question } = props.data;
    const { className } = props;
    return (
      <>
        <ControlBox data={props.data} colClass=""></ControlBox>
      </>
    );
  } catch (e) {
    console.log(e);
  }
};
const ControlBox = (props) => {
  try {
    const { question, isChild } = props.data;
    const { children, colClass } = props;

    let hideQuestion = "";
    if (question.IsChild) {
      let questionText = PreferredLanguage(question);
      hideQuestion =
        (questionText === "" || questionText === null) &&
        question.ignoreSerialNo == "false"
          ? " hide-question"
          : "";
    }

    return (
      <div
        className={`${colClass === `` ? `col-sm-12` : colClass}${hideQuestion}`}
        id={`div_qu_${question.QuestionId}`}
        data-type={question.AspControlType}
        data-is-child={question.IsChild}
        data-validation-rule={question.ValidationRule}
        data-validation-condition={question.ValidationCondition}
        data-parent-id={question.ParentQuestionId}
        data-is-mandatory={question.IsMandatory}
        data-raw-details={question.additionalRawData}
        data-validation-expression={question.ValidationExpression}
        data-is-nested-condition={question.IsNestedCondition}
        data-validation-child-condition={question.ValidationChildCondition}
        data-is-readonly={question.IsReadOnly}
      >
        {children}
        <BindContentText data={props.data} />
      </div>
    );
  } catch (e) {
    console.log(e);
  }
};
const BindQuestionSerialNo = (props) => {
  const { question, isChild } = props.data;
  let questionText = PreferredLanguage(question);
  if (question.ignoreSerialNo == "false" && isChild == false) {
    return (
      <div className="row-title row-data">
        <div className="numbers">{question.OrderId + "."}</div>
        <div style={{ display: "inline", marginLeft: "11px" }}>
          {question.IsMandatory && <span className="mandatory">*</span>}
          {questionText}
        </div>
      </div>
    );
  } else {
    return <div className="row-title row-data">{questionText}</div>;
  }
};
const BindContentText = (props) => {
  const { question, assessmentContent, values } = props.data;
  return assessmentContent
    ?.filter((obj) => obj.dependantQuestionId === question.QuestionId)
    .map((content, ind) => {
      let contentText = PreferredLanguage(content, "content");
      if (
        content.ValidationCondition != null &&
        content.ValidationCondition != undefined
      ) {
        const enabledContent = CheckConditionForBindingContent(
          question,
          content,
          values
        );
        return (
          <div
            key={ind}
            id={`div_content_${content.dependantQuestionId}`}
            data-content-condition={content.ValidationCondition}
            data-content-validation-rule={content.ValidationRule}
            data-parent-id={content.ParentQuestionId}
            className={"" + (enabledContent ? "" : "d-none")}
            dangerouslySetInnerHTML={{ __html: contentText }}
          ></div>
        );
      } else {
        return (
          <div
            key={ind}
            id={`div_content_${content.dependantQuestionId}`}
            // className={"spacing-left"}
            dangerouslySetInnerHTML={{ __html: contentText }}
          ></div>
        );
      }
    });
};
function PreferredLanguage(obj, type = "question") {
  let { prefLang } = useContext(AppContext);
  let objText =
    type === "question"
      ? obj.QuestionText
      : type === "option"
      ? obj.OptionText
      : obj.contentText;
  if (
    prefLang?.toLowerCase() === SPANISH_LANG_TEXT.toLowerCase() ||
    prefLang?.toLowerCase() === SPANISH_LANG_CODE.toLowerCase()
  )
    objText =
      type === "question"
        ? obj.SpanishQuestionText
        : type === "option"
        ? obj.SpanishOptionText
        : obj.spanishContentText;
  return objText;
}
function checkboxOnChangeNone(question, setFieldValue, values, checked, value) {
  if (checked) {
    let vals = values[`question_${question.QuestionId}`].filter((v) => v === 0);
    vals.push(value);
    setFieldValue(`question_${question.QuestionId}`, vals);
    let AspControlType = question.AspControlType;
    if (question.IsChild == true) {
      document
        .querySelectorAll('div[data-parent-id="' + question.QuestionId + '"]')
        .forEach((qu, key) => {
          let childQuId = qu.id.replace("div_qu_", "");
          controlShow(childQuId, AspControlType, false, setFieldValue);
        });
    }
  } else {
    setFieldValue(
      `question_${question.QuestionId}`,
      values[`question_${question.QuestionId}`].filter((v) => v !== value)
    );
  }
}
function CheckConditionForBinding(parentQuestion, question, values) {
  let flag = false;
  try {
    let qId = question.ParentQuestionId;
    let answer = values[`question_${qId}`];
    let validationChildCondition = question.ValidationChildCondition;
    let childValidationRule = parentQuestion.ValidationRule;
    let validationCondition = parentQuestion.ValidationCondition;
    if (
      validationCondition != null &&
      validationCondition != undefined &&
      validationCondition != ""
    ) {
      if (validationCondition == "!=") {
        if (
          answer != undefined &&
          answer != null &&
          answer != 0 &&
          answer != childValidationRule &&
          !answer?.includes(childValidationRule)
        )
          flag = false;
        else flag = true;
      } else {
        if (
          answer == childValidationRule ||
          answer?.includes(childValidationRule)
        )
          flag = false;
        else flag = true;
      }
    }
    if (
      validationChildCondition != null &&
      validationChildCondition != undefined &&
      validationChildCondition != ""
    ) {
      if (validationChildCondition == "!=") {
        if (
          answer != undefined &&
          answer != null &&
          answer != 0 &&
          answer != childValidationRule
        )
          flag = false;
        else flag = true;
      } else if (
        validationChildCondition.indexOf("indexOf") >= 0 ||
        validationChildCondition.indexOf("length") >= 0
      ) {
        var statementId = "#CK_H" + qId + "#";
        validationChildCondition = validationChildCondition.replace(
          new RegExp(statementId, "g"),
          answer
        );
        if (answer != undefined && answer != null && answer != 0) {
          if (eval(validationChildCondition)) flag = false;
          else flag = true;
        } else flag = true;
      } else if (validationChildCondition != "") {
        if (answer == childValidationRule) flag = false;
        else flag = true;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return flag;
}
function CheckConditionForBindingContent(question, content, values) {
  let flag = false;
  try {
    let qId = question.QuestionId;
    let answer = values[`question_${qId}`];
    let childContentCondition = content.ValidationCondition;
    let childContentRule = content.ValidationRule;
    //Load Content Validation Condition
    if (childContentCondition == "!=") {
      if (
        answer != undefined &&
        answer != null &&
        answer != 0 &&
        answer != childContentRule
      )
        flag = true;
      else flag = false;
    } else if (
      childContentCondition != undefined &&
      childContentCondition != null
    ) {
      if (
        childContentCondition.indexOf("indexOf") >= 0 ||
        childContentCondition.indexOf("length") >= 0
      ) {
        var statementId = "#CK_H" + qId + "#";
        childContentCondition = childContentCondition.replace(
          new RegExp(statementId, "g"),
          answer
        );
        if (answer != undefined && answer != null && answer != 0) {
          if (eval(childContentCondition)) flag = true;
          else flag = false;
        } else flag = false;
      } else if (childContentCondition != "") {
        if (answer == childContentRule) flag = true;
        else flag = false;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return flag;
}
function onChangeControl(e, question, isChild, setFieldValue) {
  try {
    let qId = question.QuestionId;
    let answer = "";
    if (question.AspControlType == dynamicControlConstants.checkbox) {
      let chkList = document.querySelectorAll(
        'input[name="question_' + qId + '"][type="checkbox"]:checked'
      );
      chkList.forEach((val, i) => {
        answer += answer !== "" ? "," + val.value : val.value;
      });
    } else {
      if (question.AspControlType == dynamicControlConstants.select)
        answer = e.value;
      else answer = e.target.value;
    }
    let AspControlType = question.AspControlType;
    if (question.IsChild == true) {
      document
        .querySelectorAll('div[data-parent-id="' + question.QuestionId + '"]')
        .forEach((qu, key) => {
          let childQuId = qu.id.replace("div_qu_", "");
          let validationChildCondition = document
            .getElementById("div_qu_" + childQuId)
            .getAttribute("data-validation-child-condition");
          let childValidationRule = document
            .getElementById("div_qu_" + qId)
            .getAttribute("data-validation-rule");
          let validationCondition = document
            .getElementById("div_qu_" + qId)
            .getAttribute("data-validation-condition");
          if (
            validationCondition != null &&
            validationCondition != undefined &&
            validationCondition != ""
          ) {
            if (validationCondition == "!=") {
              if (
                answer != undefined &&
                answer != null &&
                answer != 0 &&
                answer != childValidationRule &&
                !answer?.includes(childValidationRule)
              )
                controlShow(childQuId, AspControlType, true, setFieldValue);
              else controlShow(childQuId, AspControlType, false, setFieldValue);
            } else {
              if (
                answer == childValidationRule ||
                answer?.includes(childValidationRule)
              )
                controlShow(childQuId, AspControlType, true, setFieldValue);
              else controlShow(childQuId, AspControlType, false, setFieldValue);
            }
          }
          if (
            validationChildCondition != null &&
            validationChildCondition != undefined &&
            validationChildCondition != ""
          ) {
            if (validationChildCondition == "!=") {
              if (
                answer != undefined &&
                answer != null &&
                answer != 0 &&
                answer != childValidationRule
              )
                controlShow(childQuId, AspControlType, true, setFieldValue);
              else controlShow(childQuId, AspControlType, false, setFieldValue);
            } else if (
              validationChildCondition.indexOf("indexOf") >= 0 ||
              validationChildCondition.indexOf("length") >= 0
            ) {
              var statementId = "#CK_H" + qId + "#";
              validationChildCondition = validationChildCondition.replace(
                new RegExp(statementId, "g"),
                answer
              );
              if (answer != undefined && answer != null && answer != 0) {
                if (eval(validationChildCondition))
                  controlShow(childQuId, AspControlType, true, setFieldValue);
                else
                  controlShow(childQuId, AspControlType, false, setFieldValue);
              } else
                controlShow(childQuId, AspControlType, false, setFieldValue);
            } else if (validationChildCondition != "") {
              if (answer == childValidationRule)
                controlShow(childQuId, AspControlType, true, setFieldValue);
              else controlShow(childQuId, AspControlType, false, setFieldValue);
            }
          }
        });
    }
    if (document.getElementById("div_content_" + qId) != undefined) {
      let childContentCondition = document
        .getElementById("div_content_" + qId)
        .getAttribute("data-content-condition");
      let childContentRule = document
        .getElementById("div_content_" + qId)
        .getAttribute("data-content-validation-rule");
      if (childContentCondition == "!=") {
        if (
          answer != undefined &&
          answer != null &&
          answer != 0 &&
          answer != childContentRule
        )
          controlShow(qId, AspControlType, true, setFieldValue, "content");
        else controlShow(qId, AspControlType, false, setFieldValue, "content");
      } else if (
        childContentCondition != undefined &&
        childContentCondition != null
      ) {
        if (
          childContentCondition.indexOf("indexOf") >= 0 ||
          childContentCondition.indexOf("length") >= 0
        ) {
          var statementId = "#CK_H" + qId + "#";
          childContentCondition = childContentCondition.replace(
            new RegExp(statementId, "g"),
            answer
          );
          if (answer != undefined && answer != null && answer != 0) {
            if (eval(childContentCondition))
              controlShow(qId, AspControlType, true, setFieldValue, "content");
            else
              controlShow(qId, AspControlType, false, setFieldValue, "content");
          } else
            controlShow(qId, AspControlType, false, setFieldValue, "content");
        } else if (childContentCondition != "") {
          if (answer == childContentRule)
            controlShow(qId, AspControlType, true, setFieldValue, "content");
          else
            controlShow(qId, AspControlType, false, setFieldValue, "content");
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}
function controlShow(qId, controlType, flag, setFieldValue, elementType = "") {
  try {
    let elementTypeId = "question_";
    if (elementType == "content") {
      elementTypeId = "div_content_";
      if (flag == true) {
        document
          .getElementById(elementTypeId + qId)
          ?.classList.remove("d-none");
      } else {
        document.getElementById(elementTypeId + qId)?.classList.add("d-none");
        ResetControls(qId, "", setFieldValue);
        HideAndResetChildControls(qId, setFieldValue);
      }
    } else if (flag == true) {
      document
        .querySelector(`[name="` + elementTypeId + qId + `"]`)
        ?.removeAttribute("disabled");
    } else {
      document
        .querySelector(`[name="` + elementTypeId + qId + `"]`)
        ?.setAttribute("disabled", "disabled");
      ResetControls(qId, "", setFieldValue);
      HideAndResetChildControls(qId, setFieldValue);
    }
  } catch (e) {
    console.log(e);
  }
}
function HideAndResetChildControls(qId, setFieldValue, elementType = "") {
  try {
    let elementTypeId = "div_qu_";
    if (elementType == "content") elementTypeId = "div_content_";
    document
      .querySelectorAll('div[data-parent-id="' + qId + '"]')
      .forEach((qu, key) => {
        let childQuId = qu.id.replace(elementTypeId, "");
        let controlType = qu.getAttribute("data-type");
        let isChild = qu.getAttribute("data-is-child");
        if (elementType == "content") {
          document
            .getElementById(elementTypeId + childQuId)
            ?.classList.add("d-none");
        } else {
          document
            .querySelector(`[name="` + elementTypeId + qId + `"]`)
            ?.setAttribute("disabled", "disabled");
          ResetControls(childQuId, controlType, setFieldValue);
          if (isChild === "true") {
            HideAndResetChildControls(childQuId, setFieldValue);
          }
        }
      });
  } catch (e) {
    console.log(e);
  }
}
function ResetControls(qId, controlType = "", setFieldValue) {
  try {
    if (controlType == "") {
      controlType = document
        .getElementById("div_qu_" + qId)
        .getAttribute("data-type");
    }
    switch (controlType) {
      case "checkbox":
        break;
      case "radio":
        document.getElementById("div_qu_" + qId).checked = false;
      default:
        setFieldValue("question_" + qId, "");
        break;
    }
  } catch (e) {
    console.log(e);
  }
}
