import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { useCTContactInforAccess } from "../../hooks/useCTContactInforAccess";
import { useGetCareTeamContactAccessManagementAPI } from "../../hooks/useGetCareTeamContactAccessManagementAPI";

import {
  printReportDate,
  //paginationPrint,
  //paginationWithoutApiCall,
} from "../Common-Health-Records/Helper";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_CONTACT_INFO } from "../../define.widgets";
import DisplayItemCH from "../MyHealthRecord-ClaimsHistory/DisplayItemCH.js";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { CustomSelect } from "../../common/Select";
import { useMemberConsentGetEntityNameListAPI } from "../../hooks/useMemberConsentGetEntityNameListAPI";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  IMAGE_PATH,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
import { ModalContext } from "./../../contexts/modal.context";
import { AppContext } from "./../../contexts/app.context";
import { ErrorContext } from "../../contexts/error.context";
import * as HealthRecordAPI from "../../services/HeathRecordAPI";
import { getFormattedDate } from "../../utils/helpers";

const CTContactAccessManagement = (props) => {
  const [pageEditable, setPageEditable] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const [highlightAuthCheckBox, setHighlightAuthCheckBox] = useState(false);

  const { memberConsent, refreshContactAccessManagementAPI, isApiResponded } =
    useGetCareTeamContactAccessManagementAPI();
  const { entityNameList, isApiResponded1 } =
    useMemberConsentGetEntityNameListAPI();
  const { setSpinner, innerSpinnerHTML } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { memberName, profileList } = useContext(AppContext);
  const { fields } = props;
  const consentCheckBoxRef = useRef(null);
  const gridRef = useRef(null);
  const declarationRef = useRef(null);

  let consentList = [];
  memberConsent.map((record, index) => {
    let entity = entityNameList.find((e) => e.id == record.EntityId);
    if (entity) {
      consentList.push({
        Id: record.Id,
        MemID: record.MemID,
        RKSMemberID: record.RKSMemberID,
        EntityId: record.EntityId,
        EntityName: entity.title,
        EntityType: record.EntityType,
        ConsentStatus: record.ConsentStatus,
        FullPersonalHealthRecord: record.FullPersonalHealthRecord,
        GeneralMedicalHealth: record.GeneralMedicalHealth,
        BehavioralandMentalHealth:
          record.BehavioralandMentalHealth == "Y" ? true : false,
        SubstanceUseandAbuse: record.SubstanceUseandAbuse == "Y" ? true : false,
        CommunicableDiseasesincludingSTDs:
          record.CommunicableDiseasesincludingSTDs == "Y" ? true : false,
        Self: record.Self,
        Parent: record.Parent,
        Guardian: record.Guardian,
        AuthorizedRepresentative: record.AuthorizedRepresentative,
        Other: record.Other,
        ParentName: record.ParentName,
        Relationship: record.Relationship,
        EffDate: record.EffDate,
        TermDate: record.TermDate,
        IsActive: record.IsActive,
        AllowEdit: record.AllowEdit,
        Activity: record.Activity,
        HIPAAFileName: record.HIPAAFileName,
        MemberName: record.MemberName,
        MemberAddress: record.MemberAddress,
        MemberDOB: record.MemberDOB,
        MemberCity: record.MemberCity,
        MemberState: record.MemberState,
        MemberZip: record.MemberZip,
        MemberPhone: record.MemberPhone,
        selLanguage: record.selLanguage,
      });
    }
  });

  let entitiesOptions = [];
  entityNameList?.map((item) => {
    //let entityAlreadyPresent = consentList.some((c) => c.EntityId == item.id);
    //if (!entityAlreadyPresent) {
    entitiesOptions.push({
      value: item.title === "Select" ? "" : item.title,
      label: item.title,
      id: item.id,
    });
    //}
  });

  const initialValues = {
    MemberConsentInf: consentList,
    Self: false,
    Parent: false,
    Guardian: false,
    AuthorizedRepresentative: false,
    Other: false,
    ParentName: "",
    Relationship: "",
  };

  const BindLabel = ({ children, htmlFor, cssClass, textDetails }) => {
    return (
      <>
        <label
          htmlFor={`PhonePreference.preferences.${htmlFor}`}
          className={`row-title ${cssClass} radio-btn`}
        >
          <Text field={textDetails ?? ""} />
          {children}
        </label>
      </>
    );
  };

  const handleEntityChange = (e, index, values, setValues, setStatus) => {
    // let consentList = [];
    setStatus({
      gridError: "",
      relationCheckBoxError: "",
      parentNameError: "",
      relationNameError: "",
    });
    let MemberConsentInf = values?.MemberConsentInf;
    // let entityName = consentList[index]?.EntityName;

    let entity = entityNameList.find((ent) => ent.title == e.value);
    let duplicateConsent = MemberConsentInf.find(
      (c) => c.EntityId == entity.id
    );
    if (duplicateConsent) {
      setStatus({
        gridError: fields?.MC_Message_NoDuplicate?.value,
        relationCheckBoxError: "",
        parentNameError: "",
        relationNameError: "",
      });
      //showGenericPopUp(fields?.MC_Message_NoDuplicate?.value);
      //setGridError(fields?.MC_Message_NoDuplicate?.value);
    } else {
      MemberConsentInf[index].EntityType = entity?.EntityType;
      MemberConsentInf[index].Activity =
        MemberConsentInf[index].Activity == "ADD" ? "ADD" : "UPDATE";
      MemberConsentInf[index].EntityId = entity?.id;
      //MemberConsentInf[index].EntityName = entity?.title;
      setValues({ ...values, MemberConsentInf });
    }
  };

  const onEditBtnClick = () => {
    setPageEditable(true);
  };

  const handleBehavioralandMentalHealthChange = (values, setValues, index) => {
    const MemberConsentInf = values?.MemberConsentInf;
    if (MemberConsentInf[index].Activity != "ADD") {
      MemberConsentInf[index].Activity = "UPDATE";
      setValues({ ...values, MemberConsentInf });
    }
  };

  const handleSubstanceUseandAbuseChange = (values, setValues, index) => {
    const MemberConsentInf = values?.MemberConsentInf;
    if (MemberConsentInf[index].Activity != "ADD") {
      MemberConsentInf[index].Activity = "UPDATE";
      setValues({ ...values, MemberConsentInf });
    }
  };

  const handleCommunicableDiseasesincludingSTDsChange = (
    values,
    setValues,
    index
  ) => {
    const MemberConsentInf = values?.MemberConsentInf;
    if (MemberConsentInf[index].Activity != "ADD") {
      MemberConsentInf[index].Activity = "UPDATE";
      setValues({ ...values, MemberConsentInf });
    }
  };

  const AddRow = (values, setValues) => {
    const MemberConsentInf = values?.MemberConsentInf;
    MemberConsentInf?.push({
      Id: 0,
      MemID: "",
      RKSMemberID: "",
      EntityId: 0,
      EntityName: "",
      EntityType: "",
      ConsentStatus: "",
      FullPersonalHealthRecord: "N",
      GeneralMedicalHealth: "N",
      BehavioralandMentalHealth: false,
      SubstanceUseandAbuse: false,
      CommunicableDiseasesincludingSTDs: false,
      Self: 0,
      Parent: 0,
      Guardian: 0,
      AuthorizedRepresentative: 0,
      Other: 0,
      ParentName: null,
      Relationship: null,
      EffDate: null,
      TermDate: null,
      IsActive: 1,
      AllowEdit: 0,
      Activity: "ADD",
      HIPAAFileName: null,
      MemberName: null,
      MemberAddress: null,
      MemberDOB: null,
      MemberCity: null,
      MemberState: null,
      MemberZip: null,
      MemberPhone: null,
      selLanguage: null,
    });
    setValues({ ...values, MemberConsentInf });
  };

  const [formValues, setFormValues] = useState({});
  const ShowHippaPopup = (e, value, setStatus) => {
    //e.preventDefault();
    //consentList = value?.MemberConsentInf?.map((obj) => ({ ...obj }));
    if (validationCheck(value, setStatus)) {
      setShowConsentForm(true);
      setFormValues(value);
      window.scrollTo(0, 100);
    }
  };

  const validationCheck = (value, setStatus) => {
    let consentList = value?.MemberConsentInf;

    let isValid = true;

    let status = {
      gridError: "",
      relationCheckBoxError: "",
      parentNameError: "",
      relationNameError: "",
    };

    setStatus(status);

    if (!pageEditable) {
      status.gridError = fields?.MC_Message_NoChange?.value;
      window.scrollBy(0, -500);
      //gridRef.current.scrollIntoView({ behavior: "smooth" });
      return false;
    }

    if (consentList?.length <= 0) {
      //showGenericPopUp(fields?.MC_Message_Add_New_Record?.value);
      status.gridError = fields?.MC_Message_Add_New_Record?.value;
      isValid = false;
    }

    if (consentList?.some((consent) => consent.EntityId == 0)) {
      //showGenericPopUp(fields?.MC_Message_EmptyEntity?.value);
      //setGridError(fields?.MC_Message_EmptyEntity?.value);
      status.gridError = fields?.MC_Message_EmptyEntity?.value;

      isValid = false;
    }

    let isDuplicateFound = entityNameList?.some((entity) => {
      consentList?.filter((consent) => consent.EntityId == entity.id)?.length >
        1;
    });
    if (isDuplicateFound) {
      //setGridError(fields?.MC_Message_NoDuplicate?.value);
      status.gridError = fields?.MC_Message_NoDuplicate?.value;
      isValid = false;
    }

    if (
      !value?.Self &&
      !value?.Parent &&
      !value?.Guardian &&
      !value?.AuthorizedRepresentative &&
      !value?.Other
    ) {
      //showGenericPopUp(fields?.ICTHippaRelationAlertMessage?.value);
      status.relationCheckBoxError =
        fields?.ICTHippaRelationAlertMessage?.value;
      isValid = false;
    }

    if (
      (value?.Parent ||
        value?.Guardian ||
        value?.AuthorizedRepresentative ||
        value?.Other) &&
      value?.ParentName?.trim() == ""
    ) {
      //showGenericPopUp(fields?.ICTHippaRelationNameAlertMessage?.value);
      status.parentNameError = fields?.ICTHippaRelationNameAlertMessage?.value;
      isValid = false;
    }

    if (value?.Other && value?.Relationship?.trim() == "") {
      //showGenericPopUp(fields?.ICTHippaRelationShipAlertMessage?.value);
      status.relationNameError =
        fields?.ICTHippaRelationShipAlertMessage?.value;
      isValid = false;
    }

    setStatus(status);
    if (!isValid) {
      if (status?.gridError) window.scrollBy(0, -500);
      else if (
        status?.parentNameError ||
        status?.relationCheckBoxError ||
        status?.relationNameError
      )
        declarationRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return isValid;
  };

  const successUpdate = () => {
    showGenericPopUp(fields?.MC_Message_SavedSuccess?.value);
    setShowConsentForm(false);
    window.scrollTo(0, 0);
    setPageEditable(false);
  };

  const PostApiData = (value) => {
    let updatedConsentList = [];

    formValues?.MemberConsentInf?.map((record, index) => {
      let entityName = "";
      if (record.Activity == "ADD") {
        let entity = entityNameList.find((e) => e.id == record.EntityId);
        entityName = entity?.title;
      } else {
        entityName = record.EntityName;
      }
      updatedConsentList.push({
        Id: record.Id,
        MemID: record.MemID,
        RKSMemberID: record.RKSMemberID,
        EntityId: record.EntityId,
        EntityName: entityName,
        EntityType: record.EntityType,
        ConsentStatus: record.ConsentStatus,
        FullPersonalHealthRecord: record.FullPersonalHealthRecord,
        GeneralMedicalHealth: record.GeneralMedicalHealth,
        BehavioralandMentalHealth: record.BehavioralandMentalHealth ? "Y" : "N",
        SubstanceUseandAbuse: record.SubstanceUseandAbuse ? "Y" : "N",
        CommunicableDiseasesincludingSTDs:
          record.CommunicableDiseasesincludingSTDs ? "Y" : "N",
        Self:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.Self
              ? 1
              : 0
            : record.Self,
        Parent:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.Parent
              ? 1
              : 0
            : record.Parent,
        Guardian:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.Guardian
              ? 1
              : 0
            : record.Guardian,
        AuthorizedRepresentative:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.AuthorizedRepresentative
              ? 1
              : 0
            : record.AuthorizedRepresentative,
        Other:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.Other
              ? 1
              : 0
            : record.Other,
        ParentName:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.ParentName
            : record.ParentName,
        Relationship:
          record.Activity == "UPDATE" || record.Activity == "ADD"
            ? formValues?.Relationship
            : record.Relationship,
        EffDate: record.EffDate,
        TermDate: record.TermDate,
        IsActive: record.IsActive,
        AllowEdit: record.AllowEdit,
        Activity: record.Activity,
        HIPAAFileName: record.HIPAAFileName,
        MemberName: record.MemberName,
        MemberAddress: record.MemberAddress,
        MemberDOB: record.MemberDOB,
        MemberCity: record.MemberCity,
        MemberState: record.MemberState,
        MemberZip: record.MemberZip,
        MemberPhone: record.MemberPhone,
        selLanguage: record.selLanguage,
      });
    });

    //updatedConsentList = value?.MemberConsentInf;
    let updatedMemberConsent = {
      MemberConsentInf: updatedConsentList,
    };

    if (updatedMemberConsent) {
      setSpinner(true);
      HealthRecordAPI.MemberConsentSaveGridData(updatedMemberConsent)
        .then((response) => {
          setSpinner(false);
          if (response) {
            refreshContactAccessManagementAPI();
            setSpinner(true);
            successUpdate();
          }
        })
        .catch((err) => {
          setSpinner(false);
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  };

  const HippaPopUpContent = (value) => {
    const currentDate = new Date();
    let authExpiryDate = new Date(currentDate);
    authExpiryDate.setFullYear(authExpiryDate.getFullYear() + 1);

    const authExpiryDateString = getFormattedDate(
      authExpiryDate.toLocaleString(),
      "mm/dd/yyyy hh:mm:ss t"
    );
    const signatureDateString = getFormattedDate(
      currentDate.toLocaleString(),
      "mm/dd/yyyy hh:mm:ss t"
    );
    return (
      <>
        <div className="mycoverage-modal list-group-item">
          <div className="update-header">
            <div>
              <picture>
                <img
                  src={`${IMAGE_PATH}/CT_Hippa_Logo.png`}
                  style={{ filter: "none" }}
                />
              </picture>
            </div>

            <div
              className="service-disc"
              style={{
                color: "#454545",
                font: "normal normal normal .8rem/1.04rem Arial",
                letterSpacing: ".24px",
                marginBottom: "8px",
                //marginTop: "2rem",
                wordBreak: "keep-all",
              }}
            >
              <div>
                <b>
                  <h5>
                    <Text field={fields?.MIHippaTitle_1 ?? ""} />
                  </h5>
                </b>
              </div>
              <br />
              <div>
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_MemberName ?? ""} />
                    </b>{" "}
                    {memberName ?? ""}
                  </div>
                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_MemberID ?? ""} />
                    </b>{" "}
                    {profileList?.MemberId ?? ""}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_MemberAddress ?? ""} />
                    </b>{" "}
                    {profileList?.MailingAddress ?? ""}
                  </div>

                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_DateofBirth ?? ""} />
                    </b>{" "}
                    {profileList?.DateOfBirth ?? ""}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_City ?? ""} />
                    </b>{" "}
                    {profileList?.City ?? ""}
                  </div>

                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_State ?? ""} />
                    </b>{" "}
                    {profileList?.State ?? ""}
                  </div>

                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_Zip ?? ""} />
                    </b>{" "}
                    {profileList?.Zip ?? ""}
                  </div>

                  <div
                    className="col"
                    style={{ border: "1px solid #999", padding: "5px" }}
                  >
                    <b>
                      <Text field={fields?.MC_Phone ?? ""} />
                    </b>{" "}
                    {profileList?.HomePhone ?? ""}
                  </div>
                </div>
              </div>
              <br />

              <div>
                <b>
                  <Text field={fields?.MC_Hippa_Auth ?? ""} />
                </b>
              </div>
              <br />

              <div>
                {/* <b>1.</b>Name and address of Molina Healthcare (Molina) entity
                authorized to use or disclose the PHI:
                <br />
                Molina Healthcare of Ohio, Inc.
                <br />
                3000 Corporate Exchange Drive <br />
                Columbus, Ohio 43231 */}
                <RichText field={fields?.MC_Hippa_Claus_1 ?? ""} />
              </div>
              <br />

              <div>
                <RichText field={fields?.MC_Hippa_Claus_2 ?? ""} />
              </div>
              <br />

              <div ref={consentCheckBoxRef}>
                <RichText field={fields?.MC_Hippa_Claus_3 ?? ""} />
              </div>
              <br />
              <div>
                <RichText field={fields?.MC_Hippa_Claus_3_1 ?? ""} />
              </div>
              <ul style={{ marginLeft: "4%", listStyleType: "disc" }}>
                <li>
                  <div>
                    <Text field={fields?.MC_Hippa_Claus_3_2 ?? ""} />
                  </div>
                </li>
                <li>
                  <div>
                    <Text field={fields?.MC_Hippa_Claus_3_3 ?? ""} />
                  </div>
                </li>
                <li>
                  <div>
                    <Text field={fields?.MC_Hippa_Claus_3_4 ?? ""} />
                  </div>
                </li>
                <li>
                  <div>
                    <Text field={fields?.MC_Hippa_Claus_3_5 ?? ""} />
                  </div>
                </li>
              </ul>

              <br />

              <div
                style={{
                  border: highlightAuthCheckBox ? "1px solid red" : "none",
                }}
              >
                <b>4.</b> &nbsp;{" "}
                <input
                  type="checkbox"
                  id="CheckboxConfirmMC"
                  name="chkMIHICheck"
                  checked={allowSubmit}
                  onChange={(e) => {
                    setAllowSubmit(e.target?.checked);
                    setHighlightAuthCheckBox(!e.target?.checked);
                  }}
                />
                <span style={{ marginLeft: "10px", marginRight: "3px" }}>
                  <b>
                    <Text field={fields?.MC_Hippa_Claus_4 ?? ""} />
                  </b>
                </span>
                {highlightAuthCheckBox && (
                  <div
                    style={{
                      color: "#ff0000",
                      font: "normal normal normal 0.8rem/1.12rem Arial",
                    }}
                  >
                    {fields?.MC_Message_SelectAccept?.value}
                  </div>
                )}
              </div>

              <br />
              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_4_1 ?? ""} />
              </div>
              <br />
              <div>
                <RichText field={fields?.MC_Hippa_Claus_5 ?? ""} />
              </div>
              <br />
              <div>
                <RichText field={fields?.MC_Hippa_Claus_6 ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_a ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_b ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_c ?? ""} />
              </div>
              <br />

              <ul style={{ marginLeft: "4%", listStyleType: "disc" }}>
                <li>
                  <div>
                    {" "}
                    <Text field={fields?.MC_Hippa_Claus_6_c_1 ?? ""} />
                  </div>
                </li>
                <li>
                  <div>
                    {" "}
                    <Text field={fields?.MC_Hippa_Claus_6_c_2 ?? ""} />
                  </div>
                </li>
                <li>
                  <div>
                    <Text field={fields?.MC_Hippa_Claus_6_c_3 ?? ""} />
                  </div>
                </li>
              </ul>

              <br />

              <div style={{ paddingLeft: "60px" }}>
                <Text field={fields?.MC_Hippa_Claus_6_c_4 ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_d ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_e ?? ""} />
              </div>
              <br />

              <div style={{ paddingLeft: "40px" }}>
                <RichText field={fields?.MC_Hippa_Claus_6_f ?? ""} />
              </div>
              <br />
              <br />
              <div>
                <div className="row">
                  <div className="col">
                    <RichText field={fields?.MC_Hippa_Claus_7 ?? ""} />
                  </div>
                  <div className="col">
                    <u>{authExpiryDateString}</u>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div style={{ paddingLeft: "20px" }}>
                      <i>
                        <Text field={fields?.MC_Hippa_Claus_7_1 ?? ""} />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div>
                <div>
                  <div className="row">
                    <div
                      className="col"
                      style={{ border: "1px solid #999", padding: "5px" }}
                    >
                      <Text field={fields?.MC_Hippa_Claus_7_Signature ?? ""} />
                    </div>
                    <div
                      className="col"
                      style={{ border: "1px solid #999", padding: "5px" }}
                    >
                      <Text field={fields?.MC_Hippa_Claus_7_Date ?? ""} />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col"
                      style={{ border: "1px solid #999", padding: "5px" }}
                    >
                      {formValues?.ParentName ?? ""}
                    </div>
                    <div
                      className="col"
                      style={{ border: "1px solid #999", padding: "5px" }}
                    >
                      {signatureDateString}
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div>
                <div className="row">
                  <div className="col">
                    <b>
                      <Text field={fields?.MC_Hippa_Claus_7_2 ?? ""} />
                    </b>
                  </div>
                  <div className="col">
                    <p id="txtMCParentName_MC">
                      {formValues?.ParentName ?? ""}
                    </p>
                    <hr style={{ width: "200px" }} />
                    <u></u>
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div>
                <div>
                  <div className="row">
                    <div className="col" style={{ padding: "5px" }}>
                      <Text field={fields?.MC_Hippa_Claus_8 ?? ""} />
                    </div>
                    <div className="col" style={{ padding: "5px" }}>
                      <input
                        type="checkbox"
                        id="ChkMCParent_H"
                        name="chkMIHIRelation"
                        //disabled="disabled"
                        checked={formValues?.Parent}
                      />
                      <span>
                        {" "}
                        <Text field={fields?.MC_Hippa_Claus_8_1 ?? ""} />
                      </span>
                    </div>
                    <div className="col" style={{ padding: "5px" }}>
                      <input
                        type="checkbox"
                        id="ChkMCGuardian_H"
                        name="chkMIHIRelation"
                        //disabled="disabled"
                        checked={formValues?.Guardian}
                      />
                      <span>
                        {" "}
                        <Text field={fields?.MC_Hippa_Claus_8_2 ?? ""} />
                      </span>
                    </div>
                    <div className="col" style={{ padding: "5px" }}>
                      <input
                        type="checkbox"
                        id="ChkMCAuthorRepre_H"
                        name="chkMIHIRelation"
                        //disabled="disabled"
                        checked={formValues?.AuthorizedRepresentative}
                      />
                      <span>
                        {" "}
                        <Text field={fields?.MC_Hippa_Claus_8_3 ?? ""} />
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col" style={{ padding: "5px" }}>
                      <input
                        type="checkbox"
                        id="ChkMCOther_H"
                        name="chkMIOther"
                        //disabled="disabled"
                        checked={formValues?.Other}
                      />
                      <span>
                        {" "}
                        <Text field={fields?.MC_Hippa_Claus_8_4 ?? ""} />
                      </span>
                    </div>
                    <div className="col" style={{ padding: "5px" }}>
                      <p id="txtMCOtherRelatioinship_MC">
                        {formValues?.Relationship ?? ""}
                      </p>
                      <hr style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <div>
                <b>
                  <Text field={fields?.MC_Hippa_Claus_7_4 ?? ""} />
                </b>
              </div>
              <br />
              <br />

              <div>
                <b>
                  <Text field={fields?.MC_Hippa_Claus_7_5 ?? ""} />
                </b>
              </div>
              <br />
              <br />

              <div>
                <b>
                  <Text field={fields?.MC_Hippa_Claus_7_6 ?? ""} />
                </b>
              </div>
              <br />
              <br />

              <div>
                <Text field={fields?.MC_Hippa_Claus_7_7 ?? ""} />
              </div>
              <br />
              <br />

              <div>
                <RichText field={fields?.MC_Hippa_Claus_7_8 ?? ""} />
              </div>
              <br />
              <br />

              <div
                style={{
                  border: "2px solid black",
                  padding: "10px",
                }}
              >
                <RichText field={fields?.MC_Hippa_Claus_7_9 ?? ""} />
              </div>
              <br />
              <br />
            </div>
            <div>
              <button
                className="btn btn-primary m-3 w-md-35 w-sm-100"
                onClick={() => {
                  handleAuthorizationSubmit(value);
                }}
                //disabled={}
              >
                <Text field={fields?.Submit ?? ""} />
              </button>

              <button
                className="btn btn-default m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100 "
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowConsentForm(false);
                }}
              >
                <Text field={fields?.Cancel ?? ""} />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleAuthorizationSubmit = (value) => {
    if (allowSubmit) {
      PostApiData(value);
    } else {
      setHighlightAuthCheckBox(true);
      consentCheckBoxRef.current.scrollIntoView({ behavior: "smooth" });
      //showGenericPopUp(fields?.MC_Message_SelectAccept?.value);
    }
  };

  const genericPopUpContent = (message, callback = closeModal) => {
    return (
      <>
        <div className="mycoverage-modal">
          <div className="update-header">
            <p>{message}</p>
            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-primary m-3 w-md-35 w-sm-100"
                onClick={() => {
                  callback();
                }}
              >
                {fields?.OKButton?.value ?? ""}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const showGenericPopUp = (message, callback = closeModal) => {
    openModal({
      content: genericPopUpContent(message, callback),
      version: 1,
    });
  };

  const validateName = (value) => {
    const lettersAndSpacesOnly = /^[A-Za-z\s]+$/;
    if (lettersAndSpacesOnly.test(value) || value == "") {
      return true;
    }
    return false;
  };

  const setParentNameError = (status, setStatus, message) => {
    if (status) {
      status.parentNameError = message;
      setStatus(status);
    }
  };

  const setRelationNameError = (status, setStatus, message) => {
    if (status) {
      status.relationNameError = message;
      setStatus(status);
    }
  };

  const setRelationCheckBoxError = (status, setStatus, message) => {
    if (status) {
      status.relationCheckBoxError = message;
      setStatus(status);
    }
  };

  let count = 0;
  return (
    <>
      <div className={showConsentForm ? "d-none" : ""}>
        <Formik initialValues={initialValues} enableReinitialize={true}>
          {({
            setFieldValue,
            values,
            setValues,
            resetForm,
            handleBlur,
            handleChange,
            handleReset,
            setFieldTouched,
            errors,
            setErrors,
            setStatus,
            status,
          }) => (
            <Form>
              <div
                className="list-group-item"
                style={{
                  font: "normal normal 1.2rem/1.52rem Campton-medium",
                  marginTop: "40px",
                }}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-8">
                    <div className="page-subheading">
                      <Text
                        field={
                          fields?.MC_Heading_MemberConsent_FullLength ?? ""
                        }
                      />
                      <WhoIsAuthorized
                        authorize={{
                          [SUPPORT_USER]: true,
                          [TERMED_MEMBER]: false,
                        }}
                        handleClick={() => {
                          onEditBtnClick();
                          setStatus({
                            gridError: "",
                            relationCheckBoxError: "",
                            parentNameError: "",
                            relationNameError: "",
                          });
                        }}
                      >
                        {({ handleClick, disabledClass }) => {
                          return (
                            <>
                              {pageEditable === false ? (
                                <a
                                  className={`edit ${disabledClass}`}
                                  style={{
                                    font: "normal normal .88rem/1.12rem Campton-medium",
                                    marginLeft: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClick}
                                >
                                  <Text field={fields?.EditButton ?? ""} />
                                </a>
                              ) : null}
                            </>
                          );
                        }}
                      </WhoIsAuthorized>
                    </div>
                  </div>
                </div>
                {values?.MemberConsentInf?.length == 0 && (
                  <div className="col-sm-12 no-tab-title">
                    <div
                      className="no-records"
                      style={{ marginBottom: "10px" }}
                    >
                      <Text field={fields?.NoRecords ?? ""} />
                    </div>
                  </div>
                )}
                <hr className="d-none d-sm-block d-xl-none" />
                <div>
                  <FieldArray name="preferences">
                    {(fieldArrayProp) => {
                      return (
                        <div>
                          {values?.MemberConsentInf?.length > 0 && (
                            <div className="row label-list">
                              <div className="col-xl-2 d-xl-block d-none">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_EntityName
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>
                              <div className="col-xl-2 d-xl-block d-none">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_EntityType
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>
                              <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_ConsentStatus
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>

                              <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_BehavioralandMentalHealth
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>
                              <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_SubstanceUseandAbuse
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>
                              <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                                <BindLabel
                                  textDetails={
                                    fields?.MC_ColumnHeader_CommunicableDiseases
                                  }
                                  cssClass={"list-desc-title"}
                                ></BindLabel>
                              </div>
                            </div>
                          )}
                          {values?.MemberConsentInf?.map((component, index) => (
                            <div
                              id={`div_memberConsent_` + index}
                              key={index}
                              //className={"d-none"}
                            >
                              <div className="row field1">
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                  <BindLabel
                                    htmlFor={`${index}.phoneType`}
                                    cssClass={`d-lg-block d-xl-none`}
                                    textDetails={
                                      fields?.MC_ColumnHeader_EntityName
                                    }
                                  ></BindLabel>
                                  <div className="list-desc-details">
                                    {pageEditable ? (
                                      <Field
                                        as="select"
                                        name={`MemberConsentInf.${index}.EntityName`}
                                        id={`MemberConsentInf.${index}.EntityName`}
                                        //value={component.EntityName}
                                        // placeholder={getFieldValue(
                                        //   fields,
                                        //   "placeholderPhoneType",
                                        //   false
                                        // )}
                                        options={entitiesOptions}
                                        //isDisabled={pageEditable}
                                        component={CustomSelect}
                                        onChange={(e) => {
                                          handleEntityChange(
                                            e,
                                            index,
                                            values,
                                            setValues,
                                            setStatus
                                          );
                                          setFieldTouched(
                                            `MemberConsentInf.${index}.EntityName`,
                                            true
                                          );
                                        }}
                                        // ariaLabel={getFieldValue(
                                        //   fields,
                                        //   "phoneTypeHeading",
                                        //   false
                                        // )}
                                        customclass={""}
                                        //isFormik={true}
                                        isDisabled={!pageEditable}
                                      />
                                    ) : (
                                      <>{component.EntityName}</>
                                    )}
                                  </div>
                                </div>

                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                                  <div
                                    className="form-group"
                                    role="group"
                                    aria-labelledby={`PhonePreference.preferences.${index}.VoiceConcent`}
                                  >
                                    <BindLabel
                                      htmlFor={`${index}.VoiceConcent`}
                                      cssClass={`d-lg-block d-xl-none`}
                                      textDetails={
                                        fields?.MC_ColumnHeader_EntityType
                                      }
                                    ></BindLabel>
                                    <div className="list-desc-details">
                                      {component.EntityType}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                                  <div
                                    className="form-group"
                                    role="group"
                                    aria-labelledby={`PhonePreference.preferences.${index}.TextConsent`}
                                  >
                                    <BindLabel
                                      htmlFor={`${index}.TextConsent`}
                                      cssClass={`d-lg-block d-xl-none`}
                                      textDetails={
                                        fields?.MC_ColumnHeader_ConsentStatus
                                      }
                                    ></BindLabel>
                                    <div className={"list-desc-details"}>
                                      {component.ConsentStatus}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                                  <div
                                    className="form-group"
                                    role="group"
                                    aria-labelledby={`PhonePreference.preferences.${index}.TextConsent`}
                                  >
                                    <BindLabel
                                      htmlFor={`${index}.TextConsent`}
                                      cssClass={`d-lg-block d-xl-none`}
                                      textDetails={
                                        fields?.MC_ColumnHeader_BehavioralandMentalHealth
                                      }
                                    ></BindLabel>
                                    <div className={"row-details "}>
                                      <div className="form-check">
                                        <Field
                                          id={`MemberConsentInf.${index}.BehavioralandMentalHealth`}
                                          name={`MemberConsentInf.${index}.BehavioralandMentalHealth`}
                                          type="checkbox"
                                          disabled={!pageEditable}
                                          // disabled={
                                          //   component.phoneType === "work"
                                          //     ? true
                                          //     : pageEditable
                                          // }
                                          className="form-check-input"
                                          onChange={(e) => {
                                            handleBehavioralandMentalHealthChange(
                                              values,
                                              setValues,
                                              index
                                            );
                                            setFieldValue(
                                              `MemberConsentInf.${index}.BehavioralandMentalHealth`,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                                  <div
                                    className="form-group"
                                    role="group"
                                    aria-labelledby={`PhonePreference.preferences.${index}.TextConsent`}
                                  >
                                    <BindLabel
                                      htmlFor={`${index}.TextConsent`}
                                      cssClass={`d-lg-block d-xl-none`}
                                      textDetails={
                                        fields?.MC_ColumnHeader_SubstanceUseandAbuse
                                      }
                                    ></BindLabel>
                                    <div className={"row-details "}>
                                      <div className="form-check">
                                        <Field
                                          id={`MemberConsentInf.${index}.SubstanceUseandAbuse`}
                                          name={`MemberConsentInf.${index}.SubstanceUseandAbuse`}
                                          type="checkbox"
                                          disabled={!pageEditable}
                                          // disabled={
                                          //   component.phoneType === "work"
                                          //     ? true
                                          //     : pageEditable
                                          // }
                                          className="form-check-input"
                                          onChange={(e) => {
                                            handleSubstanceUseandAbuseChange(
                                              values,
                                              setValues,
                                              index
                                            );
                                            setFieldValue(
                                              `MemberConsentInf.${index}.SubstanceUseandAbuse`,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                                  <div
                                    className="form-group"
                                    role="group"
                                    aria-labelledby={`PhonePreference.preferences.${index}.TextConsent`}
                                  >
                                    <BindLabel
                                      htmlFor={`${index}.TextConsent`}
                                      cssClass={`d-lg-block d-xl-none`}
                                      textDetails={
                                        fields?.MC_ColumnHeader_CommunicableDiseases
                                      }
                                    ></BindLabel>
                                    <div className={"row-details "}>
                                      <div className="form-check">
                                        <Field
                                          id={`MemberConsentInf.${index}.CommunicableDiseasesincludingSTDs`}
                                          name={`MemberConsentInf.${index}.CommunicableDiseasesincludingSTDs`}
                                          type="checkbox"
                                          disabled={!pageEditable}
                                          // disabled={
                                          //   component.phoneType === "work"
                                          //     ? true
                                          //     : pageEditable
                                          // }
                                          className="form-check-input"
                                          onChange={(e) => {
                                            handleCommunicableDiseasesincludingSTDsChange(
                                              values,
                                              setValues,
                                              index
                                            );
                                            setFieldValue(
                                              `MemberConsentInf.${index}.CommunicableDiseasesincludingSTDs`,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {values?.MemberConsentInf?.length - 1 > index && (
                                <hr className="d-none d-sm-block" />
                              )}
                            </div>
                          ))}

                          <div ref={declarationRef}>
                            <button
                              type="button"
                              className={"btn btn-primary m-3 w-md-35 w-sm-100"}
                              style={{ cursor: "pointer" }}
                              disabled={!pageEditable}
                              onClick={() => AddRow(values, setValues)}
                            >
                              <Text field={fields?.AddButton ?? ""} />
                            </button>

                            <button
                              type="button"
                              className={
                                "btn btn-default m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
                              }
                              style={{ cursor: "pointer" }}
                              disabled={!pageEditable}
                              onClick={() => {
                                resetForm();
                                handleReset();
                                setPageEditable(false);
                              }}
                            >
                              <Text field={fields?.CancelButton ?? ""} />
                            </button>
                          </div>
                          <div
                            style={{
                              color: "#ff0000",
                              font: "normal normal normal 0.8rem/1.12rem Arial",
                            }}
                          >
                            {status?.gridError}
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>
              </div>
              <div className="service-disc">
                <div>
                  <Text field={fields?.ConsentDeclaration ?? ""} />
                </div>
                <div
                  style={{
                    border:
                      status?.relationCheckBoxError &&
                      !values?.Parent &&
                      !values?.Guardian &&
                      !values?.AuthorizedRepresentative &&
                      !values?.Other
                        ? "1px solid red"
                        : "none",
                  }}
                >
                  <div style={{ padding: "5px" }}>
                    <Field
                      id={"Self"}
                      name={"Self"}
                      type="checkbox"
                      disabled={!pageEditable}
                      className="form-check-input"
                      onChange={(e) => {
                        setFieldValue("Self", e.target.checked);
                        if (e.target.checked) {
                          setRelationCheckBoxError(status, setStatus, "");
                        }
                      }}
                    />{" "}
                    <label>
                      <Text field={fields?.lblSelf ?? ""} />
                    </label>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <Field
                      id={"Parent"}
                      name={"Parent"}
                      type="checkbox"
                      disabled={!pageEditable}
                      className="form-check-input"
                      onChange={(e) => {
                        setFieldValue("Parent", e.target.checked);
                        if (e.target.checked) {
                          setRelationCheckBoxError(status, setStatus, "");
                        }
                      }}
                    />{" "}
                    <label>
                      <Text field={fields?.lblParent ?? ""} />
                    </label>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <Field
                      id={"Guardian"}
                      name={"Guardian"}
                      type="checkbox"
                      disabled={!pageEditable}
                      className="form-check-input"
                      onChange={(e) => {
                        setFieldValue("Guardian", e.target.checked);
                        if (e.target.checked) {
                          setRelationCheckBoxError(status, setStatus, "");
                        }
                      }}
                    />{" "}
                    <label>
                      <Text field={fields?.lblGuardian ?? ""} />
                    </label>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <Field
                      id={"AuthorizedRepresentative"}
                      name={"AuthorizedRepresentative"}
                      type="checkbox"
                      disabled={!pageEditable}
                      className="form-check-input"
                      onChange={(e) => {
                        setFieldValue(
                          "AuthorizedRepresentative",
                          e.target.checked
                        );
                        if (e.target.checked) {
                          setRelationCheckBoxError(status, setStatus, "");
                        }
                      }}
                    />{" "}
                    <label>
                      <Text field={fields?.lblAuthorizedRepresentative ?? ""} />
                    </label>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <Field
                      id={"Other"}
                      name={"Other"}
                      type="checkbox"
                      disabled={!pageEditable}
                      className="form-check-input"
                      onChange={(e) => {
                        setFieldValue("Other", e.target.checked);
                        if (e.target.checked) {
                          setRelationCheckBoxError(status, setStatus, "");
                        }
                      }}
                    />{" "}
                    <label>
                      <Text field={fields?.lblOther ?? ""} />
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    color: "#ff0000",
                    font: "normal normal normal 0.8rem/1.12rem Arial",
                  }}
                >
                  {!values?.Parent &&
                  !values?.Guardian &&
                  !values?.AuthorizedRepresentative &&
                  !values?.Other
                    ? status?.relationCheckBoxError
                    : ""}
                </div>

                <br />
                <div>
                  <Text field={fields?.MIContactInfo ?? ""} />
                  <Field
                    name={"ParentName"}
                    id={"ParentName"}
                    type="text"
                    disabled={
                      !pageEditable ||
                      (!values?.Parent &&
                        !values?.Guardian &&
                        !values?.AuthorizedRepresentative &&
                        !values?.Other)
                    }
                    onFocus={(event) => {
                      if (validateName(event?.target?.value)) {
                        setFieldValue("ParentName", event?.target?.value);
                      }
                    }}
                    onChange={(event) => {
                      if (validateName(event?.target?.value)) {
                        handleChange(event);
                        setParentNameError(status, setStatus, "");
                        setFieldValue("ParentName", event?.target?.value);
                      }
                    }}
                    maxLength={100}
                    onBlur={(event) => {
                      if (
                        validateName(event?.target?.value) &&
                        event?.target?.value
                      ) {
                        handleBlur(event);
                        setFieldValue("ParentName", event?.target?.value);
                      } else {
                        setParentNameError(
                          status,
                          setStatus,
                          fields?.ICTHippaRelationNameAlertMessage?.value
                        );
                      }
                    }}
                    className={"form-control"}
                    style={{
                      border:
                        status?.parentNameError &&
                        (values?.Parent ||
                          values?.Guardian ||
                          values?.AuthorizedRepresentative ||
                          values?.Other)
                          ? "1px solid red"
                          : "1px solid #999",
                    }}
                  />
                  <div
                    style={{
                      color: "#ff0000",
                      font: "normal normal normal 0.8rem/1.12rem Arial",
                    }}
                  >
                    {values?.Parent ||
                    values?.Guardian ||
                    values?.AuthorizedRepresentative ||
                    values?.Other
                      ? status?.parentNameError
                      : ""}
                  </div>
                </div>
                <br />
                <div>
                  <Text field={fields?.MC_MemberConsentInfo3_1 ?? ""} />
                  <Field
                    name={"Relationship"}
                    id={"Relationship"}
                    type="text"
                    disabled={!pageEditable || !values?.Other}
                    onFocus={(event) => {
                      if (validateName(event?.target?.value)) {
                        setFieldValue("Relationship", event?.target?.value);
                      }
                    }}
                    onChange={(event) => {
                      if (validateName(event?.target?.value)) {
                        handleChange(event);
                        setRelationNameError(status, setStatus, "");
                        setFieldValue("Relationship", event?.target?.value);
                      }
                    }}
                    maxLength={100}
                    onBlur={(event) => {
                      if (
                        validateName(event?.target?.value) &&
                        event?.target?.value
                      ) {
                        handleBlur(event);
                        setFieldValue("Relationship", event?.target?.value);
                      } else {
                        setRelationNameError(
                          status,
                          setStatus,
                          fields?.ICTHippaRelationShipAlertMessage?.value
                        );
                      }
                    }}
                    className={"form-control"}
                    style={{
                      border:
                        status?.relationNameError && values?.Other
                          ? "1px solid red"
                          : "1px solid #999",
                    }}
                  />
                  <div
                    style={{
                      color: "#ff0000",
                      font: "normal normal normal 0.8rem/1.12rem Arial",
                    }}
                  >
                    {values?.Other ? status?.relationNameError : ""}
                  </div>
                </div>
                <br />
                <div>
                  <Text field={fields?.MC_MemberConsentInfo4 ?? ""} />{" "}
                  <b>
                    <u>
                      <Text field={fields?.MC_ICTContactInfoHIPAALink ?? ""} />
                    </u>
                  </b>{" "}
                  <Text field={fields?.MC_MemberConsentInfo5 ?? ""} />
                </div>
                <br />
                <div>
                  <Text field={fields?.PLEASE ?? ""} />{" "}
                  <WhoIsAuthorized
                    authorize={{
                      [SUPPORT_USER]: false,
                      [TERMED_MEMBER]: false,
                    }}
                    handleClick={(e) => {
                      ShowHippaPopup(e, values, setStatus);
                    }}
                  >
                    {({ handleClick, disabledClass }) => {
                      return (
                        <>
                          <a
                            id="HippaFormLinkClickhere"
                            className={`hr-contact ${disabledClass}`}
                            onClick={handleClick}
                            style={{ color: "#339999", cursor: "pointer" }}
                          >
                            <Text field={fields?.ClickHere ?? ""} />
                          </a>
                        </>
                      );
                    }}
                  </WhoIsAuthorized>{" "}
                  <Text field={fields?.ICTContactInfo6 ?? ""} />
                </div>
                <br />
                <div>
                  <Text field={fields?.ICTContactInfoDisclaimer ?? ""} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div
        className={showConsentForm ? "" : "d-none"}
        style={{
          marginTop: "40px",
        }}
      >
        <HippaPopUpContent value={formValues}></HippaPopUpContent>
      </div>
    </>
  );
};

export default CTContactAccessManagement;
