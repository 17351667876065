import React, { useState, useContext, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DependentList from "../common/DependentList";
import { ModalContext } from "../../../contexts/modal.context";
import { useMemAPIIdCardDetailsAPI } from "../../../hooks/useMemAPIIdCardDetailsAPI";

const Dependents = (props) => {
  const { fields, onPrint, yearSel, memberRKSID } = props;
  const { openModal, closeModal } = useContext(ModalContext);
  const [disbaleButton, setDisbaleButton] = useState(true);
  const [selectedDependents, setselectedDependents] = useState(null);
  const [selectedDependent, setSelectedDependent] = useState(null);
  const { details } = useMemAPIIdCardDetailsAPI(selectedDependent, yearSel);
  const handleClick = () => {
    onPrint(selectedDependents.map((sd) => sd.RksMemberId).join(";"));
  };
  useEffect(() => {
    setSelectedDependent(
      selectedDependents?.length > 0
        ? selectedDependents[0]
        : {
            RksMemberId: null,
            StateCode: null,
          }
    );
  }, [selectedDependents]);
  return (
    <>
      <div className="request-new-id-body">
        <p className="request-new-id-body-title">
          <Text field={fields.PopupSelectMemberName} />
        </p>
        <DependentList
          {...props}
          onDependentSelected={(data) => {
            setselectedDependents(data);
            setDisbaleButton(
              !data?.length ||
                (Number(yearSel) > 2021 &&
                  selectedDependents?.length === 1 &&
                  details?.PCPName_Current === null &&
                  details?.PCPName_New === null)
            );
          }}
        />
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={fields.btnCancel} />
          </button>
          <button
            disabled={disbaleButton}
            type="button"
            className="btn btn-primary"
            onClick={handleClick}
          >
            <Text field={fields.btnNext} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Dependents;
