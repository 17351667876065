import React, { useContext, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";
import { MultiStepContext } from "../../contexts/Steps.context";
import { InlineFormError } from "../../common/InlineFormError";
import {
  ICON_PATH,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  VALIDATE_MEMBERSHIP_ATTEMPT_FIRST,
  VALIDATE_MEMBERSHIP_ATTEMPT_FORTH,
  VALIDATE_MEMBERSHIP_ATTEMPT_SECOND,
  VALIDATE_MEMBERSHIP_ATTEMPT_THIRD,
  VALIDATE_MEMBERSHIP_ATTEMPT_NEW,
  VALIDATION_MEMBERSHIP_ATTEMPT,
  VALIDATE_MEMBERSHIP_ATTEMPT_ONE,
  REGISTRATION_LOGIN_RESTRICTION,
} from "../../define.constants";
import {
  getCaptcha,
  highlightBorderOnError,
  handleLogin,
} from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { Calendar } from "../../common/Calendar";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "./../../contexts/modal.context";

const MemberInformation = (props) => {
  const { fields } = props;
  const { selectedDate } = props;
  const { setSelectedDate } = props;
  const [statusCode, setStatusCode] = useState("");
  const [enrollStateOptions, setEnrollStateOptions] = useState([]);
  const [enrollStateList, setEnrollStateList] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [hover, setHover] = useState(false);
  const [memberIdError, setMemberIdError] = useState(false);
  const [recordRetrievalFail, setRecordRetrievalFail] = useState(0);
  const onHover = () => {
    setHover(true);
  };
  const onLeave = () => {
    setHover(false);
  };
  const stateOptionsArray = [];
  const required = <span style={{ color: "red" }}>*</span>;

  const {
    setStep,
    memberId,
    setMemberId,
    enrollState,
    setEnrollState,
    dob,
    setDob,
    setBrowserSessionId,
  } = useContext(MultiStepContext);
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  useEffect(() => {
    getCaptcha();
    getEnrollStateList();
  }, []);
  const [validateMembership, setValidationMsg] = useState("");
  const memberIdCardText = () => {
    return (
      <>
        {" "}
        <div>
          <Text field={fields.memberIdText} />
        </div>
      </>
    );
  };
  const { openModal, closeModal } = useContext(ModalContext);

  const getEnrollStateList = () => {
    setSpinner(true);
    RegistrationAPI.getStateListForReg({
      StateListFor: "Member",
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE
          ) {
            setEnrollStateList([]);
            setEnrollStateList(data.ResultData);
            data.ResultData?.map((item) => {
              stateOptionsArray.push({
                value: item.StateName,
                label: item.StateName,
                id: item.StateCode,
              });
            });
          }
          setEnrollStateOptions([...stateOptionsArray]);
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
        //Send CDP event for registration page view
        try {
          const urlSearchParams = new URLSearchParams(window.location.search);
          if (urlSearchParams.has("c")) {
            RegistrationAPI.sendRegistrationCDPEvent({
              ENContactID: urlSearchParams.get("c"),
              BrowserID: window?.Engage?.getBrowserId(),
              UtmSource: urlSearchParams.get("utm_source"),
              UtmCampaign: urlSearchParams.get("utm_campaign"),
              UtmMedium: urlSearchParams.get("utm_medium"),
              AlertType: "UTM",
              CDPChannel: "WEB",
              EventType: "View",
            });
            sessionStorage.setItem("CDPRegistrationEvent", "true");
          }
        } catch (error) {
          console.log("CDP Registration Event Error: ", error.message);
        }
      });
  };

  const memberIdValidation = (e, values, setFieldValue) => {
    setTimeout(() => {
      const memberId = e.target.value;
      setMemberIdError(false);
      let nonNum, Num, zeroNum;

      let regExp_alphanum = new RegExp("^[a-zA-Z0-9]*$"); //RegEx for AlphaNumeric

      if (
        memberId != null &&
        memberId != "" &&
        regExp_alphanum.test(memberId)
      ) {
        // Checking For AlphaNumeric Or Numeric(No special characters are allowed)
        nonNum = memberId.replace(/\d+/g, "").length; // Number of nonNumeric characters
        Num = memberId.replace(/\D+/g, "").length; // Number of digits
        zeroNum = memberId.replace(/\D+/g, "").replace(/[1-9]+/g, "").length; // Number of zeroes
        if (
          memberId.length < 4 ||
          (nonNum > 4 && isNaN(memberId)) ||
          Num < 3 ||
          Num == zeroNum
        ) {
          setMemberIdError(true);
          setValidationMsg(getFieldValue(fields, "invalidMemberId"));
          return true;
        } else {
          setValidationMsg("");
          setMemberIdError(false);
        }
      } else {
        if (e.type === "paste") {
          setFieldValue("memberId", "");
        }
        setMemberIdError(false);
        setValidationMsg("");
        return false;
      }
    }, 10);
  };

  const hasErrors = (errors) => {
    return errors.dob || errors.memberId || errors.enrollState;
  };

  return (
    <>
      <Formik
        initialValues={{ enrollState, memberId, dob }}
        validationSchema={Yup.object({
          enrollState: Yup.string().required(" "),
          memberId: Yup.string()
            .required(" ")
            .min(3, getFieldValue(fields, "invalidMemberId")),
          dob: Yup.date().required(" "),
        })}
        onSubmit={(values) => {
          let selectedState = enrollStateList.find(
            (item) => item.StateCode === values.enrollState
          );
          if (!sessionStorage.getItem("sessionId")) {
            setBrowserSessionId();
          }

          setIsSubmited(true);
          setMemberId(values.memberId);
          setEnrollState(selectedState?.StateCode);
          setDob(values.dob);
          setSpinner(true);
          RegistrationAPI.getValidateMembership({
            MemberNumber: values.memberId,
            Dob: values.dob,
            StateCode: values.enrollState,
            SessionId: sessionStorage.getItem("sessionId"),
          })
            .then((data) => {
              setStatusCode(data?.ResultCode);
              if (data) {
                if (
                  data.Message === SUCCESS_RESPONSE &&
                  data.ResultCode === SUCCESS_RESULT_CODE
                ) {
                  setStep(2);
                } else if (
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST ||
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_SECOND ||
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_ONE
                ) {
                  if (recordRetrievalFail > 0) {
                    setValidationMsg(
                      getFieldValue(fields, "ValidatesecAttempt")
                    );
                  } else {
                    setValidationMsg(getFieldValue(fields, "ValidationError1"));
                  }
                  setRecordRetrievalFail((count) => count + 1);
                } else if (
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_NEW ||
                  data.ResultCode === VALIDATION_MEMBERSHIP_ATTEMPT
                ) {
                  setValidationMsg(getFieldValue(fields, "ValidationError2"));
                } else if (
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_THIRD ||
                  data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FORTH
                ) {
                  setValidationMsg(getFieldValue(fields, "ValidationError3"));
                } else if ((data.ResultCode = REGISTRATION_LOGIN_RESTRICTION)) {
                  setValidationMsg(
                    getFieldValue(fields, "RegistrationLoginRestriction0")
                  );
                }
              } else {
                setValidationMsg(
                  getFieldValue(fields, "FormLevelErrorMessage")
                );
              }
            })
            .catch(function (err) {
              setError({ err });
            })
            .finally(() => {
              setSpinner(false);
            });
        }}
      >
        {({ touched, errors, values, setFieldValue }) => (
          <Form>
            <div className="Registration">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <section className="d-flex justify-content-center">
                    <div className="card lg-card">
                      <div className="card-body">
                        <StepProgressBar fields={fields} />
                        {/* -------------- */}
                        <div
                          className="directionsText"
                          style={{ marginBottom: "2.5rem", lineHeight: "20px" }}
                        >
                          {validateMembership && !memberIdError ? (
                            <div
                              className="validation-msg"
                              style={{
                                lineHeight: "20px",
                                height: "auto",
                              }}
                            >
                              {validateMembership}&nbsp;
                              {statusCode ===
                                VALIDATE_MEMBERSHIP_ATTEMPT_NEW && (
                                <span>
                                  <a href="#" onClick={handleLogin}>
                                    {getFieldValue(fields, "lnkHelp").text}
                                  </a>
                                </span>
                              )}
                            </div>
                          ) : (
                            <Text field={fields.directions} />
                          )}
                        </div>
                        <div
                          className="row sub-gap"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          {/* {isSubmited && hasErrors(errors) ? ( */}
                          <div className="col-md-12 ">
                            <div className="text-center font-fill-indetails">
                              <b>
                                <Text field={fields.pleaseFillInTheFields} />
                              </b>
                            </div>
                          </div>

                          <div
                            className="col-md-12 sub-text text-center"
                            style={{ fontSize: ".7rem", color: "#ef2e2e" }}
                          >
                            <span className="text-center">
                              {isSubmited && hasErrors(errors) && (
                                <Text field={fields.allFieldsReq} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="member-info"
                          style={{ paddingTop: "0rem" }}
                        >
                          <div className="row-title">
                            <Text field={fields.enrollmentState} />
                            {required}
                            <div className="font-weight-500 custom-tooltip-spending pl-3">
                              <p className="custom-tooltip-spending-text tooltipBox">
                                <Text field={fields.stateText} />
                              </p>
                              <img
                                style={{ marginBottom: "2px" }}
                                src={`${ICON_PATH}/info-24px.svg`}
                                alt={getFieldValue(fields, "infoIcon")}
                              />
                            </div>
                          </div>
                          <Field
                            name="enrollState"
                            as="select"
                            value={
                              enrollStateOptions
                                ? enrollStateOptions.find(
                                    (option) =>
                                      option.value === values.enrollState
                                  )
                                : ""
                            }
                            className={`form-gap ${highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}`}
                            error={highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}
                            aria-label={getFieldValue(
                              fields,
                              "enrollmentState"
                            )}
                            component={CustomSelect}
                            options={enrollStateOptions}
                            errors={highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}
                            onChange={(option) => {
                              setFieldValue("enrollState", option.value);
                            }}
                            isFormik={true}
                          />
                          <div className="row-title row-inline align-member-id-required">
                            <Text field={fields.memberId} />
                            {required}
                            <div className="row-sub-title">
                              <Text field={fields.howDoIFindMyMemberId} />
                              <div className="font-weight-500 custom-tooltip-spending pl-3">
                                <p className="custom-tooltip-spending-text tooltipBox">
                                  <Text field={fields.memberIdText} />
                                </p>
                                <img
                                  style={{ marginBottom: "2px" }}
                                  src={`${ICON_PATH}/info-24px.svg`}
                                  alt={getFieldValue(fields, "infoIcon")}
                                />
                              </div>
                            </div>
                          </div>
                          <Field
                            name="memberId"
                            type="text"
                            className={`member-id-content form-control ${highlightBorderOnError(
                              errors.memberId,
                              touched.memberId
                            )}`}
                            onBlur={(e) =>
                              memberIdValidation(e, e.target.value)
                            }
                            onChange={(e) => {
                              let memId = e?.target?.value.replace(/\s+/g, "");
                              setFieldValue("memberId", memId);
                            }}
                            onPaste={(e) => {
                              memberIdValidation(e, values, setFieldValue);
                            }}
                            aria-label={getFieldValue(fields, "memberId")}
                          />
                          {memberIdError && (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {validateMembership}
                            </div>
                          )}

                          <div className="row-title">
                            <Text field={fields.dateOfBirth} />
                            {required}
                          </div>

                          <Field
                            component={Calendar}
                            name="dob"
                            handleChange={(selectedDate) => {
                              setSelectedDate(selectedDate);
                              selectedDate !== null
                                ? setFieldValue(
                                    "dob",
                                    selectedDate.toLocaleDateString()
                                  )
                                : setFieldValue("dob", null);
                            }}
                            value={selectedDate}
                            placeholder={getFieldValue(
                              fields,
                              "placeholderDateFormat",
                              false
                            )}
                            className={`${highlightBorderOnError(
                              errors.dob,
                              touched.dob
                            )}`}
                            aria-label={getFieldValue(fields, "dateOfBirth")}
                            invalidDateMessage={getFieldValue(
                              fields,
                              "lblInvalidDateError"
                            )}
                            minDateMessage={getFieldValue(
                              fields,
                              "lblMinDateError"
                            )}
                            maxDateMessage={getFieldValue(
                              fields,
                              "lblMaxDateError"
                            )}
                          />

                          <div className="row mt-3">
                            <div className="col-md-12">
                              <div className="registration-btn1">
                                <button
                                  type="button"
                                  className="btn btn-primary ripple-surface1 btn-1"
                                  onClick={() => {
                                    location = "/";
                                  }}
                                >
                                  <Text field={fields.cancelButton} />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={memberIdError}
                                  onClick={() => setIsSubmited(true)}
                                >
                                  <Text field={fields.nextButton} />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row step1-reg">
                            <div>
                              <Text field={fields.alreadyRegistered} />
                              <a
                                href="#"
                                className="sign-link text-zoom"
                                onClick={handleLogin}
                              >
                                {getFieldValue(fields, "lnkSignIn").text}
                                <img
                                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                  alt={getFieldValue(fields, "lnkSignIn").text}
                                />
                              </a>
                            </div>
                            {/* <div>
                              <Text field={fields.needHelpRegistering} />
                              <a
                                href={getFieldValue(fields, "lnkHelp").href}
                                className="sign-link text-zoom"
                              >
                                {getFieldValue(fields, "lnkHelp").text}
                                <img
                                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                  alt={getFieldValue(fields, "lnkSignIn").text}
                                />
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MemberInformation;
