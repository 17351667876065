import React, { useContext } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { handleLogout } from "../../utils/helpers";
import { AppContext } from "../../contexts/app.context";
import * as cdpAPI from "../../services/CDPAPI";

const Logout = ({ fields, postition = "top" }) => {
  const { isSupportUser } = useContext(AppContext);
  let logoutLbl = getFieldValue(fields, "lblLogout", false);

  const logoutMolina = (e) => {
    localStorage.removeItem("chatEnable");
    Genesys("command", "Messenger.close");
    Genesys("command", "MessagingService.clearConversation");
    //CDP logout event tracking
    if (!isSupportUser) {
      try {
        cdpAPI.sendCDPAlert("FORCE_CLOSE");
        const domainname = window.location.hostname;
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name =
            eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
          if (name.includes("bid_")) {
            document.cookie =
              name +
              "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=." +
              domainname;
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    handleLogout(isSupportUser, e);
  };
  return (
    <a
      href="#"
      className={`${postition === "top" ? "dropdown-item" : "nav-link"}`}
      onClick={(e) => logoutMolina(e)}
    >
      {logoutLbl}
    </a>
  );
};

export default Logout;
