import React, { useContext, useEffect } from "react";
import {
  Placeholder,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  CARE_PLAN_TAB,
  DEPENDANT_18AndOVER,
  DEPENDANT_UNDER18,
  PRIMARY_SUBSCRIBER_ROLEID,
  HomePageUrl,
  SUBSCRIBER,
  LAB_RESULTS_TAB,
  NYMCC_PROGRAMID,
  ALLERGIES_TAB,
  NY,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  MEDICATIONS_TAB,
  NY_MD_HARP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  OH,
  MEDICAID,
} from "../../define.constants";

import { AppContext } from "../../contexts/app.context";
import { useGetCarePlanAccessAPI } from "../../hooks/useGetCarePlanAccessAPI";
import { redirectTo } from "./../../utils/helpers";

const MyHealthRecordLayout = (props) => {
  const { rendering, fields } = props;
  const { carePlanFlag, isLoaded } = useGetCarePlanAccessAPI();
  const { MemberStateName, programId, memberLOB } = useContext(AppContext);
  const {
    isSwitchedToDependent,
    isDependentLoggedIn,
    roleId,
    isSwitchedView,
    DependentCategory,
    switchedUserDependentCat,
    loggedInUserDependentCat,
    switchedUserType,
  } = useContext(AppContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const isMedicaid = memberLOB?.trim()?.toLowerCase() === MEDICAID;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();

  useEffect(() => {
    if (
      (isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserType === SUBSCRIBER) || //Conditions for 1486 & 1490
      (!isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserDependentCat === DEPENDANT_18AndOVER) //Conditions for 1489
    ) {
      return redirectTo(HomePageUrl, false);
    }
  }, [
    isDependentLoggedIn,
    isSwitchedView,
    switchedUserDependentCat,
    switchedUserType,
  ]);

  const hiddenTabs = () => {
    let tabsToHide = [];
    if (!carePlanFlag || (MemberStateName === OH && isMedicaid)) {
      tabsToHide.push(CARE_PLAN_TAB);
    }
    if (
      !isDependentLoggedIn && //Conditions for MMPI-1488
      isSwitchedView &&
      switchedUserDependentCat === DEPENDANT_UNDER18
    ) {
      tabsToHide.push(
        "MyHealthRecord-ServiceAuthorizations",
        "MyHealthRecord-Referrals",
        "MyHealthRecord-LabResults",
        "MyHealthRecord-Allergies",
        "MyHealthRecord-Medications",
        "MyHealthRecord-InpatientAdmissionsERVisits",
        "MyHealthRecord-CarePlan",
        "MyHealthRecord-CTContactInfoAccess",
        "MyHealthRecord-CTContactAccessManagement"
      );
    }

    //Hiding LabResults Tab FOR NON_MP
    if (!isMarketplaceMember) {
      tabsToHide.push(LAB_RESULTS_TAB);
    }
    if (MemberStateName == NY && programId == NYMCC_PROGRAMID) {
      tabsToHide.push(ALLERGIES_TAB);
    }
    if (MemberStateName != OH || (MemberStateName == OH && !isMedicaid)) {
      tabsToHide.push("MyHealthRecord-CTContactInfoAccess");
      tabsToHide.push("MyHealthRecord-CTContactAccessManagement");
    }

    return tabsToHide;
  };

  return (
    <>
      {isLoaded && (
        <div className="my-health-record">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <div className="page-heading">
                <Text field={fields?.heading ?? ""} />
              </div>
            </div>
          </div>

          <Placeholder
            name="jss-my-health-record-layout"
            rendering={rendering}
            hiddenTabs={hiddenTabs()}
          />
        </div>
      )}
    </>
  );
};

export default MyHealthRecordLayout;
