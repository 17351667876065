import React, { useContext, useState, useEffect } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import StepProgressBar from "./StepProgressBar";
import { MultiStepContext } from "../../contexts/Steps.context";
import { HIDE_EMAIL_CHARACTERS, ICON_PATH } from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "../../contexts/modal.context";
import Authentication from "./Authentication";
import AuthenticationPhone from "./AuthenticationPhone";
import { apiResponseTemplate } from "../AccountSettings-PayerWrapper/formatAPISubmitData";

const Verify = (props) => {
  const { fields } = props;
  const { openModal } = useContext(ModalContext);
  const { setStep, email, phoneNo, textConsent } = useContext(MultiStepContext);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [securityCode, setSecurityCode] = useState("");
  const [deviceId, setDeviceId] = useState("");
  var emailNameLength = email?.substring(0, email.lastIndexOf("@")).length;
  var emailDomain = email?.substring(email.lastIndexOf("@"));
  var str = HIDE_EMAIL_CHARACTERS;
  useEffect(() => {
    if (emailVerified) {
      setNextEnabled(true);
    }
  }, [emailVerified, phoneVerified]);
  const NextStep = () => {
    if (nextEnabled) {
      setStep(4);
    }
  };
  const sendSecurityCode = (param) => {
    setSpinner(true);
    RegistrationAPI.getSendOrResendSecurityCode({
      EmailId: email,
      PhoneNum: phoneNo,
      isphonenumber: param !== "email" ? true : false,
      Functionality: "MemberRegistration",
      isResendClicked: false,
    })
      .then((data) => {
        if (data.Message === "Success") {
          if (param === "phoneNum") {
            setDeviceId(data?.ResultData?.DeviceID);
            openModal({
              content: (
                <AuthenticationPhone
                  phoneVerifiedCheck={setPhoneVerified}
                  setStep={setStep}
                  {...props}
                  deviceId={data?.ResultData?.DeviceID}
                  email={email}
                  phoneNo={phoneNo}
                  textConsent={textConsent}
                />
              ),
              version: 1,
            });
          }
          if (param === "email") {
            emailPopup();
          }
          setSecurityCode(data.ResultData);
        } else {
          const content = apiResponseTemplate(
            getFieldValue(fields, "errorApiTitle"),
            getFieldValue(fields, "errorApiMsg")
          );
          openModal({
            content,
            version: 1,
          });
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  const emailPopup = () => {
    openModal({
      content: (
        <Authentication
          emailVerifiedCheck={setEmailVerified}
          setStep={setStep}
          {...props}
          email={email}
          phoneNo={phoneNo}
          textConsent={textConsent}
        />
      ),
      version: 1,
    });
  };
  const openModelPopup = (email) => {
    sendSecurityCode("email");
  };
  const openModelPopupPhone = (email, phoneNo) => {
    sendSecurityCode("phoneNum");
  };
  return (
    <>
      <div className="Registration">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <section className="d-flex justify-content-center">
              <div className="card lg-card">
                <div className="card-body">
                  <StepProgressBar fields={fields} />
                  {/* <!-- step3 --> */}
                  <div>
                    <div className="verify-section">
                      <div className="col-10 col-sm-9">
                        <div className="row-title">
                          <img
                            src={`${ICON_PATH}/check-green.svg`}
                            className="check-green"
                            style={{
                              display: emailVerified ? "inline" : "none",
                            }}
                            alt={getFieldValue(fields, "emailAdd")}
                          />
                          <b>
                            <Text field={fields.accountSuccessMessage1} />
                          </b>
                          <br />
                          <br />
                          <Text field={fields.accountSuccessMessage2} />
                          <br />
                          <br />
                          <b>
                            <Text field={fields.emailAdd} />
                          </b>
                        </div>
                        <div className="row">
                          <div className="col-10">
                            <input
                              name="emailField"
                              type="text"
                              disabled="true"
                              className="form-control"
                              value={email}
                            />
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="btn btn-primary ripple-surface"
                              style={{
                                display: emailVerified ? "none" : "inline",
                              }}
                              onClick={() => openModelPopup(email)}
                            >
                              <Text field={fields.authentication} />
                            </button>
                          </div>
                        </div>
                        <div className="step3-error-msg"> {errorMsg}</div>

                        <div style={{ display: phoneNo ? "block" : "none" }}>
                          <div className="row-title">
                            <img
                              src={`${ICON_PATH}/check-green.svg`}
                              className="check-green"
                              style={{
                                display: phoneVerified ? "inline" : "none",
                              }}
                              alt={getFieldValue(fields, "phoneNo")}
                            />
                            <b>
                              <Text field={fields.phoneNo} />
                            </b>
                          </div>
                          <div className="row">
                            <div className="col-10">
                              <input
                                id="phoneField"
                                type="text"
                                disabled="true"
                                className="form-control"
                                value={phoneNo}
                              />
                            </div>
                            <div className="col-2">
                              <button
                                type="button"
                                className="btn btn-primary ripple-surface"
                                disabled={!emailVerified}
                                style={{
                                  display: phoneVerified ? "none" : "inline",
                                }}
                                onClick={() =>
                                  openModelPopupPhone(email, phoneNo)
                                }
                              >
                                <Text field={fields.authentication} />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="step3-error-msg"> {errorMsg}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="registration-btn security">
                          <button
                            type="button"
                            className="btn btn-primary ripple-surface"
                            disabled={!nextEnabled}
                            onClick={NextStep}
                          >
                            <Text field={fields.nextButton} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
