import React, { useContext, useEffect, useState } from "react";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import ViewIdCard from "./ViewIdCard";
import ReqIdModal from "./RequestID";
import PrintIdModal from "./PrintID";
import { AppContext } from "../../contexts/app.context";
import DependentList from "./common/DependentList";
import { useMemAPIIdCardDetailsAPI } from "../../hooks/useMemAPIIdCardDetailsAPI";
import { getStateLobConfigurationItems } from "./../../utils/helpers";
import {
  SUBSCRIBER,
  DEPENDENT_WITH_ACCESS,
  AZ,
  FL,
  MI,
  IL,
  IA,
  WI,
  TX,
  WA,
  VA,
  CA,
  NV,
  OH,
  NM,
  SC,
  UT,
  ID,
  MA,
  MS,
  MARKETPLACE,
  MEDICAID,
  MEDICARE,
  KY,
  NY,
  NY_MD_NMC_PROGRAMID,
  NY_MD_HARP_PROGRAMID,
  NYMCC_PROGRAMID,
  NY_MD_CHP_PROGRAMID,
  NY_MD_EP_PROGRAMID1,
  NY_MD_EP_PROGRAMID2,
  NY_MD_EP_PROGRAMID3,
  NY_MD_EP_PROGRAMID4,
  NY_MD_EP_PROGRAMID_EP_200_250,
  FL_SP_PROGRAMID,
  FL_MMA_LTC_PROGRAMID,
  FL_MMA_PROGRAMID,
  FL_LTC_PROGRAMID,
  GLOBAL_VARIABLE,
  WA_MD_IMCCommon_LIST,
  WA_MD_BHSO,
  MS_MEDICAID_CHIP,
  MS_MEDICAID_CAN,
  IA_MEDICAID_PLANID,
  IA_HWK_PLANID,
  IA_IHAWP_PLANID,
  TX_MD_PLUS_PROGRAMID,
  TX_MD_STAR_PROGRAMID,
  TX_MD_CHIPPerinate_PROGRAMID,
  TX_MD_STARDUAL_PROGRAMID,
  TX_MD_CHIP_PROGRAMID,
  DUALS,
  TX_MD_CHIP_RSA_PROGRAMID,
  TX_MD_CHIP_PERINATE_RSA_PROGRAMID,
  IL_MLTSS_PROGRAM,
  IL_HC_PROGRAM,
  FULL,
  OH_STANDERD,
  CSP_PARTIAL,
  CSP_FULL,
  PARTIAL,
  RISE,
  STANDARD,
  FAMIS_M4,
  NE,
} from "../../define.constants";
import ViewIdCardTpl2 from "./ViewIdCardTpl2";
import ViewIdCard2023 from "./ViewIdCard2023";
import ViewIdCard2024 from "./ViewIdCard2024";
import { CustomSelect } from "../../common/Select";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import ViewIdCardAZMedicaid from "./NonMarketplace-IDCard/AZ/Medicaid/ViewIdCardAZMedicaid";
import ViewIdCardMedicare from "./NonMarketplace-IDCard/Medicare/ViewIdCardMedicare";
import ViewIdCardMA from "./NonMarketplace/MA/Medicare/ViewIdCardMA";
import ViewIdCardMedicaidMA from "./NonMarketplace/MA/Medicaid/ViewIdCardMedicaidMA";
import ViewIdCardMedicaidNV from "./NonMarketplace/NV/Medicaid/ViewIdCardMedicaidNV";
import ViewIdCardMedicareUT from "./NonMarketplace/UT/Medicare/ViewIdCardMedicareUT";
import ViewIdCardMedicaidVA from "./NonMarketplace/VA/Medicaid/ViewIdCardMedicaidVA";
import ViewIdCardFamisMedicaidVA from "./NonMarketplace/VA/Medicaid/ViewIdCardFamisMedicaidVA";
import ViewIdCardMedicareVA from "./NonMarketplace/VA/Medicare/ViewIdCardMedicareVA";
import ViewIDCardKYMedicaid from "./NonMarketplace-IDCard/KY/Medicaid/ViewIDCardKYMedicaid";
import ViewIdCardMedicareAZ from "./NonMarketplace-IDCard/AZ/Medicare/ViewIdCardMedicareAZ";
import ViewIDCardKYMedicare from "./NonMarketplace-IDCard/KY/Medicare/ViewIDCardKYMedicare";
import ViewMedicaidIdCardIL from "./NonMarketplace-IDCard/IL/Medicaid/ViewMedicaidIdCardIL";
import ViewMedicaidIdCardILHBI from "./NonMarketplace-IDCard/IL/Medicaid/ViewMedicaidIdCardILHBI";
import ViewMedicaidIdCardMltssIL from "./NonMarketplace-IDCard/IL/Medicaid/ViewMedicaidIdCardMltssIL";
import ViewIdCardDualIL from "./NonMarketplace-IDCard/IL/Dual/ViewIdCardDualIL";
import ViewIdCardMedicaidIAHWK from "./NonMarketplace/IA/Medicaid/ViewIdCardHawki";
import ViewIdCardMedicaidIAIHL from "./NonMarketplace/IA/Medicaid/ViewIdCardIHL";
import ViewIdCardStateUTMedicaid from "./NonMarketplace/UT/Medicaid/ViewIdCardStateUTMedicaid";
import ViewIDCardMedicaidCA from "./NonMarketplace-IDCard/CA/Medicaid/ViewIDCardMedicaidCA";
import ViewIDCardMedicaidSC from "./NonMarketplace-IDCard/SC/Medicaid/ViewIDCardMedicaidSC";
import ViewIdCardMedicaidNY from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNY";
import ViewIdCardMedicaidNYMenryHarp from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYMenryHarp";
import ViewIdCardMedicaidNYNMC from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYNMC";
import ViewIdCardMedicaidNYEP from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYEP";
import ViewIdCardMedicaidNYEPNonAffinity from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYEPNonAffinity";
import ViewIdCardMedicaidNYChpNonAffinity from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYChpNonAffinity";
import ViewIdCardMedicaidNYLTC from "./NonMarketplace/NY/Medicaid/ViewIdCardMedicaidNYLTC";
import ViewIdCardMedicaidWI from "./NonMarketplace/WI/Medicaid/ViewIdCardMedicaidWI";
import ViewIdCardSpecialPlanFL from "./NonMarketplace/FL/Medicaid/ViewIdCardSpecialPlanFL";
import ViewIdCardMmaLlcFL from "./NonMarketplace/FL/Medicaid/ViewIdCardMmaLlcFL";
import ViewIdCardLtcFL from "./NonMarketplace/FL/Medicaid/ViewIdCardLtcFL";
import ViewIdCardMedicaidMS from "./NonMarketplace/MS/Medicaid/ViewIdCardMedicaidMS";
import ViewIdCardMedicaidMI from "./NonMarketplace/MI/Medicaid/ViewIdCardMedicaidMI";
import ViewIdCardMedicaidWA from "./NonMarketplace/WA/Medicaid/ViewIdCardMedicaidWA";
import ViewIdCardMedicaidWABHSO from "./NonMarketplace/WA/Medicaid/ViewIdCardMedicaidWABHSO";
import ViewIdCardMedicaidTX from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTX";
import ViewIdCardMedicaidTXStar from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXStar";
import ViewIdCardMedicaidTXDual from "./NonMarketplace-IDCard/TX/Duals/ViewIDCardMedicaidTXDual";
import ViewIdCardMedicaidTXPerinate from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXPerinate";
import ViewIdCardMedicaidTXChip from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXChip";
import ViewIDCardMedicaidTXStar from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXStar";
import ViewIDCardMedicaidTXChipRSA from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXChipRSA";
import ViewIDCardMedicaidTXChipPerinateRSA from "./NonMarketplace-IDCard/TX/Medicaid/ViewIDCardMedicaidTXChipPerinateRSA";
import ViewIdCardIDMedicaid from "./NonMarketplace/ID/Medicaid/ViewIdCardIDMedicaid";
import ViewIdCardDualsMI from "./NonMarketplace/MI/Dual/ViewIdCardDualsMI";
import ViewIdCardDualSc from "./NonMarketplace-IDCard/SC/Dual/ViewIdCardDualSc";
import ViewIdCardOhDual from "./NonMarketplace-IDCard/OH/Dual/ViewIdCardOhDual";
import ViewIdCardOHRiseFull from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardOHRiseFull";
import ViewIdCardOHRisePartial from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardOHRisePartial";
import ViewIdCardNonOHRiseStanderd from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardNonOHRiseStanderd";
import ViewIdCardNonOHRiseFull from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardNonOHRiseFull";
import ViewIdCardNonOHRisePartial from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardNonOHRisePartial";
import ViewIdCardOHRiseStanderd from "./NonMarketplace-IDCard/OH/Medicaid/ViewIdCardOHRiseStanderd";
import ViewIdCardNEMedicaid from "./NonMarketplace/NE/Medicaid/ViewIdCardNEMedicaid";
import ViewIdCardNMMedicaid from "./NonMarketplace/NM/Medicaid/ViewIdCardNMMedicaid";

const MemberIDCardDetails = (props) => {
  const {
    Dependents,
    memberLOB,
    MemberStateName,
    userType,
    isTermedMember,
    memberRKSID,
    programId,
    planId,
    DSNPdetails,
    isNYAffinityMember,
    isILHBIMember,
    OH_CSPValue,
    IsOHIORISE,
    VA_Category,
  } = useContext(AppContext);
  const [recordList, setRecordList] = useState([]);
  const [record, setRecord] = useState(null);
  const { isIdCardToggleRemovalFlag, listRecords } = useGetDDLYearsAPI();
  const [yearSel, setYearSel] = useState(null);
  const currentYear = new Date().getFullYear();

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    let listYear = listRecords?.sort((a, b) => b.Year - a.Year);
    listYear =
      isIdCardToggleRemovalFlag === true
        ? listYear.filter((l) => l.Year === currentYear)
        : listYear;
    setRecordList(listYear);
    setRecord(listYear[0]);
    setYearSel(listYear[0]?.Year);
  }, [listRecords]);

  useEffect(() => {
    setShowDependentSelection(
      userType === SUBSCRIBER || userType === DEPENDENT_WITH_ACCESS
    );
  }, [userType]);

  const handleYearSelect = (option) => {
    let recordVal = recordList.filter(
      (r) => Number(r.Year) === option.value
    )[0];
    setRecord(recordVal);
    setYearSel(recordVal?.Year);
  };
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  const list = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    record?.Year
  );
  const [selectedDependent, setSelectedDependent] = useState({
    RksMemberId: null,
    StateCode: null,
  });
  const { result, details, idCard, drugInfo } = useMemAPIIdCardDetailsAPI(
    selectedDependent,
    record?.Year,
    memberLOB
  );
  const [showDependentSelection, setShowDependentSelection] = useState(null);
  //List of ID  card collection based upon LOB
  const viewIDCardCompCollection = {
    az_medicaid: ViewIdCardAZMedicaid,
    ma_medicare: ViewIdCardMA,
    ma_medicaid: ViewIdCardMedicaidMA,
    medicare: ViewIdCardMedicare,
    va_medicaid: ViewIdCardMedicaidVA,
    va_famis_medicaid: ViewIdCardFamisMedicaidVA,
    va_medicare: ViewIdCardMedicareVA,
    ut_medicare: ViewIdCardMedicareUT,
    az_medicare: ViewIdCardMedicareAZ,
    ky_medicare: ViewIDCardKYMedicare,
    il_medicaid: ViewMedicaidIdCardIL,
    il_medicaid_hbi: ViewMedicaidIdCardILHBI,
    il_medicaid_mltss: ViewMedicaidIdCardMltssIL,
    il_dual: ViewIdCardDualIL,
    ia_medicaid_hwk: ViewIdCardMedicaidIAHWK,
    ia_medicaid_ihl: ViewIdCardMedicaidIAIHL,
    ut_medicaid: ViewIdCardStateUTMedicaid,
    ca_medicaid: ViewIDCardMedicaidCA,
    nv_medicaid: ViewIdCardMedicaidNV,
    viewIdCard: ViewIdCard,
    sc_medicaid: ViewIDCardMedicaidSC,
    sc_dual: ViewIdCardDualSc,
    ny_medicaid_harp: ViewIdCardMedicaidNYMenryHarp,
    ny_medicaid_nmc: ViewIdCardMedicaidNYNMC,
    ny_medicaid_ltc: ViewIdCardMedicaidNYLTC,
    ny_medicaid_ep: ViewIdCardMedicaidNYEP,
    ny_medicaid_ep_non_affinity: ViewIdCardMedicaidNYEPNonAffinity,
    ny_medicaid_chp: ViewIdCardMedicaidNY,
    ny_medicaid_chp_non_affinity: ViewIdCardMedicaidNYChpNonAffinity,
    wi_medicaid: ViewIdCardMedicaidWI,
    fl_sp_medicaid: ViewIdCardSpecialPlanFL,
    fl_mma_ltc_medicaid: ViewIdCardMmaLlcFL,
    fl_ltc_medicaid: ViewIdCardLtcFL,
    ms_medicaid: ViewIdCardMedicaidMS,
    mi_medicaid: ViewIdCardMedicaidMI,
    mi_duals: ViewIdCardDualsMI,
    wa_medicaid_common: ViewIdCardMedicaidWA,
    wa_medicaid_bhso: ViewIdCardMedicaidWABHSO,
    ky_medicaid: ViewIDCardKYMedicaid,
    tx_medicaid: ViewIdCardMedicaidTX,
    tx_medicaid_star: ViewIdCardMedicaidTXStar,
    tx_Duals: ViewIdCardMedicaidTXDual,
    tx_medicaid_Chip: ViewIdCardMedicaidTXChip,
    tx_medicaid_Perinate: ViewIdCardMedicaidTXPerinate,
    tx_medicaid_Chip_RSA: ViewIDCardMedicaidTXChipRSA,
    tx_medicaid_Chip_Perinate_RSA: ViewIDCardMedicaidTXChipPerinateRSA,
    id_medicaid: ViewIdCardIDMedicaid,
    oh_Duals: ViewIdCardOhDual,
    oh_OhioRise_full: ViewIdCardOHRiseFull,
    oh_OhioRise_partial: ViewIdCardOHRisePartial,
    oh_OhioRise_standerd: ViewIdCardOHRiseStanderd,
    oh_NonOhioRise_full: ViewIdCardNonOHRiseFull,
    oh_NonOhioRise_partial: ViewIdCardNonOHRisePartial,
    oh_NonOhioRise_standerd: ViewIdCardNonOHRiseStanderd,
    ne_medicaid: ViewIdCardNEMedicaid,
    nm_medicaid: ViewIdCardNMMedicaid,
  };
  //Based on State and LOB ID Card's will be rendering
  const getappropriateIdCard = (stateName, item) => {
    let CurrentViewIDCardNonMP = "";
    const program_fl = [FL_MMA_LTC_PROGRAMID, FL_MMA_PROGRAMID];
    const program_il = [IL_HC_PROGRAM, NY_MD_CHP_PROGRAMID];
    const program_ep = [
      NY_MD_EP_PROGRAMID1,
      NY_MD_EP_PROGRAMID2,
      NY_MD_EP_PROGRAMID3,
      NY_MD_EP_PROGRAMID4,
      NY_MD_EP_PROGRAMID_EP_200_250,
    ];
    const medicare_states = [WA, TX, FL, WI, MI, CA, IL, NV, OH, NM, SC]; //WA, TX, FL, WI, MI, CA, IL, NV, OH, NM, SC sharing same template for Medicare LOB.
    const ut_id_states = [UT, ID];
    const az_va_states = [AZ];
    const va_state = [VA];
    //const ma_ny_states = [MA, NY];
    const lob = memberLOB.toLowerCase();
    const isVaFamisMember = VA_Category?.trim() === FAMIS_M4;
    if (!isMarketplaceMember) {
      switch (lob) {
        case MEDICARE:
          switch (MemberStateName) {
            case medicare_states.includes(MemberStateName)
              ? MemberStateName
              : null:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["medicare"];
              break;
            case az_va_states.includes(MemberStateName)
              ? MemberStateName
              : null:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["az_medicare"];
              break;
            case va_state.includes(MemberStateName) ? MemberStateName : null:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["va_medicare"];
              break;
            case KY:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ky_medicare"];
              break;
            case MA:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ma_medicare"];
              break;
            case NY:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ma_medicare"];
              break;
            case ut_id_states.includes(MemberStateName)
              ? MemberStateName
              : null:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ut_medicare"];
              break;
            default:
              CurrentViewIDCardNonMP = "";
          }
          break;
        case MEDICAID:
          switch (MemberStateName) {
            case AZ:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["az_medicaid"];
              break;
            case NE:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ne_medicaid"];
              break;
            case NM:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["nm_medicaid"];
              break;
            case KY:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ky_medicaid"];
              break;
            case NV:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["nv_medicaid"];
              break;
            case MI:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["mi_medicaid"];
              break;
            case MS:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ms_medicaid"];
              break;
            case NY:
              switch (programId) {
                case NY_MD_NMC_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ny_medicaid_nmc"];
                  break;
                case NY_MD_HARP_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ny_medicaid_harp"];
                  break;
                case NYMCC_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ny_medicaid_ltc"];
                  break;
                case program_ep.includes(programId) ? programId : null:
                  CurrentViewIDCardNonMP = isNYAffinityMember
                    ? viewIDCardCompCollection["ny_medicaid_ep"]
                    : viewIDCardCompCollection["ny_medicaid_ep_non_affinity"];
                  break;
                case NY_MD_CHP_PROGRAMID:
                  CurrentViewIDCardNonMP = isNYAffinityMember
                    ? viewIDCardCompCollection["ny_medicaid_chp"]
                    : viewIDCardCompCollection["ny_medicaid_chp_non_affinity"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case IA:
              switch (planId) {
                case IA_HWK_PLANID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ia_medicaid_hwk"];
                  break;
                case IA_MEDICAID_PLANID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ia_medicaid_ihl"];
                  break;
                case IA_IHAWP_PLANID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["ia_medicaid_ihl"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case IL:
              switch (programId) {
                case program_il.includes(programId) ? programId : null:
                  CurrentViewIDCardNonMP = isILHBIMember
                    ? viewIDCardCompCollection["il_medicaid_hbi"]
                    : viewIDCardCompCollection["il_medicaid"];
                  break;
                case IL_MLTSS_PROGRAM:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["il_medicaid_mltss"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case UT:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ut_medicaid"];
              break;
            case CA:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ca_medicaid"];
              break;
            case SC:
              switch (programId) {
                case NY_MD_CHP_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["sc_medicaid"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case MA:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ma_medicaid"];
              break;
            case WI:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["wi_medicaid"];
              break;
            case VA:
              CurrentViewIDCardNonMP = isVaFamisMember
                ? viewIDCardCompCollection["va_famis_medicaid"]
                : viewIDCardCompCollection["va_medicaid"];
              break;
            case OH:
              switch (OH_CSPValue) {
                case CSP_FULL:
                  CurrentViewIDCardNonMP = IsOHIORISE
                    ? viewIDCardCompCollection["oh_OhioRise_full"]
                    : viewIDCardCompCollection["oh_NonOhioRise_full"];
                  break;
                case CSP_PARTIAL:
                  CurrentViewIDCardNonMP = IsOHIORISE
                    ? viewIDCardCompCollection["oh_OhioRise_partial"]
                    : viewIDCardCompCollection["oh_NonOhioRise_partial"];
                  break;
                case OH_STANDERD:
                  CurrentViewIDCardNonMP = IsOHIORISE
                    ? viewIDCardCompCollection["oh_OhioRise_standerd"]
                    : viewIDCardCompCollection["oh_NonOhioRise_standerd"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case ID:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["id_medicaid"];
              break;
            case FL:
              switch (programId) {
                case FL_SP_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["fl_sp_medicaid"];
                  break;
                case program_fl.includes(programId) ? programId : null:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["fl_mma_ltc_medicaid"];
                  break;
                case FL_LTC_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["fl_ltc_medicaid"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case WA:
              switch (programId) {
                case WA_MD_BHSO:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["wa_medicaid_bhso"];
                  break;
                case WA_MD_IMCCommon_LIST.includes(programId)
                  ? programId
                  : null:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["wa_medicaid_common"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;
            case TX:
              CurrentViewIDCardNonMP =
                viewIDCardCompCollection["tx_medicaid_Dual"];
              switch (programId) {
                case TX_MD_PLUS_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid"];
                  break;
                case TX_MD_CHIPPerinate_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_Perinate"];
                  break;
                case TX_MD_STARDUAL_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_Dual"];
                  break;
                case TX_MD_STAR_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_star"];
                  break;
                case TX_MD_CHIP_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_Chip"];
                  break;
                case TX_MD_CHIP_RSA_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_Chip_RSA"];
                  break;
                case TX_MD_CHIP_PERINATE_RSA_PROGRAMID:
                  CurrentViewIDCardNonMP =
                    viewIDCardCompCollection["tx_medicaid_Chip_Perinate_RSA"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
              break;

            default:
              CurrentViewIDCardNonMP = "";
          }
          break;
        case DUALS:
          switch (MemberStateName) {
            case MI:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["mi_duals"];
              break;
            case ID:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["ut_medicare"];
              break;
            case IL:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["il_dual"];
              break;
            case CA:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["medicare"];
              break;
            case SC:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["sc_dual"];
              break;
            case OH:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["oh_Duals"];
              break;
            case TX:
              CurrentViewIDCardNonMP = viewIDCardCompCollection["tx_Duals"];
              switch (programId) {
                case TX_MD_STARDUAL_PROGRAMID:
                  CurrentViewIDCardNonMP = viewIDCardCompCollection["tx_Duals"];
                  break;
                default:
                  CurrentViewIDCardNonMP = "";
              }
          }
          break;
        default:
          CurrentViewIDCardNonMP = "";
      }
    }
    //MarketPlace LOB Id card rendering
    if (isMarketplaceMember) {
      CurrentViewIDCardNonMP = viewIDCardCompCollection["viewIdCard"];
    }
    if (CurrentViewIDCardNonMP) {
      return (
        <CurrentViewIDCardNonMP
          {...item}
          result={result}
          details={details}
          idCard={idCard}
          drugInfo={drugInfo}
        />
      );
    }
  };

  const isDSNPMedicaidMember =
    DSNPdetails?.length > 0 && memberLOB?.toLowerCase() === MEDICAID;

  return (
    <>
      {list?.map((item, index) => (
        <div key={index}>
          {isTermedMember ? (
            <RichText field={item.fields.lblIDDisclaimer} />
          ) : isDSNPMedicaidMember ? (
            <Text field={item.fields.lblIDReferToPrimary} />
          ) : (
            <section className="member-id-card">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                  <div className="page-heading">
                    <Text field={item.fields.PageTitle} />
                  </div>
                </div>
                {listRecords?.length > 1 && !isIdCardToggleRemovalFlag && (
                  <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 myid-filters">
                    <div className="plan-year-block">
                      <div className="plan-year-space">
                        <Text field={item.fields.lblPlanYear} />
                      </div>
                      <div>
                        <CustomSelect
                          field={{
                            name: "idPlanYears",
                            value: record?.Year,
                          }}
                          className="form-select"
                          aria-label={getFieldValue(props, "lblPlanYear")}
                          options={recordList.map((recItem) => ({
                            value: recItem.Year,
                            label: recItem.Year,
                          }))}
                          isFormik={false}
                          onChange={handleYearSelect}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-md-none mbl-label">
                {isMarketplaceMember &&
                  showDependentSelection &&
                  Dependents?.length > 0 && (
                    <Text field={item.fields.lblCurrentViewing} />
                  )}
              </div>
              <div className="col-md-6 col-lg-3 col-12 mb-4">
                {isMarketplaceMember &&
                  showDependentSelection &&
                  Dependents?.length > 0 && (
                    <DependentList
                      {...item}
                      elem="dropdown"
                      dependents={Dependents}
                      onDependentSelected={(data) => setSelectedDependent(data)}
                    />
                  )}
              </div>
              <div className="row print-options-modal">
                <div className="col-md-12 col-lg-12 col-xxl-12">
                  <div className="row">
                    {idCard && (
                      <div className="col-md-4 col-lg-3 col-xl-2 col-xxl-3 col-6 id-print">
                        <PrintIdModal
                          {...item}
                          dependents={Dependents}
                          details={details}
                          showUserSwitch={showDependentSelection}
                          yearSel={yearSel}
                        ></PrintIdModal>
                      </div>
                    )}
                    <div className="col-md-8 col-12 id-print">
                      <ReqIdModal
                        {...item}
                        dependents={Dependents}
                        showUserSwitch={showDependentSelection}
                        yearSel={yearSel}
                      ></ReqIdModal>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                <div className="justify-content-center row">
                  {idCard && (
                    <>
                      {isMarketplaceMember &&
                        (() => {
                          if (
                            record?.Year === undefined ||
                            Number(record?.Year) <= 2021
                          ) {
                            return (
                              <ViewIdCard
                                {...item}
                                result={result}
                                details={details}
                                idCard={idCard}
                                drugInfo={drugInfo}
                              />
                            );
                          } else if (Number(record?.Year) == 2022) {
                            return (
                              <ViewIdCardTpl2
                                {...item}
                                result={result}
                                details={details}
                                idCard={idCard}
                              />
                            );
                          } else if (Number(record?.Year) == 2023) {
                            return (
                              <ViewIdCard2023
                                {...item}
                                result={result}
                                details={details}
                                idCard={idCard}
                              />
                            );
                          } else {
                            return (
                              <ViewIdCard2024
                                {...item}
                                result={result}
                                details={details}
                                idCard={idCard}
                              />
                            );
                          }
                        })()}
                      {!isMarketplaceMember &&
                        getappropriateIdCard(idCard?.StateName, item)}
                    </>
                  )}
                </div>
              </div>
            </section>
          )}
        </div>
      ))}
    </>
  );
};

export default MemberIDCardDetails;
