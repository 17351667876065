/**
 * MODULE NAME: Header
 */
export const HEADER_USER_SWITCH = "Header-UserSwitch";

/**
 * MODULE NAME: Home Page
 */
export const HOME_TRACK_MY_SPENDING = "Home-TrackMySpending";
export const HOME_MY_MESSAGE_CARD = "Home-MyMessageCard";

/**
 * MODULE NAME: My Coverage Page
 */
export const COVERAGE_TRACK_MY_SPENDING = HOME_TRACK_MY_SPENDING; //Both cards are sharing same API!
export const COVERAGE_ELIGIBILITY = "MyCoverage-Eligibility";

/**
 * MODULE NAME: My Wellness Page
 */
export const MY_WELLNESS_GET_ALERTS = "MyWellness-GetAlerts";
export const MY_WELLNESS_GET_ASSESSMENTS = "MyWellness-Assessments";
export const MY_WELLNESS_TAKE_ASSESSMENT = "MyWellness-TakeAssessments";
export const MY_WELLNESS_ASSESSMENT_FORM = "MyWellness-AssessmentsForm";

/**
 * MODULE NAME: My Health Record Page
 */
export const MY_HEALTH_RECORD_GET_ALLERGIES = "MyHealthRecord-GetAllergies";
export const MY_HEALTH_RECORD_GET_LAB_RESULTS = "MyHealthRecord-GetLabResults";
export const MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION =
  "MyHealthRecord-ViewServiceAuthorization";
export const MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS =
  "MyHealthRecord-ViewInpatientAndEDVisits";
export const MY_HEALTH_RECORD_GET_MEDICATIONS_HISTORY =
  "MyHealthRecord-GetMedicationsHistory";
export const MY_HEALTH_RECORD_VIEW_MEDICATIONS =
  "MyHealthRecord-ViewMedications";
export const MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS =
  "MyHealthRecord-ViewMemberReferrals";
export const MY_HEALTH_RECORD_CONTACT_INFO =
  "MyHealthRecord-CTContactInfoAccess";
export const MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY =
  "MyHealthRecord-MemAPIServiceHistory";
// export const MY_HEALTH_RECORD_GET_CLAIM_EOB_DOCUMENT =
//   "MyHealthRecord-GetClaimEOBDocument";
export const MY_HEALTH_RECORD_GET_CARE_PLAN = "MyHealthRecord-GetCarePlan";
/**
 * MODULE NAME: AccountSettings Page
 */
export const ACCSETTINGS_MANAGE_DATA_SHARING =
  "AccountSettings-ManageDataSharing";
export const ACCSETTINGS_COMMUNICATION_PREFERENCE =
  "AccountSettings-CommunicationPreference";
export const ACCOUNT_SETTINGS_ACCESS_FOR_OTHERS = HEADER_USER_SWITCH;
export const ACCOUNT_SETTINGS_MY_PROFILE = "AccountSettings-MyProfile";
export const ACCOUNT_SETTINGS_PAYER = "AccountSettings-Payer";

/**
 * MODULE NAME: MyDoctor Page
 */
export const MY_DOCTOR = "MyDoctor";

/**
 * Message center
 */
export const MESSAGES_LIST = HOME_MY_MESSAGE_CARD;
export const MESSAGES_DETAILS = "MyMessages-Details";

/**
 *  My Plan Resources
 */
export const MY_RESOURCES_NOTICE_AND_LETTERS = "Resource-NoticeAndLetter";
